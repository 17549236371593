import React, { Fragment, useEffect, useState } from 'react'
import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControl,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { AdminRole } from '../type/AdminRole'
import { OrganizationUser } from '../UsersSlice'
import ConfirmationModal from '../../../components/shared-components/ConfirmationModal'
import { Permission } from '../../../types/permission'

interface ManageUserModalProps {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  user: OrganizationUser
  noPrescriberSeatsLeft: boolean
  onUpdate: (data: any) => Promise<void>
  onDeactivate: () => Promise<void>
  onTransferOwner: () => void
}

export interface RoleStatus {
  prescriber: boolean
  adminRole: AdminRole
}

export const ManageUserModal = ({
  isOpen,
  isLoading,
  onClose,
  user,
  noPrescriberSeatsLeft,
  onUpdate,
  onDeactivate,
  onTransferOwner,
}: ManageUserModalProps) => {
  const admin = user.admin
  const prescriber = user.prescriber
  const isOwner = admin?.isOwner
  const isAdminActive = admin?.active
  const isPrescriberActive = prescriber?.active
  const isActive = isAdminActive || isPrescriberActive
  const isAbleToDeactivateUser = !isOwner && isActive
  const [roles, setRoles] = useState<RoleStatus>({
    prescriber: isPrescriberActive || false,
    adminRole: isAdminActive
      ? admin?.permissions.includes(Permission.PRESCRIPTION_MANAGEMENT)
        ? 'rx_admin'
        : 'patient_admin'
      : null,
  })
  const toast = useToast()
  const noEnoughSeats = !roles.prescriber && noPrescriberSeatsLeft && !isPrescriberActive

  useEffect(() => {
    setRoles({
      prescriber: isPrescriberActive || false,
      adminRole: isAdminActive
        ? admin?.permissions.includes(Permission.PRESCRIPTION_MANAGEMENT)
          ? 'rx_admin'
          : 'patient_admin'
        : null,
    })
  }, [user])

  const handleRoleChange = (role: 'prescriber' | 'rx_admin' | 'patient_admin') => {
    if (role === 'prescriber') {
      if (noEnoughSeats) {
        toast({
          title: 'No prescriber seats available',
          status: 'error',
          duration: 3000,
        })
        return
      }
      setRoles((prev) => ({
        ...prev,
        prescriber: !prev.prescriber,
      }))
    } else {
      // Handle admin roles (rx_admin or patient_admin)
      setRoles((prev) => ({
        ...prev,
        adminRole: prev.adminRole === role ? null : role,
      }))
    }
  }

  const isAtLeastOneRoleSelected = roles.prescriber || roles.adminRole !== null

  const handleUpdate = async () => {
    if (!isAtLeastOneRoleSelected) {
      toast({
        title: 'Please select at least one role',
        status: 'error',
        duration: 3000,
      })
      return
    }
    await onUpdate(roles)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontWeight="medium">Manage User</Text>
        </ModalHeader>

        <ModalBody>
          <VStack align="stretch" spacing={4}>
            <VStack align="stretch" spacing={2}>
              <Box>
                <Text fontWeight="medium">Name</Text>
                <Text>{`${user.firstName} ${user.lastName}`}</Text>
              </Box>
              <Box>
                <Text fontWeight="medium">Email</Text>
                <Text>{user.email}</Text>
              </Box>
            </VStack>

            <FormControl>
              <VStack align="stretch" bgColor={'gray.50'} p={4} rounded={'md'} spacing={2}>
                <Box>
                  <HStack spacing={2}>
                    <Checkbox
                      isChecked={roles.prescriber}
                      onChange={() => handleRoleChange('prescriber')}
                      isDisabled={noEnoughSeats}
                    >
                      Prescriber
                    </Checkbox>
                    <Badge fontSize={'xs'} colorScheme="purple" variant="subtle">
                      billable
                    </Badge>
                  </HStack>
                  {noEnoughSeats && (
                    <Text fontSize="sm" color="purple" ml={6}>
                      More seats required for new prescribers.
                    </Text>
                  )}
                  <Text fontSize="sm" color="gray.600" ml={6}>
                    This role allows the user to create prescriptions and manage patient health
                    data.
                  </Text>
                </Box>

                {!isOwner && (
                  <Fragment>
                    <Box>
                      <Checkbox
                        isChecked={roles.adminRole === 'patient_admin'}
                        onChange={() => handleRoleChange('patient_admin')}
                      >
                        Patient Admin
                      </Checkbox>
                      <Text fontSize="sm" color="gray.600" ml={6}>
                        This role allows the user to manage patients.
                      </Text>
                    </Box>
                    <Box>
                      <Checkbox
                        isChecked={roles.adminRole === 'rx_admin'}
                        onChange={() => handleRoleChange('rx_admin')}
                      >
                        Rx Admin
                      </Checkbox>
                      <Text fontSize="sm" color="gray.600" ml={6}>
                        This role allows the user to view prescriptions and manage patients.
                      </Text>
                    </Box>
                  </Fragment>
                )}
              </VStack>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <HStack spacing={4} width="100%" justify={!isActive ? 'end' : 'space-between'}>
            {isOwner && (
              <Button colorScheme="red" variant="ghost" onClick={onTransferOwner}>
                Transfer Owner
              </Button>
            )}
            {isAbleToDeactivateUser && (
              <ConfirmationModal
                title="Deactivate User"
                body={`Are you sure you want to deactivate ${user.firstName} ${user.lastName}? This action will remove all roles from this user.`}
                onConfirm={onDeactivate}
              >
                {({ openModal }) => (
                  <Button colorScheme="red" variant="ghost" onClick={openModal}>
                    Deactivate User
                  </Button>
                )}
              </ConfirmationModal>
            )}

            <HStack spacing={4}>
              <Button variant="ghost" onClick={onClose} disabled={isLoading}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                onClick={handleUpdate}
                isDisabled={!isAtLeastOneRoleSelected}
                isLoading={isLoading}
              >
                Update
              </Button>
            </HStack>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
