import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  VStack,
  RadioGroup,
  Radio,
  HStack,
  Avatar,
  Box,
  Stack,
  Divider,
} from '@chakra-ui/react'
import { OrganizationUser } from '../UsersSlice'

interface TransferOwnerModalProps {
  isOpen: boolean
  onClose: () => void
  users: OrganizationUser[]
  currentOwner: OrganizationUser
  onTransfer: (user: OrganizationUser) => Promise<void>
  isLoading: boolean
}

export const TransferOwnerModal = ({
  isOpen,
  onClose,
  users,
  currentOwner,
  onTransfer,
  isLoading,
}: TransferOwnerModalProps) => {
  const [selectedUserId, setSelectedUserId] = useState<string>('')

  const activeUsers = users.filter(
    (user) => (user.admin?.active || user.prescriber?.active) && user.userId !== currentOwner.userId
  )
  const handleTransfer = async () => {
    if (selectedUserId) {
      const selectedUser = users.find((user) => user.userId === selectedUserId)
      selectedUser && (await onTransfer(selectedUser))
    }
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Transfer Ownership</ModalHeader>
        <ModalBody>
          <VStack spacing={6} align="stretch">
            <Text>
              Select a new owner for the organization. This action cannot be undone. You will lose
              owner privileges after the transfer.
            </Text>

            <Box borderWidth="1px" borderRadius="md" maxHeight="300px" overflowY="auto">
              <RadioGroup value={selectedUserId} onChange={setSelectedUserId}>
                <Stack spacing={0} divider={<Divider />}>
                  {activeUsers.map((user) => (
                    <Box
                      key={user.userId}
                      p={3}
                      _hover={{ bg: 'gray.50' }}
                      transition="background 0.2s"
                    >
                      <Radio value={user.userId} width="100%">
                        <HStack spacing={3}>
                          <Avatar
                            size="sm"
                            name={`${user.firstName} ${user.lastName}`}
                            bg="blue.500"
                          />
                          <Box>
                            <Text fontWeight="medium">
                              {user.firstName} {user.lastName}
                            </Text>
                            <Text fontSize="sm" color="gray.600">
                              {user.email}
                            </Text>
                          </Box>
                        </HStack>
                      </Radio>
                    </Box>
                  ))}
                </Stack>
              </RadioGroup>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose} isDisabled={isLoading}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            onClick={handleTransfer}
            isDisabled={!selectedUserId}
            isLoading={isLoading}
          >
            Transfer Ownership
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
