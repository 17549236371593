import { SettingsIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Prescription } from '../../../types'
import { printPaperPrescription } from '../../NewScriptPage/SendScriptPanel/PaperPrescription'
import { transformPrescriptions } from '../utilities'
import { printEvidenceOfPrescription } from './EvidenceOfPrescription'
import PrescribedItemStateTag from './PrescribedItemStateTag'
import { PrescriptionTypeTag } from './PrescriptionTypeTag'
import { TruncatedText } from '../../../components/shared-components/TruncatedText'

const StyledWrapper = styled(Box)`
  border: 1px solid #555555;
  border-radius: 16px;
  height: 100%;
  background-color: white;
  overflow: hidden;
`

const StyledTable = styled(Table)`
  th,
  td {
    padding: 16px !important;
  }
`

interface PrescriptionTableProps {
  prescriptions: Prescription[]
  isDeactivatedPrescriber?: boolean
  isReadonlyPermission?: boolean
  openCeaseModal: (index: number) => void
  openReIssueModal: (index: number) => void
  openCancelModal: (index: number) => void
}

const PrescriptionTable: React.FC<PrescriptionTableProps> = ({
  prescriptions,
  openCeaseModal,
  openReIssueModal,
  openCancelModal,
  isDeactivatedPrescriber,
  isReadonlyPermission,
}) => {
  const viewPaperPrescription = (prescription: Prescription) => {
    printPaperPrescription({ scid: prescription.scid, isNewScript: false, prescription })
  }

  const viewElectronicPrescription = (scid: string) => {
    window.open(`${process.env.REACT_APP_ELECTRONIC_PRESCRIPTION_BASE_URL}/${scid}`, '_blank')
  }

  return (
    <StyledWrapper>
      <Box height="100%" overflowX="auto" overflowY="auto">
        <StyledTable variant="striped" size={['md', 'lg']}>
          <TableCaption />
          <Thead position="sticky" top={0} backgroundColor="white" zIndex={1}>
            <Tr>
              <Th width="80px">Actions</Th>
              <Th>Patient Name</Th>
              <Th>SCID</Th>
              <Th>Type</Th>
              <Th>Created Date</Th>
              <Th minWidth={'200px'}>Prescribed Item Name | Quantity</Th>
              <Th>Original Repeats</Th>
              <Th>Patient Instructions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {prescriptions?.map((prescription, index) => {
              const {
                patientName,
                createdDate,
                prescribedItems,
                isPaperPrescription,
                scid,
                isEmergencySupply,
                isSendToPharmacy,
              } = transformPrescriptions(prescription)
              const isItemInactive =
                prescribedItems[0].state === 'Ceased' || prescribedItems[0].state === 'Cancelled'
              return (
                <Tr key={`${prescription.id}`}>
                  <Td textAlign={'center'}>
                    <Menu>
                      <MenuButton as={Button} colorScheme="blue" variant="link" width={'100%'}>
                        <SettingsIcon />
                      </MenuButton>
                      <MenuList>
                        <Tooltip
                          label="View the electronic prescription for your patient."
                          placement="right"
                        >
                          <MenuItem
                            isDisabled={isItemInactive}
                            onClick={() => {
                              isPaperPrescription
                                ? viewPaperPrescription(prescription)
                                : viewElectronicPrescription(scid)
                            }}
                          >
                            View
                          </MenuItem>
                        </Tooltip>
                        <Tooltip
                          label={'Print evidence of electronic prescription.'}
                          placement={'right'}
                        >
                          <MenuItem
                            isDisabled={isPaperPrescription || isItemInactive}
                            onClick={() => {
                              printEvidenceOfPrescription(prescription)
                            }}
                          >
                            Print EoP
                          </MenuItem>
                        </Tooltip>
                        <Tooltip
                          label={
                            'Clicking this button disables all remaining repeats. This button is applicable to electronic prescriptions only. The script can be ceased even after the original script has been dispensed.'
                          }
                          placement={'right'}
                        >
                          <MenuItem
                            isDisabled={
                              isPaperPrescription ||
                              isItemInactive ||
                              isDeactivatedPrescriber ||
                              isReadonlyPermission
                            }
                            onClick={() => openCeaseModal(index)}
                          >
                            Cease
                          </MenuItem>
                        </Tooltip>
                        <Tooltip
                          label={
                            'Clicking this button cancels the entire prescription. The script can only be cancelled provided the original script has never been dispensed.'
                          }
                          placement={'right'}
                        >
                          <MenuItem
                            isDisabled={
                              isItemInactive || isDeactivatedPrescriber || isReadonlyPermission
                            }
                            onClick={() => openCancelModal(index)}
                          >
                            Cancel
                          </MenuItem>
                        </Tooltip>
                        <Tooltip
                          label={
                            'Enable the same electronic prescription to be sent to the nominated mobile number and/or email address of the patient. SCID of a re-sent prescription will be the same as the original prescription.'
                          }
                          placement={'right'}
                        >
                          <MenuItem
                            isDisabled={
                              isPaperPrescription ||
                              isItemInactive ||
                              isEmergencySupply ||
                              isSendToPharmacy ||
                              isDeactivatedPrescriber
                            }
                            onClick={() => openReIssueModal(index)}
                          >
                            Re-send
                          </MenuItem>
                        </Tooltip>
                      </MenuList>
                    </Menu>
                  </Td>
                  <Td>{patientName}</Td>
                  <Td>{prescription.scid}</Td>
                  <Td>
                    <PrescriptionTypeTag isPaperPrescription={isPaperPrescription} />
                  </Td>
                  <Td>{createdDate}</Td>
                  <Td whiteSpace={'normal'}>
                    {prescribedItems.map((item, idx) => (
                      <Text key={`displayName_${idx}`}>
                        {item.displayName} <PrescribedItemStateTag state={item.state} />
                      </Text>
                    ))}
                  </Td>
                  <Td>
                    {prescribedItems.map((item, idx) => (
                      <Text key={`numberOfRepeatsAuthorised_${idx}`}>
                        {item.numberOfRepeatsAuthorised}
                      </Text>
                    ))}
                  </Td>
                  <Td whiteSpace={'normal'}>
                    {prescribedItems.map((item, idx) => (
                      <TruncatedText
                        key={`patientInstructions_${idx}`}
                        text={item.patientInstructions}
                      />
                    ))}
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </StyledTable>
      </Box>
    </StyledWrapper>
  )
}

export default PrescriptionTable
