import {
  Button,
  Center,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Spacer,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react'
import { FC, MouseEventHandler } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { WhiteLogo } from './Logo'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { UserSummaryPanel } from '../UserSummaryPanel'
import { DESKTOP_BREAKPOINT } from '../../constants'
import { useLogout } from '../../hooks/useLogout'
import { routes } from '../../pages/routes'
import { useFeatureToggle } from '../../common/utils/feature-toggle/useFeatureToggle'
import { useAppSelector } from '../../app/typedReduxHooks'
import { Permission } from '../../types/permission'

interface SideNavDrawerProps {
  isOpen: boolean
  onClose: () => void
}

interface NavProps {
  isSmallScreenNavOpen: boolean
  onCloseSmallScreenNav: () => void
}

interface SideNavLinkProps {
  to: string
  title: string
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const SideNavLink: FC<SideNavLinkProps> = ({ to, title, onClick }) => {
  const location = useLocation()

  return (
    <Button
      justifyContent="flex-start"
      colorScheme="blue"
      bgColor={location.pathname === to ? 'blue.200' : 'blue.800'}
      as={Link}
      to={to}
      onClick={onClick}
    >
      {title}
    </Button>
  )
}

const SideNav: FC<{ isNavDrawer?: boolean }> = ({ isNavDrawer }) => {
  const logout = useLogout()
  const { isFeatureEnabled: isReferralCodeFeatureOn } = useFeatureToggle('REFERRAL_CODE')
  const permissions = useAppSelector((state) => state.authentication.permissions)
  const prescriber = useAppSelector((state) => state.prescriber?.prescriber)
  const organizationSettings = useAppSelector((state) => state.organization?.data?.settings)
  const isEnableRewards = organizationSettings?.enableRewards && isReferralCodeFeatureOn
  const isEnableHelpDocument = organizationSettings?.enableHelpDocument
  return (
    <Flex flexDirection="column" bgColor="blue.800" p="3" h="100%">
      <VStack p={'6'}>
        <Center>
          <WhiteLogo maxWidth={150} />
        </Center>
      </VStack>
      {
        <VStack spacing={10} justifyContent="space-between">
          <VStack width={isNavDrawer ? '296px' : '260px'} spacing={2} alignItems="stretch">
            {permissions?.includes(Permission.PRESCRIBING) && (
              <SideNavLink to="/new-script" title="New Prescription" />
            )}
            {(prescriber || permissions?.includes(Permission.PATIENT_MANAGEMENT)) && (
              <SideNavLink to="/patients" title="Manage Patients" />
            )}
            {(prescriber || permissions?.includes(Permission.PRESCRIPTION_MANAGEMENT)) && (
              <SideNavLink to="/prescriptions" title="Manage Prescriptions" />
            )}
          </VStack>
        </VStack>
      }
      <Spacer />
      <VStack width="260px" spacing={10} alignItems="stretch" justifyContent="space-between">
        <VStack width="260px" spacing={2} alignItems="stretch">
          {isEnableRewards && permissions?.includes(Permission.PRESCRIBING) && (
            <SideNavLink to={routes.refer.path} title="RxRewards" />
          )}
          {permissions && permissions.length > 0 && <SideNavLink to="/settings" title="Settings" />}
          {isEnableHelpDocument ? (
            <Button
              justifyContent="flex-start"
              colorScheme="blue"
              bgColor={'blue.800'}
              as="a"
              href="https://help.rxpad.com.au"
              target="_blank"
              rightIcon={<ExternalLinkIcon />}
            >
              Help
            </Button>
          ) : null}
          {/*TODO: Build real logout function*/}
          <SideNavLink
            to="/"
            title="Logout"
            onClick={(e) => {
              e.preventDefault()
              logout()
            }}
          />
        </VStack>
        <UserSummaryPanel />
      </VStack>
    </Flex>
  )
}

export const SideNavDrawer: FC<Required<SideNavDrawerProps>> = ({ isOpen, onClose }) => (
  <Drawer isOpen={isOpen} placement="left" onClose={onClose} size={'xs'}>
    <DrawerOverlay />
    <DrawerContent>
      <DrawerCloseButton color="white" />
      <SideNav isNavDrawer />
    </DrawerContent>
  </Drawer>
)

const Nav: FC<NavProps> = ({ isSmallScreenNavOpen, onCloseSmallScreenNav }) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${DESKTOP_BREAKPOINT})`)
  return isDesktop ? (
    <SideNav />
  ) : (
    <SideNavDrawer isOpen={isSmallScreenNavOpen} onClose={onCloseSmallScreenNav} />
  )
}

export default Nav
