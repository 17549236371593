import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrganizationAdmin } from '../../types'
import ApiClient from '../../utils/api/ApiClient'
import { AxiosResponse } from 'axios'
import { Permission } from '../../types/permission'

export interface OrganizationAdminFormData {
  email: string
  givenName: string
  familyName: string
}

interface TransferOrgOwnerRequest {
  targetUserId: string
  targetUserFirstName: string
  targetUserLastName: string
  targetUserEmail: string
  organizationId: string
}

interface OrganizationAdminCreationData {
  email: string
  givenName: string
  familyName: string
  permissions: Permission[]
  organizationId: string
  userId: string
}

interface OrganizationAdminCreationState {
  organizationAdminCreationPayload: Partial<OrganizationAdminFormData> | null
  profile: Partial<OrganizationAdmin> | null
}

const initialState: OrganizationAdminCreationState = {
  organizationAdminCreationPayload: null,
  profile: null,
}

export const OrganizationAdminClient = {
  updateAdminProfile: async ({
    id,
    data,
  }: {
    id: string
    data: OrganizationAdminFormData
  }): Promise<AxiosResponse<{ id: string }>> => {
    return await ApiClient.patch(`/bff/organization-admin/${id}`, data)
  },
  acceptUserAgreement: async ({
    adminId,
    rxpadEndUserLicenseAgreement,
  }: {
    adminId: string
    rxpadEndUserLicenseAgreement: { accepted: boolean; acceptedAt: number; revisionDate: string }
  }) => {
    return await ApiClient.patch(`/bff/organization-admin/${adminId}/agreements`, {
      rxpadEndUserLicenseAgreement,
    })
  },
  getAdminProfile: async ({ id }: { id: string }): Promise<AxiosResponse<OrganizationAdmin>> => {
    return await ApiClient.get(`/bff/organization-admin/${id}`)
  },
  transferOwner: async ({
    targetUserId,
    targetUserFirstName,
    targetUserLastName,
    targetUserEmail,
    organizationId,
  }: TransferOrgOwnerRequest): Promise<AxiosResponse<{ id: string }>> => {
    return await ApiClient.patch('/bff/organization-admin/owner', {
      targetUserId,
      organizationId,
      targetUserFirstName,
      targetUserLastName,
      targetUserEmail,
    })
  },
  createAdminProfile: async ({
    familyName,
    givenName,
    organizationId,
    permissions,
    email,
    userId,
  }: OrganizationAdminCreationData): Promise<AxiosResponse<OrganizationAdmin>> => {
    return await ApiClient.post(`/bff/organization-admin`, {
      familyName,
      givenName,
      organizationId,
      permissions,
      email,
      userId,
    })
  },
  updateAdminPermissions: async ({
    adminId,
    permissions,
  }: {
    adminId: string
    permissions: Permission[]
  }) => {
    await ApiClient.patch(`/bff/organization-admin/${adminId}/permissions`, {
      permissions,
    })
  },
  updateActiveStatus: async ({ adminId, active }: { adminId: string; active: boolean }) => {
    await ApiClient.patch(`/bff/organization-admin/${adminId}/active`, {
      active,
    })
  },
}

const organizationAdminSlice = createSlice({
  name: 'organizationAdmin',
  initialState,
  reducers: {
    setOrganizationAdmin: (
      state,
      { payload: organizationAdmin }: PayloadAction<OrganizationAdmin>
    ) => {
      state.profile = organizationAdmin
      localStorage.setItem('organizationAdmin', JSON.stringify(organizationAdmin))
    },
    setOrganizationAdminCreationPayload: (
      state,
      { payload }: PayloadAction<Partial<OrganizationAdminFormData | null>>
    ) => {
      state.organizationAdminCreationPayload = payload ?? null
    },
  },
})

export const { setOrganizationAdminCreationPayload, setOrganizationAdmin } =
  organizationAdminSlice.actions

export default organizationAdminSlice.reducer
