import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../pages/routes'
import { useLogout } from '../../hooks/useLogout'

type Role = 'prescriber' | 'non-prescriber'

export const SelectRolesPage = () => {
  const [selectedRole, setSelectedRole] = useState<Role | ''>('')
  const navigate = useNavigate()
  const toast = useToast()
  const logout = useLogout()

  const handleRoleSubmit = () => {
    if (!selectedRole) {
      toast({
        title: 'Error',
        description: 'Please select a role to continue',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      return
    }

    const path =
      selectedRole === 'prescriber'
        ? routes.createPrescriberProfile.path
        : routes.createAdminProfile.path
    navigate(path)
  }

  return (
    <Container maxW="container.sm" py={24}>
      <Box borderWidth="1px" borderRadius="lg" p={8} boxShadow="lg" bg="white">
        <Flex direction="column" align="center" gap={6}>
          <Heading size="lg" textAlign="center">
            Select a Role to Create User
          </Heading>

          <Text color="gray.600" textAlign="center">
            Please select a role before creating your user profile. This will determine your access
            permissions and features.
          </Text>

          <RadioGroup onChange={(value) => setSelectedRole(value as Role)} value={selectedRole}>
            <Stack spacing={5} width="full">
              <Radio value="prescriber" size="lg" borderColor="gray.300" spacing={4}>
                <Box>
                  <Text fontWeight="semibold">Prescriber</Text>
                  <Text fontSize="sm" color="gray.600">
                    Healthcare professionals who can prescribe medications
                  </Text>
                </Box>
              </Radio>

              <Radio value="non-prescriber" size="lg" borderColor="gray.300" spacing={4}>
                <Box>
                  <Text fontWeight="semibold">Non-Prescriber</Text>
                  <Text fontSize="sm" color="gray.600">
                    Healthcare professionals who do not prescribe medications
                  </Text>
                </Box>
              </Radio>
            </Stack>
          </RadioGroup>
          <Button colorScheme="blue" size="lg" width="full" onClick={handleRoleSubmit} mt={8}>
            Continue
          </Button>
          <Link
            onClick={(e) => {
              e.preventDefault()
              navigate('/login')
              logout()
            }}
            color="blue.500"
            textAlign="center"
            fontSize={'sm'}
            _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            Back to Login Page
          </Link>
        </Flex>
      </Box>
    </Container>
  )
}
