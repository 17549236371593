import React, { Fragment, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import { useAppDispatch, useAppSelector } from '../../app/typedReduxHooks'
import { RootState } from '../../app/store'
import useApi from '../../common/utils/api/useApi'
import { OrganizationUser, OrganizationUsersClient, setOrganizationUsers } from './UsersSlice'
import { AuthenticationClient } from '../authentication/slice/authenticationSlice'
import { InviteUserModal } from '../user-invitations/components/InviteUserModal'
import { ManageUserModal, RoleStatus } from './components/ManageUserModal'
import { convertRoleToPermissions } from './utils/AdminPermissions'
import { PrescriberClient } from '../../pages/NewScriptPage/ScriptForm/PrescriberSlice'
import { areStringArraysEqual } from '../../utils/array/areStringArraysEqual'
import { OrganizationAdminClient } from '../organization-admin/organizationAdminSlice'
import { TransferOwnerModal } from './components/TransferOwnerModal'
import { BillingAlert } from './components/BillingAlert'
import { BillingClient } from '../../pages/Billing/BillingSlice'
import { Permission } from '../../types/permission'

interface LicenseInfo {
  purchased: number
  used: number
  left: number
}

const ITEMS_PER_PAGE = 30

export const UsersPage = () => {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [selectedUser, setSelectedUser] = useState<OrganizationUser | null>(null)
  const [updatePermissionLoading, setUpdatePermissionLoading] = useState<boolean>(false)
  const { isOpen: isManageOpen, onOpen: onManageOpen, onClose: onManageClose } = useDisclosure()
  const organization = useAppSelector((state: RootState) => state.organization?.data)
  const authInfo = useAppSelector((state: RootState) => state.authentication)
  const prescriber = useAppSelector((state: RootState) => state.prescriber.prescriber)
  const permissions = authInfo?.permissions
  const organizationId = organization?.id
  const { data: subscription, error: billingError } = useAppSelector(
    (state: RootState) => state.billing?.subscription
  )
  const users = useAppSelector((state: RootState) => state.users?.data)
  const [currentPage, setCurrentPage] = useState(1)
  const [licenseInfo, setLicenseInfo] = useState<LicenseInfo>({
    purchased: 0,
    used: 0,
    left: 0,
  })
  const [isTransferOwnerModalOpen, setIsTransferOwnerModalOpen] = useState(false)

  const isAbleToManageUsers = !!permissions?.includes(Permission.USER_MANAGEMENT)
  const isAbleToManageBilling = !!permissions?.includes(Permission.BILLING_MANAGEMENT)

  const { trigger: triggerSetOrganizationUsers } = useApi(
    OrganizationUsersClient.getAllUsersInOrg,
    {
      onSuccess: (data) => {
        dispatch(setOrganizationUsers(data))
      },
    }
  )

  const { trigger: triggerTransferOwner, loading: isTransferringOwner } = useApi(
    OrganizationAdminClient.transferOwner,
    {
      onSuccess: async () => {
        await dispatch(AuthenticationClient.Login())
        organizationId && (await triggerSetOrganizationUsers({ organizationId }))

        setIsTransferOwnerModalOpen(false)
        onManageClose()

        toast({
          title: 'Owner transferred successfully',
          status: 'success',
          duration: 3000,
        })
      },
      onFailure: async () => {
        toast({
          title: 'Failed to transfer ownership',
          status: 'error',
          duration: 3000,
        })
      },
    }
  )

  const handleManage = (userId: string) => {
    const user = users?.find((u) => u.userId === userId)
    if (user) {
      setSelectedUser(user)
      onManageOpen()
    }
  }

  useEffect(() => {
    if (organizationId) {
      triggerSetOrganizationUsers({ organizationId })
    }
  }, [organizationId])

  useEffect(() => {
    const purchased = subscription?.seats ?? 0
    const used = users?.filter((user) => user.prescriber?.active).length ?? 0
    setLicenseInfo({
      purchased,
      used,
      left: purchased - used,
    })
  }, [users, subscription])

  useEffect(() => {
    if (organizationId) {
      if (prescriber) {
        dispatch(BillingClient.getSubscription({ organizationId, prescriberId: prescriber.id }))
      }
      dispatch(BillingClient.getSubscription({ organizationId }))
    }
  }, [licenseInfo.used])

  const handleUpdatePermissions = async (newRolesPayload: RoleStatus) => {
    try {
      if (!organizationId || !selectedUser) {
        return
      }
      const existPrescriberProfile = selectedUser?.prescriber
      const isPrescriberActive = !!existPrescriberProfile?.active
      const existAdminProfile = selectedUser?.admin
      const isAdminActive = !!existAdminProfile?.active
      const isOwner = !!existAdminProfile?.isOwner
      const previousAdminPermissions = existAdminProfile?.permissions ?? []
      const isPrescriber = newRolesPayload.prescriber
      const admin = newRolesPayload.adminRole
      const newPermissions = convertRoleToPermissions(admin)
      const hasPrescriberChanges =
        isPrescriberActive !== isPrescriber || (!existPrescriberProfile && isPrescriber)
      setUpdatePermissionLoading(true)
      if (hasPrescriberChanges) {
        if (!existPrescriberProfile && isPrescriber) {
          //Create prescriber
          await PrescriberClient.createSimpleProfile({
            email: selectedUser.email,
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
            organizationId: organizationId,
            userId: selectedUser.userId,
          })
        }
        if (existPrescriberProfile && !isPrescriberActive && isPrescriber) {
          //active prescriber
          await PrescriberClient.updateActiveStatus({
            prescriberId: existPrescriberProfile.id,
            active: true,
          })
        }
        if (isPrescriberActive && !isPrescriber) {
          //deactivate Prescriber
          await PrescriberClient.updateActiveStatus({
            prescriberId: existPrescriberProfile.id,
            active: false,
          })
        }
      }
      if (!isOwner) {
        if (!existAdminProfile && admin) {
          //create admin base on role
          await OrganizationAdminClient.createAdminProfile({
            familyName: selectedUser.lastName,
            givenName: selectedUser.firstName,
            userId: selectedUser.userId,
            email: selectedUser.email,
            permissions: newPermissions,
            organizationId,
          })
        }
        if (existAdminProfile && !isAdminActive && admin) {
          // active admin
          await OrganizationAdminClient.updateActiveStatus({
            adminId: existAdminProfile.id,
            active: true,
          })
          //check is permission same or not, if not, update the role
          if (!areStringArraysEqual(previousAdminPermissions, newPermissions)) {
            await OrganizationAdminClient.updateAdminPermissions({
              adminId: existAdminProfile.id,
              permissions: newPermissions,
            })
          }
        }
        if (isAdminActive && !admin) {
          // deactivate admin
          await OrganizationAdminClient.updateActiveStatus({
            adminId: existAdminProfile.id,
            active: false,
          })
        }
        if (
          isAdminActive &&
          admin &&
          !areStringArraysEqual(previousAdminPermissions, newPermissions)
        ) {
          //update permission
          await OrganizationAdminClient.updateAdminPermissions({
            adminId: existAdminProfile.id,
            permissions: newPermissions,
          })
        }
      }

      if (selectedUser.userId === authInfo.username) {
        await dispatch(AuthenticationClient.Login())
      }
      triggerSetOrganizationUsers({ organizationId })
      onManageClose()
      setUpdatePermissionLoading(false)
      toast({
        title: 'Permissions updated successfully',
        status: 'success',
        duration: 3000,
      })
    } catch (error) {
      toast({
        title: 'Failed to update permissions',
        status: 'error',
        duration: 3000,
      })
      setUpdatePermissionLoading(false)
    }
  }

  const handleDeactivateUser = async () => {
    if (!organizationId || !selectedUser || selectedUser.admin?.isOwner) {
      return
    }
    try {
      const deactivateUser = []
      const existedAdmin = selectedUser?.admin
      const existedPrescriber = selectedUser?.prescriber
      const isAdminActive = !!existedAdmin?.active
      const isPrescriberActive = !!existedPrescriber?.active
      if (isAdminActive) {
        deactivateUser.push(
          OrganizationAdminClient.updateActiveStatus({
            adminId: existedAdmin.id,
            active: false,
          })
        )
      }
      if (isPrescriberActive) {
        deactivateUser.push(
          PrescriberClient.updateActiveStatus({
            prescriberId: existedPrescriber?.id,
            active: false,
          })
        )
      }
      await Promise.all(deactivateUser)
      triggerSetOrganizationUsers({ organizationId })
      onManageClose()
      toast({
        title: 'User deactivated successfully',
        status: 'success',
        duration: 3000,
      })
    } catch (error) {
      toast({
        title: 'Failed to deactivate user',
        status: 'error',
        duration: 3000,
      })
    }
  }

  const handleTransferOwner = async (newOwner: OrganizationUser) => {
    if (!organizationId || !selectedUser) return
    await triggerTransferOwner({
      organizationId,
      targetUserId: newOwner.userId,
      targetUserLastName: newOwner.lastName,
      targetUserFirstName: newOwner.firstName,
      targetUserEmail: newOwner.email,
    })
  }

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
  const currentUsers = users?.slice(startIndex, startIndex + ITEMS_PER_PAGE) ?? []

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  return (
    <Box px={6} py={2}>
      <Box mb={6} display="flex" justifyContent="space-between" alignItems="center">
        <VStack align="flex-start" spacing={1}>
          <Heading size="lg">Users</Heading>
          <Text color="gray.600">
            A list of all the users in your account including their name, email and role etc.
          </Text>
        </VStack>
        {!!authInfo?.permissions?.length && (
          <Button colorScheme="blue" onClick={onOpen}>
            Invite user
          </Button>
        )}
      </Box>
      <BillingAlert errorCode={billingError?.code} isAbleToManageBilling={isAbleToManageBilling} />
      <SimpleGrid columns={3} spacing={4} mb={6}>
        <Card bg="white">
          <CardBody textAlign="center">
            <Text fontSize="4xl" fontWeight="bold" mb={2}>
              {licenseInfo.purchased}
            </Text>
            <Text color="gray.600">Prescriber licenses purchased</Text>
          </CardBody>
        </Card>
        <Card bg="white">
          <CardBody textAlign="center">
            <Text fontSize="4xl" fontWeight="bold" mb={2}>
              {licenseInfo.used}
            </Text>
            <Text color="gray.600">Prescriber licenses used</Text>
          </CardBody>
        </Card>
        <Card bg="white">
          <CardBody textAlign="center">
            <Text fontSize="4xl" fontWeight="bold" mb={2}>
              {licenseInfo.left}
            </Text>
            <Text color="gray.600">Prescriber licenses left</Text>
          </CardBody>
        </Card>
      </SimpleGrid>

      <Card bg="white" p={4}>
        <CardBody p={0} display="flex" flexDirection="column">
          <Box height="420px" overflowY="auto" overflowX="auto">
            <Table variant="simple" position="relative">
              <Thead position="sticky" top={0} bg="gray.50" zIndex={1}>
                <Tr>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Role</Th>
                  <Th>Status</Th>
                  {isAbleToManageUsers && <Th isNumeric />}
                </Tr>
              </Thead>
              <Tbody bg="white">
                {currentUsers?.map((user) => (
                  <Tr key={user.userId}>
                    <Td fontWeight="medium">
                      <HStack>
                        <Text fontSize={'md'}>{`${user.firstName ?? ''} ${
                          user.lastName ?? ''
                        }`}</Text>
                        {user.userId === authInfo?.username && (
                          <Tag size="sm" variant="subtle" colorScheme="orange">
                            You
                          </Tag>
                        )}
                      </HStack>
                    </Td>
                    <Td>{user.email}</Td>
                    <Td>
                      <Stack direction="row" spacing={2}>
                        {user.admin?.active &&
                          (user.admin?.isOwner ? (
                            <Tag size="md" variant="subtle" colorScheme="red">
                              Owner
                            </Tag>
                          ) : user.admin.permissions.includes(
                              Permission.PRESCRIPTION_MANAGEMENT
                            ) ? (
                            <Tag size="md" variant="subtle" colorScheme="blue">
                              Rx Admin
                            </Tag>
                          ) : (
                            <Tag size="md" variant="subtle" colorScheme="cyan">
                              Patient Admin
                            </Tag>
                          ))}
                        {user.prescriber?.active && (
                          <Tag size="md" variant="subtle" colorScheme="purple">
                            Prescriber
                          </Tag>
                        )}
                      </Stack>
                    </Td>
                    <Td>
                      {!user.admin?.active && !user.prescriber?.active ? (
                        <Tag size="sm" fontSize="xs" variant="subtle" colorScheme="gray">
                          Inactive
                        </Tag>
                      ) : (
                        <Tag size="sm" fontSize="xs" variant="subtle" colorScheme="green">
                          Active
                        </Tag>
                      )}
                    </Td>
                    {isAbleToManageUsers && (
                      <Td isNumeric>
                        <Button
                          variant="ghost"
                          colorScheme="blue"
                          onClick={() => handleManage(user.userId)}
                        >
                          Manage
                        </Button>
                      </Td>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box
            p={6}
            borderTop="1px"
            borderColor="gray.100"
            bg="white"
            display="flex"
            justifyContent="flex-end"
          >
            <Pagination
              current={currentPage}
              total={users?.length ?? 0}
              pageSize={ITEMS_PER_PAGE}
              onChange={handlePageChange}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} users`}
            />
          </Box>
        </CardBody>
      </Card>
      <InviteUserModal isOpen={isOpen} onClose={onClose} noEnoughSeats={licenseInfo.left < 1} />
      {selectedUser && (
        <Fragment>
          <ManageUserModal
            isOpen={isManageOpen}
            onClose={onManageClose}
            user={selectedUser}
            noPrescriberSeatsLeft={licenseInfo.left < 1}
            onUpdate={handleUpdatePermissions}
            onDeactivate={handleDeactivateUser}
            onTransferOwner={() => setIsTransferOwnerModalOpen(true)}
            isLoading={updatePermissionLoading}
          />
          <TransferOwnerModal
            isOpen={isTransferOwnerModalOpen}
            onClose={() => setIsTransferOwnerModalOpen(false)}
            users={users ?? []}
            currentOwner={selectedUser}
            onTransfer={handleTransferOwner}
            isLoading={isTransferringOwner}
          />
        </Fragment>
      )}
    </Box>
  )
}
