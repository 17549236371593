import { Prescriber } from '../../types'
import { PrescriberCreation } from '../../types/prescriber'

export type PrescriberInService = Omit<Prescriber, 'qualifications'> & { qualifications: string[] }

export const transformPrescriberForService = (data: Prescriber | PrescriberCreation) => {
  const { qualifications, ...restData } = data

  if (qualifications) {
    return {
      ...restData,
      qualifications: qualifications.split(',').map((qualification) => qualification.trim()),
    }
  }

  return restData
}

export const transformPrescriberForUI = (data: PrescriberInService): Prescriber => {
  const { qualifications, ...restData } = data

  if (qualifications?.length > 0) {
    return {
      ...restData,
      qualifications: qualifications.join(','),
    }
  }

  return restData
}

export const validatePrescriberProfile = (prescriber: Partial<Prescriber>): boolean => {
  const requiredFields: (keyof Prescriber)[] = [
    'givenNames',
    'familyName',
    'email',
    'phoneNumber',
    'prescriberNumber',
    'entityId',
    'prescriberType',
    'hpii',
    'hpiiStatus',
    'qualifications',
  ]

  return requiredFields.every((field) => {
    const value = prescriber[field]
    return value && (typeof value === 'string' ? value.trim() !== '' : true)
  })
}
