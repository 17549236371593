import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../routes'

interface PrescriberProfileUnCompletedModalProps {
  isOpen: boolean
}

export const PrescriberProfileUnCompletedModal = ({
  isOpen,
}: PrescriberProfileUnCompletedModalProps) => {
  const navigate = useNavigate()

  const handleGoToPrescriberProfilePage = () => {
    navigate(`${routes.settings.path}?tab=prescriber`)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}} // Empty function to prevent closing
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Complete Your Profile</ModalHeader>
        <ModalBody>
          <Text>Please complete your prescriber profile before proceeding.</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleGoToPrescriberProfilePage}>
            Go to Settings
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
