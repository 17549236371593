import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/typedReduxHooks'
import LogoutWarningModal from '../components/LogoutWarningModal'
import UserAgreementModal from '../components/UserAgreementModal'
import { rxpadPrescriberAgreement } from './endUserLicenseAgreement/rxpadPrescriberAgreement'
import { Center, Text, VStack } from '@chakra-ui/react'
import { Logo } from '../../../components/shared-components/Logo'
import { PrescriberClient } from '../../NewScriptPage/ScriptForm/PrescriberSlice'
import { EULA_SERVICE_NAME } from '../constant'

const warningContent = `Please accept our agreement to continue. It outlines the terms and conditions of using
          PxPad's services. If you choose not to accept, you will be logged out of the system. Thank
          you for your cooperation.`
// Eula: End User License Agreement
const RxPadPrescriberEulaModal = () => {
  const dispatch = useAppDispatch()
  const prescriber = useAppSelector((state) => state.prescriber.prescriber)
  const [isOpen, setIsOpen] = useState(false)
  const [isLogoutWarningModalOpen, setIsLogoutWarningModalOpen] = useState(false)

  useEffect(() => {
    const eula = prescriber?.rxpadEula
    const eulaData = JSON.parse(eula || '{}')
    if (eulaData?.revisionDate === rxpadPrescriberAgreement.revisionDate) {
      setIsOpen(false)
      return
    } else {
      setIsOpen(true)
    }
  }, [prescriber?.rxpadEula])

  const acceptAgreement = async () => {
    await dispatch(
      PrescriberClient.AcceptAgreements({
        service: EULA_SERVICE_NAME.RX_PAD,
        rxpadEndUserLicenseAgreement: {
          accepted: true,
          acceptedAt: new Date().getTime(),
          revisionDate: rxpadPrescriberAgreement.revisionDate,
        },
      })
    )
  }

  return (
    <>
      <UserAgreementModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onAccept={acceptAgreement}
        canBeBypass={false}
        onDecline={() => setIsLogoutWarningModalOpen(true)}
      >
        <VStack spacing={10} paddingTop={8}>
          <Center>
            <Logo maxWidth={150} />
          </Center>
          <VStack width={'100%'} alignItems={'baseline'}>
            <Text fontSize={'xl'}>Terms and Conditions</Text>
            <iframe
              title="RxPad Prescriber End User Licence Agreement"
              width="100%"
              height="440px"
              srcDoc={`${rxpadPrescriberAgreement.contentHTML}`}
              style={{
                border: '1px solid black',
              }}
            />
          </VStack>
        </VStack>
      </UserAgreementModal>
      <LogoutWarningModal
        content={warningContent}
        isOpen={isLogoutWarningModalOpen}
        onClose={() => setIsLogoutWarningModalOpen(false)}
      />
    </>
  )
}

export default RxPadPrescriberEulaModal
