import { hasUnusualQuantity } from './PrescribedItemList/utils/hasUnusualQuantity'
import { REQUIRE_PHONE_AUTH_RESTRICTION_TYPES } from '../../constants'
import { PrescribedItem } from '../../types'
import {
  PbsConcise,
  PbsProgram,
  ProductDetails,
  StateSchedule,
} from './ScriptForm/ProductSearchContextSlice'
import { get } from 'lodash'

export const isAuthPhoneRequiredForPrescribedItem = (prescribedItem: PrescribedItem) => {
  const pbsArray = prescribedItem?.productDetails?.pbs || []
  const selectedPBS = pbsArray.find(({ pbsCode }) => pbsCode === prescribedItem?.pbsCode)

  if (!selectedPBS) {
    return false
  }

  return (
    hasUnusualQuantity(prescribedItem) ||
    REQUIRE_PHONE_AUTH_RESTRICTION_TYPES.includes(selectedPBS?.restrictions[0]?.restrictionType)
  )
}

export const isAbleToAddNewPrescribedItem = (
  item: PrescribedItem,
  prescribedItemList: PrescribedItem[],
  isPaperPrescription: boolean
) => {
  if (!isPaperPrescription) {
    return prescribedItemList?.length < 1
  }

  const isAuthRequired = isAuthPhoneRequiredForPrescribedItem(item)

  if (isAuthRequired) {
    return prescribedItemList?.length < 1
  }

  const isAnyAuthRequiredItem = prescribedItemList?.some((item) =>
    isAuthPhoneRequiredForPrescribedItem(item)
  )

  if (isAnyAuthRequiredItem) {
    return false
  }

  return prescribedItemList.length < 3
}

export const isAuthPhoneRequiredForPBS = (pbs: PbsConcise) =>
  REQUIRE_PHONE_AUTH_RESTRICTION_TYPES.includes(pbs?.restrictions[0]?.restrictionType)

// you will call this function when you've already selected the prescribedItem
export const isAbleToAddNewPBS = (pbs: PbsConcise, prescribedItemList: PrescribedItem[]) => {
  const isAuthRequired = isAuthPhoneRequiredForPBS(pbs)

  if (!isAuthRequired) {
    return true
  }

  return prescribedItemList.length < 2
}

export const isSchedule8Medicine = (prescribedItem: PrescribedItem) =>
  !!prescribedItem?.productDetails?.poisonClassification?.poisonClasses.includes('S8')

interface Map {
  [key: string]: string | undefined
}
export const RESTRICTION_TYPE_MAPPING: Map = {
  'online authority required': 'Phone/Online authority required',
}

export const convertRestrictionType = (restrictionType: string) => {
  const mappingResult = RESTRICTION_TYPE_MAPPING[restrictionType.toLowerCase()]

  return mappingResult ?? restrictionType ?? ''
}

export function repeatIntervalRequired(
  numOfRepeats: number | string,
  state: string | undefined,
  drugDetails: ProductDetails | undefined
): boolean {
  if (Number(numOfRepeats) === 0 || !state || !drugDetails) {
    return false
  }

  const poisonClasses: string[] = get(drugDetails, 'poisonClassification.poisonClasses', [])
  const stateSchedules: (StateSchedule | null)[] = get(drugDetails, 'stateSchedules', [])

  if (poisonClasses.includes('S8')) {
    return true
  }

  if (state === 'NSW') {
    return !!stateSchedules.find((ss) => ss?.state === 'NSW' && ss.schedules.includes('S4B'))
  }

  if (state === 'TAS') {
    return !!stateSchedules.find((ss) => ss?.state === 'TAS' && ss.schedules.includes('S4D'))
  }

  if (state === 'VIC') {
    return !!stateSchedules.find((ss) => ss?.state === 'VIC' && ss.schedules.includes('S11'))
  }

  return false
}

export const describePbsProgram = (pbsProgram?: PbsProgram) => {
  switch (pbsProgram?.programCode) {
    case 'CA':
      return 'Community Access'
    case 'HS':
      return 'Private Hospitals'
    case 'HB':
      return 'Public Hospitals'
    case 'GE':
      return 'General Schedule'
    default:
      return ''
  }
}
