import React from 'react'
import { Alert, AlertIcon, AlertTitle, AlertDescription, HStack, Link } from '@chakra-ui/react'
import { BillErrorCode } from '../../../pages/Billing/BillingSlice'
import { useNavigate } from 'react-router-dom'

interface BillingAlertProps {
  errorCode?: BillErrorCode
  isAbleToManageBilling?: boolean
}

export const BillingAlert: React.FC<BillingAlertProps> = ({ errorCode, isAbleToManageBilling }) => {
  const navigate = useNavigate()

  const getErrorMessage = () => {
    switch (errorCode) {
      case BillErrorCode.SUBSCRIPTION_EXPIRED:
      case BillErrorCode.NO_SUBSCRIPTION:
        return {
          title:
            errorCode === BillErrorCode.SUBSCRIPTION_EXPIRED
              ? 'Subscription Expired'
              : 'No Active Subscription',
          description: isAbleToManageBilling ? (
            <>
              {errorCode === BillErrorCode.SUBSCRIPTION_EXPIRED
                ? 'The organisation subscription has expired.'
                : 'The organisation does not have an active subscription.'}{' '}
              <Link
                color="blue.500"
                onClick={() => navigate('/price-plans?multiple_seats')}
                cursor="pointer"
              >
                Click here to subscribe
              </Link>
            </>
          ) : (
            `Please contact your organisation owner to ${
              errorCode === BillErrorCode.SUBSCRIPTION_EXPIRED ? 'renew' : 'buy'
            } the subscription.`
          ),
          status: 'warning' as const,
        }
      case BillErrorCode.EXCEED_SEAT_LIMIT:
      case BillErrorCode.NO_REMAINING_TOKENS:
        return {
          title:
            errorCode === BillErrorCode.EXCEED_SEAT_LIMIT
              ? 'Organisation Seat Limit Exceeded'
              : 'No Remaining Tokens',
          description: isAbleToManageBilling ? (
            <>
              {errorCode === BillErrorCode.EXCEED_SEAT_LIMIT
                ? 'The organisation has reached its maximum number of licensed prescribers.'
                : 'You have used all available tokens.'}{' '}
              <Link
                color="blue.500"
                onClick={() => navigate('/settings?tab=subscription')}
                cursor="pointer"
              >
                Click here to manage subscription
              </Link>
            </>
          ) : (
            'Please contact your organisation owner to upgrade the subscription.'
          ),
          status: 'error' as const,
        }
      default:
        return null
    }
  }
  const errorMessage = getErrorMessage()
  if (!errorMessage) return null

  return (
    <Alert
      status={errorMessage.status}
      variant="subtle"
      flexDirection="column"
      alignItems="flex-start"
      mb={0}
      borderRadius="md"
    >
      <HStack spacing={0}>
        <AlertIcon />
        <AlertTitle>{errorMessage.title}</AlertTitle>
      </HStack>
      <AlertDescription pl={8}>{errorMessage.description}</AlertDescription>
    </Alert>
  )
}
