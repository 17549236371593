import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/typedReduxHooks'
import LogoutWarningModal from '../components/LogoutWarningModal'
import UserAgreementModal from '../components/UserAgreementModal'
import { Center, Text, VStack } from '@chakra-ui/react'
import { Logo } from '../../../components/shared-components/Logo'
import { OrganizationAdminClient } from '../../../features/organization-admin/organizationAdminSlice'
import { rxpadAdminAgreement } from './endUserLicenseAgreement/rxpadAdminAgreement'
import useApi from '../../../common/utils/api/useApi'
import { AuthenticationClient } from '../../../features/authentication/slice/authenticationSlice'

const warningContent = `Please accept our agreement to continue. It outlines the terms and conditions of using
          PxPad's services. If you choose not to accept, you will be logged out of the system. Thank
          you for your cooperation.`
// Eula: End User License Agreement
const RxPadAdminEulaModal = () => {
  const dispatch = useAppDispatch()
  const admin = useAppSelector((state) => state.organizationAdmin.profile)
  const [isOpen, setIsOpen] = useState(false)
  const [isLogoutWarningModalOpen, setIsLogoutWarningModalOpen] = useState(false)

  const { trigger: triggerAcceptUserAgreement } = useApi(
    OrganizationAdminClient.acceptUserAgreement,
    {
      onSuccess: () => {
        dispatch(AuthenticationClient.Login())
      },
    }
  )

  useEffect(() => {
    const eula = admin?.rxpadEula
    const eulaData = JSON.parse(eula || '{}')
    if (eulaData?.revisionDate === rxpadAdminAgreement.revisionDate) {
      setIsOpen(false)
      return
    } else {
      setIsOpen(true)
    }
  }, [admin?.rxpadEula])

  const acceptAgreement = async () => {
    if (admin?.id) {
      triggerAcceptUserAgreement({
        adminId: admin.id,
        rxpadEndUserLicenseAgreement: {
          accepted: true,
          acceptedAt: new Date().getTime(),
          revisionDate: rxpadAdminAgreement.revisionDate,
        },
      })
    }
  }

  return (
    <>
      <UserAgreementModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onAccept={acceptAgreement}
        canBeBypass={false}
        onDecline={() => setIsLogoutWarningModalOpen(true)}
      >
        <VStack spacing={10} paddingTop={8}>
          <Center>
            <Logo maxWidth={150} />
          </Center>
          <VStack width={'100%'} alignItems={'baseline'}>
            <Text fontSize={'xl'}>Terms and Conditions</Text>
            <iframe
              title="RxPad End User Licence Agreement"
              width="100%"
              height="440px"
              srcDoc={`${rxpadAdminAgreement.contentHTML}`}
              style={{
                border: '1px solid black',
              }}
            />
          </VStack>
        </VStack>
      </UserAgreementModal>
      <LogoutWarningModal
        content={warningContent}
        isOpen={isLogoutWarningModalOpen}
        onClose={() => setIsLogoutWarningModalOpen(false)}
      />
    </>
  )
}

export default RxPadAdminEulaModal
