import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Text,
  useToast,
  FormErrorMessage,
  Code,
  Center,
} from '@chakra-ui/react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import useApi from '../../common/utils/api/useApi'
import { InvitationApiClient } from './InvitationSlice'
import { Logo } from '../../components/shared-components/Logo'
import { AxiosError, AxiosResponse } from 'axios'
import { Fragment } from 'react'

interface AcceptInvitationForm {
  firstName: string
  lastName: string
  phoneNumber: string
  password: string
  confirmPassword: string
}

const AcceptInvitationPage = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const toast = useToast()
  const { trigger: acceptInvitation, loading } = useApi(InvitationApiClient.acceptInvitation, {
    onSuccess: () => {
      toast({
        title: 'Welcome!',
        description: 'Your account has been created successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })

      navigate('/login')
    },
    onFailure: (error: AxiosError) => {
      const response = error.response as AxiosResponse<{ message: string }> | undefined
      toast({
        title: 'Error',
        description: response?.data?.message
          ? response.data.message
          : 'Failed to accept invitation',
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      })
    },
  })

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<AcceptInvitationForm>()

  const password = watch('password')
  const invitationToken = searchParams.get('invitation_token')
  const isNewUser = searchParams.get('is_new_user')

  if (!invitationToken) {
    return (
      <Container centerContent py={10}>
        <VStack spacing={8}>
          <Center>
            <Logo maxWidth={150} />
          </Center>
          <Text fontSize={'4xl'}>No invitation token provided</Text>
        </VStack>
      </Container>
    )
  }

  const onSubmit = async (data: AcceptInvitationForm) => {
    await acceptInvitation({
      password: data?.password,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: `+61${data.phoneNumber}`,
      token: invitationToken,
    })
  }

  return (
    <Container maxW="container.sm" py={10}>
      <VStack spacing={8}>
        <Center>
          <Logo maxWidth={150} />
        </Center>
        <Heading>Accept Invitation</Heading>
        <Text>
          Token: <Code>{invitationToken}</Code>
        </Text>

        <Box as="form" w="100%" onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4} align="stretch">
            <FormControl isInvalid={!!errors.firstName}>
              <FormLabel>First Name</FormLabel>
              <Input
                {...register('firstName', {
                  required: 'First name is required',
                  minLength: { value: 2, message: 'First name must be at least 2 characters' },
                  maxLength: { value: 25, message: 'First name must be less than 25 characters' },
                })}
                placeholder="Enter your first name"
              />
              <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.lastName}>
              <FormLabel>Last Name</FormLabel>
              <Input
                {...register('lastName', {
                  required: 'Last name is required',
                  minLength: { value: 2, message: 'Last name must be at least 2 characters' },
                  maxLength: { value: 25, message: 'Last name must be less than 25 characters' },
                })}
                placeholder="Enter your last name"
              />
              <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
            </FormControl>

            {isNewUser === 'true' && (
              <Fragment>
                <FormControl isInvalid={!!errors.phoneNumber}>
                  <FormLabel>Phone Number</FormLabel>
                  <Box display="flex">
                    <Box
                      bg="gray.100"
                      px={3}
                      display="flex"
                      alignItems="center"
                      borderWidth={1}
                      borderRight={0}
                      borderRadius="md"
                      borderRightRadius={0}
                    >
                      +61
                    </Box>
                    <Input
                      {...register('phoneNumber', {
                        required: 'Phone number is required',
                        pattern: {
                          value: /^[0-9]{9}$/,
                          message: 'Please enter a valid Australian phone number (9 digits)',
                        },
                      })}
                      placeholder="Enter your phone number"
                      borderLeftRadius={0}
                    />
                  </Box>
                  <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.password}>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    {...register('password', {
                      required: 'Password is required',
                      minLength: { value: 8, message: 'Password must be at least 8 characters' },
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
                        message:
                          'Password must contain uppercase, lowercase, number and special character',
                      },
                    })}
                    placeholder="Create a password"
                  />
                  <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.confirmPassword}>
                  <FormLabel>Confirm Password</FormLabel>
                  <Input
                    type="password"
                    {...register('confirmPassword', {
                      required: 'Please confirm your password',
                      validate: (value) => value === password || 'Passwords must match',
                    })}
                    placeholder="Confirm your password"
                  />
                  <FormErrorMessage>{errors.confirmPassword?.message}</FormErrorMessage>
                </FormControl>
              </Fragment>
            )}

            <Button type="submit" colorScheme="blue" size="lg" width="100%" isLoading={loading}>
              Accept & Create Account
            </Button>
          </VStack>
        </Box>
      </VStack>
    </Container>
  )
}

export default AcceptInvitationPage
