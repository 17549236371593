import { Button, VStack } from '@chakra-ui/react'
import TitleWithLogo from '../../components/TitleWithLogo'
import { useForm } from 'react-hook-form'
import { Clinic } from '../../types'
import ClinicForm from '../ClinicPage/ClinicForm'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import useApi from '../../common/utils/api/useApi'
import { ClinicClient } from '../NewScriptPage/ScriptForm/ClinicContextSlice'
import { createOrganization } from '../OrganizationProfilePage/OrganizationSlice'
import {
  CreatePrescriberRequest,
  PrescriberClient,
} from '../NewScriptPage/ScriptForm/PrescriberSlice'
import { setPrescriberCreationPayload } from '../PrescriberProfileCreationPage/PrescriberCreationSlice'
import { appDidFinishLaunching } from '../../app/appSlice'
import { routes } from '../routes'
import { useAppDispatch } from '../../app/typedReduxHooks'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { STATUS } from '../../constants'
import { useFeatureToggle } from '../../common/utils/feature-toggle/useFeatureToggle'

const ClinicCreationPage = () => {
  const { isFeatureEnabled: isOrgRegistrationAndPaymentFeatureOn } = useFeatureToggle(
    'ORG_REGISTRATION_AND_PAYMENT'
  )
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { CreatePrescriber: createPrescriberLoading } = useSelector(
    (state: RootState) => state.prescriber.status
  )
  const { organizationAdminCreationPayload } = useSelector(
    (state: RootState) => state.organizationAdmin
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const { prescriberCreationPayload } = useSelector((state: RootState) => state.prescriberCreation)
  const { trigger: triggerCreateOrganization, loading: createOrgLoading } = useApi(
    createOrganization,
    {
      onSuccess: async (organization) => {
        if (!prescriberCreationPayload) {
          return
        }
        await dispatch(
          PrescriberClient.CreatePrescriber({
            ...prescriberCreationPayload,
            clinicId: organization.clinic.id,
            organizationId: organization.id,
          } as CreatePrescriberRequest)
        )
        await dispatch(appDidFinishLaunching())
        isOrgRegistrationAndPaymentFeatureOn
          ? navigate(routes.pricePlan.path)
          : navigate(routes.newScript.path)
        dispatch(setPrescriberCreationPayload(null))
      },
    }
  )
  const { trigger: triggerCreateClinic, loading: createClinicLoading } = useApi(
    ClinicClient.createClinic,
    {
      onSuccess: async (clinic) => {
        if (!prescriberCreationPayload) {
          return
        }
        await triggerCreateOrganization({
          clinicId: clinic.id,
          email: prescriberCreationPayload.email ?? '',
          givenName: prescriberCreationPayload.givenNames ?? '',
          familyName: prescriberCreationPayload.familyName ?? '',
        })
      },
    }
  )

  useEffect(() => {
    if (!prescriberCreationPayload && !organizationAdminCreationPayload) {
      navigate(routes.selectRoles.path)
    }
  })

  useEffect(() => {
    setIsLoading(
      createClinicLoading || createOrgLoading || createPrescriberLoading === STATUS.PENDING
    )
  }, [createClinicLoading, createOrgLoading, createPrescriberLoading])

  const createClinic = async () => {
    await triggerCreateClinic(getValues())
  }

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {} as Clinic,
  })

  return (
    <VStack overflow="auto" p="24px" minHeight="100%" spacing="16">
      <TitleWithLogo title={'Create a New Organisation'}></TitleWithLogo>
      <VStack
        flexGrow="1"
        overflow="auto"
        flexDirection="column"
        alignItems="center"
        width={['100%', '60%', '60%', '40%']}
        spacing={10}
      >
        <ClinicForm
          setIsDisabled={setIsDisabled}
          {...{
            register,
            errors,
            getValues,
            setValue,
          }}
        />
        <Button
          colorScheme="blue"
          width={218}
          onClick={handleSubmit(createClinic)}
          isLoading={isLoading}
          disabled={
            !prescriberCreationPayload || isLoading || Object.keys(errors).length > 0 || isDisabled
          }
        >
          Create and Link Now
        </Button>
      </VStack>
    </VStack>
  )
}
export default ClinicCreationPage
