import { FC, Fragment } from 'react'
import RxPadPrescriberEulaModal from './RxPadEulaModal/RxPadPrescriberEulaModal'
import MimsEulaModal from './MimsEulaModal'
import { useAppSelector } from '../../app/typedReduxHooks'
import RxPadAdminEulaModal from './RxPadEulaModal/RxPadAdminEulaModal'
import { Permission } from '../../types/permission'

const UserAgreementPage: FC<unknown> = () => {
  const permissions = useAppSelector((state) => state.authentication.permissions)
  const admin = useAppSelector((state) => state.organizationAdmin.profile)
  return (
    <Fragment>
      {permissions?.includes(Permission.PRESCRIBING) && (
        <>
          <MimsEulaModal />
          <RxPadPrescriberEulaModal />
        </>
      )}
      {admin?.active && <RxPadAdminEulaModal />}
    </Fragment>
  )
}

export default UserAgreementPage
