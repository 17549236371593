const rxpadAdminAgreementContent = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>RxPad Administrator Terms and Conditions</title>
    <style>
        body {
            font-family: Arial, Helvetica, sans-serif;
            line-height: 1.6;
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
            color: #333;
            background-color: #fff;
        }
        header {
            text-align: center;
            margin-bottom: 30px;
            color: #ff6600;
        }
        h1 {
            font-size: 24px;
            margin-bottom: 5px;
            color: #ff6600;
        }
        h2 {
            font-size: 18px;
            margin-top: 20px;
            margin-bottom: 10px;
            color: #ff6600;
        }
        .section {
            margin-bottom: 20px;
        }
        ol {
            counter-reset: section;
            list-style-type: none;
            padding-left: 0;
        }
        ol > li {
            counter-increment: section;
            margin-bottom: 15px;
        }
        ol > li::before {
            content: counter(section) " ";
            font-weight: bold;
            color: #ff6600;
        }
        ol > li > ol {
            counter-reset: subsection;
            list-style-type: none;
            padding-left: 20px;
        }
        ol > li > ol > li {
            counter-increment: subsection;
            margin-bottom: 10px;
        }
        ol > li > ol > li::before {
            content: counter(section) "." counter(subsection) " ";
            font-weight: bold;
            color: black;
        }
        .sub-list {
            list-style-type: lower-alpha;
            padding-left: 40px;
        }
        .sub-list li {
            margin-bottom: 5px;
        }
        .sub-sub-list {
            list-style-type: lower-alpha;
            padding-left: 20px;
        }
        .sub-sub-list li {
            margin-bottom: 5px;
        }
        .footer {
            margin-top: 30px;
            border-top: 1px solid #ff6600;
            padding-top: 20px;
            font-size: 14px;
            color: #444;
        }
        .footer > strong {
            color: black;
            font-weight: bold;
        }
        .telecare-header {
            text-align: right;
            color: #ff6600;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        strong {
            color: #ff6600;
            font-weight: bold;
        }
        ol > li > ol > li > strong {
            color: black;
            font-weight: bold;
        }
        a {
            color: #0077b6;
            text-decoration: none;
        }
        a:hover {
            text-decoration: underline;
        }
    </style>
</head>
<body>
    <header>
        <h1>RxPad</h1>
        <h1>Administrator Terms and Conditions</h1>
    </header>

    <ol>
        <li>
            <strong>Introduction</strong>
            <ol>
                <li>These terms and conditions (<strong>Terms</strong>) are entered into between Telecare Australia Pty Ltd (ACN 632 722 911) (<strong>we</strong>, <strong>us</strong> or  <strong>our</strong>) and you, together the <strong>Parties</strong> and each a <strong>Party</strong>.</li>
                <li>We provide a cloud-based, software as a service platform "RxPad" where health professionals can issue electronic prescriptions to their patients (<strong>Platform</strong>).</li>
                <li>You are accessing the Platform as an authorised 'Admin' user of the account holder who is our client (<strong>Account Holder</strong>). If the Account Holder's agreement with us is suspended or terminated for any reason, your access to the Platform will also be suspended or terminated.</li>
            </ol>
        </li>

        <li>
            <strong>Acceptance and Platform Licence</strong>
            <ol>
                <li>By clicking on "I accept" (or similar button or checkbox) or by using or accessing the Platform, you accept these Terms.</li>
                <li>We may amend these Terms at any time, by providing written notice to you. By accepting or continuing to use the Platform after the notice or 30 days after notification (whichever date is earlier), you agree to the amended Terms. If you do not agree to the amendment, you may cease accessing the Platform.</li>
                <li>Subject to your compliance with these Terms, we grant you a personal, non-exclusive, royalty-free, revocable, worldwide, non-transferable licence to use our Platform in accordance with these Terms. All other uses are prohibited without our prior written consent.</li>
                <li>When using the Platform, you must not do or attempt to do anything that is unlawful or inappropriate, including:
                    <ul class="sub-list">
                        <li>anything that would constitute a breach of an individual's privacy (including uploading private or personal information without an individual's consent) or any other legal rights;</li>
                        <li>using the Platform to defame, harass, threaten, menace or offend any person, including using the Platform to send unsolicited electronic messages;</li>
                        <li>tampering with or modifying the Platform (including by transmitting viruses and using trojan horses);</li>
                        <li>using data mining, robots, screen scraping or similar data gathering and extraction tools on the Platform; or</li>
                        <li>facilitating or assisting a third party to do any of the above acts.</li>
                    </ul>
                </li>
            </ol>
        </li>

        <li>
            <strong>Access to the Platform</strong>
            <ol>
                <li>You may be required to register on the Platform to access the Platform's features.</li>
                <li>Admin users must be invited to the Platform by an authorised Account Holder. Only an authorised Account Holder on the Platform may initiate invitations for Admin users.</li>
                <li>Upon receiving an invitation, you must complete the registration process, which includes accepting these Terms, before accessing the Platform. You may be required to provide us with certain information in order to register on the Platform, which may include information to verify your identity.</li>
                <li>All personal information you provide to us will be treated in accordance with our Privacy Policy.</li>
                <li>You agree not to share your login details with any other person. Your login is personal and you must not transfer or provide it to others.</li>
                <li>You are responsible for keeping your login details and your username and password confidential and you will be liable for all activity via your login. You agree to immediately notify us of any unauthorised use of your login.</li>
                <li>As an Admin user, you are granted limited access to the Platform to perform administrative tasks such as inputting patient information. You are expressly prohibited from prescribing medication or preparing medication information ahead of time. Your access is strictly limited to the functionalities necessary for your administrative role. We reserve the right to modify, add, or remove functionalities available to Admin users from time to time.</li>
            </ol>
        </li>

        <li>
            <strong>Our Intellectual Property</strong>
            <ol>
                <li>You acknowledge and agree that any Intellectual Property or content (including copyright and trademarks) available on the Platform, the Platform itself, and any algorithms or machine learning models used on the Platform (Our Intellectual Property) will at all times vest, or remain vested, in us or our licensors.</li>
                <li>We authorise you to use Our Intellectual Property solely for your personal use. You must not exploit Our Intellectual Property for any other purpose, nor allow, aid or facilitate such use by any third party.</li>
                <li>You must not, without our prior written consent:
                    <ul class="sub-list">
                        <li>copy, in whole or in part, any of Our Intellectual Property;</li>
                        <li>reproduce, retransmit, distribute, disseminate, sell, publish, broadcast or circulate any of Our Intellectual Property to any third party; or</li>
                        <li>breach any intellectual property rights connected with the Platform, including (without limitation) altering or modifying any of Our Intellectual Property, causing any of Our Intellectual Property to be framed or embedded in another website, or creating derivative works from any of Our Intellectual Property.</li>
                    </ul>
                </li>
            </ol>
        </li>

        <li>
            <strong>Obligations and Warranties</strong>
            <ol>
                <li>You must not use the Platform to send prescriptions or otherwise to perform functions that you do not have the necessary qualifications or licenses to perform.</li>
                <li>You represent, warrant and agree that:
                    <ul class="sub-list">
                        <li>you will comply with all applicable codes, laws, standards and regulations, including:
                            <ul class="sub-sub-list">
                                <li>the Health Practitioner National Law;</li>
                                <li>the Medical Board of Australia Code of Conduct; and</li>
                                <li>any relevant State and Territory Health Records Acts;</li>
                            </ul>
                        </li>
                        <li>you will promptly notify us of any breaches of any laws, rules, regulations, industry codes or serious claims for misconduct or professional negligence by you;</li>
                        <li>you will hold all licenses, qualifications and</li>
                        <li>you will not use our Platform, including Our Intellectual Property, in any way that competes with our business;</li>
                        <li>you will only the Platform as permitted by these Terms;</li>
                        <li>there are no legal restrictions preventing you from entering into these Terms;</li>
                        <li>all information and documentation that you provide to us in connection with these Terms is true, correct and complete; and</li>
                        <li>you have not relied on any representations or warranties made by us in relation to the Platform (including as to whether the Platform is or will be fit or suitable for your particular purposes), unless expressly stipulated in these Terms.</li>
                    </ul>
                </li>
            </ol>
        </li>

        <li>
            <strong>Liability</strong>
            <ol>
                <li>Despite anything to the contrary, without limiting any rights you may have as a consumer under the Schedule 2 of the Competition and Consumer Act 2010 (Cth) (Australian Consumer Law):
                    <ul class="sub-list">
                        <li>neither Party will be liable for Consequential Loss;</li>
                        <li>each Party's liability for any Liability under these Terms will be reduced proportionately to the extent the relevant Liability was caused or contributed to by the acts or omissions of the other Party or any of that Party's personnel, including any failure by that Party to mitigate its losses; and</li>
                        <li>we will have no Liability towards you in relation to these Terms or your use of the Platform.</li>
                    </ul>
                </li>
            </ol>
        </li>

        <li>
            <strong>Confidential Information</strong>
            <ol>
                <li>As an Admin user, you may have access to confidential information, including patient information through the Platform. You agree to:
                    <ul class="sub-list">
                        <li>maintain the confidentiality of all patient information;</li>
                        <li>access such information only as necessary for your administrative duties;</li>
                        <li>not disclose patient information to any third party without explicit authorisation;</li>
                        <li>comply with all applicable privacy laws and regulations; and</li>
                        <li>immediately report any unauthorised access or disclosure of patient information to us and the Account Holder.</li>
                    </ul>
                </li>
            </ol>
        </li>

        <li>
            <strong>Personal Information</strong>
            <ol>
                <li>You must comply with all applicable privacy laws and regulations, as well as the privacy policies and procedures of your employer (the Account Holder).</li>
                <li>We collect, hold and disclose and use any Personal Information you provide to us in accordance with our privacy policy, available at <a href="https://www.telecare.com.au/privacy-policy">https://www.telecare.com.au/privacy-policy</a>, and applicable Privacy Laws.</li>
            </ol>
        </li>

        <li>
            <strong>Access</strong>
            <ol>
                <li>We may revoke your access to the Platform at any time immediately without notice to you.</li>
                <li>Should we suspect that you are in breach of these Terms, we may suspend your access to the Platform while we investigate the suspected breach. Should we determine that you are in breach of these Terms, your access to the Platform will be terminated immediately.</li>
            </ol>
        </li>

        <li>
            <strong>General</strong>
            <ol>
                <li><strong>Assignment</strong>: You must not assign or deal with the whole or any part of your rights or obligations under these Terms without our prior written consent.</li>
                <li><strong>Disputes</strong>: A Party may not commence court proceedings relating to a dispute without first meeting with the other Party to seek (in good faith) to resolve the dispute, failing which the Parties agree to engage a mediator to attempt to resolve the dispute. The costs of the mediation will be shared equally between the Parties. Nothing in this clause will operate to prevent a Party from seeking urgent injunctive or equitable relief from a court of appropriate jurisdiction.</li>
                <li><strong>Force Majeure</strong>: To the maximum extent permitted by law, we shall have no Liability for any event or circumstance outside of our reasonable control.</li>
                <li><strong>Governing law</strong>: These Terms are governed by the laws of Victoria. Each Party irrevocably and unconditionally submits to the exclusive jurisdiction of the courts operating in Victoria and any courts entitled to hear appeals from those courts and waives any right to object to proceedings being brought in those courts.</li>
                <li><strong>Notices</strong>: Any notice given under these Terms must be in writing addressed to us at the details set out below or to you at the details provided when you sign up to the Platform. Any notice may be sent by standard post or email, and will be deemed to have been served on the expiry of 48 hours in the case of post, or at the time of transmission in the case of transmission by email.</li>
                <li><strong>Severance</strong>: If a provision of these Terms is held to be void, invalid, illegal or unenforceable, that provision is to be read down as narrowly as necessary to allow it to be valid or enforceable, failing which, that provision (or that part of that provision) will be severed from these Terms without affecting the validity or enforceability of the remainder of that provision or the other provisions in these Terms.</li>
            </ol>
        </li>

        <li>
            <strong>Definitions</strong>
            <ol>
                <li><strong>Consequential Loss</strong> includes any consequential loss, indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of data, whether under statute, contract, equity, tort (including negligence), indemnity or otherwise.</li>
                <li><strong>Intellectual Property Rights</strong> or <strong>Intellectual Property</strong> means any and all existing and future rights throughout the world conferred by statute, common law, equity or any corresponding law in relation to any copyright, designs, patents or trade marks, domain names, know-how, inventions, processes, trade secrets (including the right to keep confidential information confidential), circuit layouts, software, computer programs, databases or source codes, including any application, or right to apply, for registration of, and any improvements, enhancements or modifications of, the foregoing, whether or not registered or registrable.</li>
                <li><strong>Liability</strong> means any expense, cost, liability, loss, damage, claim, notice, entitlement, investigation, demand, proceeding or judgment (whether under statute, contract, equity, tort (including negligence), indemnity or otherwise), howsoever arising, whether direct or indirect and/or whether present, unascertained, future or contingent and whether involving a third party or a party to these Terms or otherwise.</li>
                <li><strong>Personal Information</strong> means any information or opinion about an identified individual, or an individual who is reasonably identifiable, whether the information or opinion is true or not, and whether the information or opinion is recorded in a tangible form or not.</li>
            </ol>
        </li>
    </ol>

    <div class="footer">
        <strong>For any questions or notices, please contact us at:</strong>
        <p>Telecare Australia Pty Ltd (ACN 632 722 911)<br>
        Email: <a href="mailto:support@rxpad.com.au">support@rxpad.com.au</a></p>
        <p>&copy; LegalVision ILP Pty Ltd</p>
    </div>
</body>
</html>
`

export const rxpadAdminAgreement = {
  revisionDate: '19-March-2025',
  contentHTML: rxpadAdminAgreementContent,
}
