import { useEffect, useState } from 'react'
import {
  Badge,
  Box,
  Button,
  Code,
  Container,
  Divider,
  Heading,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AxiosError, AxiosResponse } from 'axios'
import useApi from '../../common/utils/api/useApi'
import { InvitationApiClient } from './InvitationSlice'
import { InvitationStatus, UserInvitation } from './type/UserInvitation'
import { routes } from '../../pages/routes'
import { useAppSelector } from '../../app/typedReduxHooks'
import { Permission } from '../../types/permission'

const getAdminType = (permissions: Permission[]): string => {
  if (permissions?.includes(Permission.PRESCRIPTION_MANAGEMENT)) {
    return 'Rx Admin'
  }
  return 'Patient Admin'
}

export const ApproveInvitationPage = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const toast = useToast()
  const [invitation, setInvitation] = useState<UserInvitation | null>(null)
  const authInfo = useAppSelector((state) => state.authentication)
  const adminProfile = useAppSelector((state) => state.organizationAdmin.profile)
  const organization = useAppSelector((state) => state.organization.data)

  const invitationToken = searchParams.get('invitation_token')

  const { trigger: getInvitationByToken, loading: getInvitationLoading } = useApi(
    InvitationApiClient.getInvitationByToken,
    {
      onSuccess: (data: UserInvitation) => {
        setInvitation(data)
      },
      onFailure: (error: AxiosError) => {
        const response = error.response as AxiosResponse<{ message: string }> | undefined
        toast({
          title: 'Error',
          description: response?.data?.message
            ? response.data.message
            : 'Failed to fetch invitation details',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    }
  )
  const { trigger: approveInvitation, loading: approveInvitationLoading } = useApi(
    InvitationApiClient.approveInvitation,
    {
      onSuccess: (resp) => {
        toast({
          title: 'Success',
          description: resp.isApproved
            ? 'Invitation has been approved'
            : 'Invitation has been rejected',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        })
        navigate(routes.settings.path)
      },
      onFailure: (error: AxiosError) => {
        const response = error.response as AxiosResponse<{ message: string }> | undefined
        toast({
          title: 'Error',
          description: response?.data?.message
            ? response.data.message
            : 'Failed to approve/reject invitation',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        })
      },
    }
  )

  useEffect(() => {
    if (!invitationToken) return
    getInvitationByToken({ invitationToken })
  }, [invitationToken])

  if (authInfo.loginFinished) {
    if (!adminProfile?.isOwner) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          paddingTop="10vh"
          minHeight="100vh"
          width="100%"
        >
          <Container
            maxW="container.sm"
            py={10}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <VStack spacing={8} width="100%">
              <Heading color="red.500">Permission Denied</Heading>
              <Box w="100%" p={6} borderWidth="1px" borderRadius="lg" bg="red.50">
                <VStack align="stretch" spacing={4}>
                  <Text fontSize="lg" fontWeight="bold">
                    You don't have permission to approve invitations
                  </Text>
                  <Text>
                    Only organization owners can approve or reject invitations. Please contact your
                    organization owner for assistance.
                  </Text>
                  <Button colorScheme="blue" onClick={() => navigate(routes.root.path)}>
                    Return to Home
                  </Button>
                </VStack>
              </Box>
            </VStack>
          </Container>
        </Box>
      )
    }

    if (invitation && invitation.organizationId !== organization?.id) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          paddingTop="10vh"
          minHeight="100vh"
          width="100%"
        >
          <Container
            maxW="container.sm"
            py={10}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <VStack spacing={8} width="100%">
              <Heading color="red.500">Organization Mismatch</Heading>
              <Box w="100%" p={6} borderWidth="1px" borderRadius="lg" bg="red.50">
                <VStack align="stretch" spacing={4}>
                  <Text fontSize="lg" fontWeight="bold">
                    This invitation belongs to a different organization
                  </Text>
                  <Text>
                    You are logged in as an owner of {organization?.name}, but this invitation is
                    for organization ID: {invitation.organizationId}.
                  </Text>
                  <Button colorScheme="blue" onClick={() => navigate(routes.root.path)}>
                    Return to Home
                  </Button>
                </VStack>
              </Box>
            </VStack>
          </Container>
        </Box>
      )
    }
  }

  if (!invitationToken) {
    return (
      <Container centerContent py={10}>
        <Text>No invitation token provided</Text>
      </Container>
    )
  }

  if (getInvitationLoading) {
    return (
      <Container centerContent py={10}>
        <Spinner size="xl" />
      </Container>
    )
  }

  if (!invitation) {
    return (
      <Container centerContent py={10}>
        <Text>Invitation not found</Text>
      </Container>
    )
  }

  const handleApprove = async () => {
    await approveInvitation({
      invitationToken,
      isApproved: true,
    })
  }

  const handleReject = async () => {
    await approveInvitation({
      invitationToken,
      isApproved: false,
    })
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      paddingTop="10vh"
      minHeight="100vh"
      width="100%"
    >
      <Container
        maxW="container.sm"
        py={10}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <VStack spacing={8} width="100%">
          <Heading>Approve Invitation</Heading>

          <Box w="100%" p={6} borderWidth="1px" borderRadius="lg">
            <VStack align="stretch" spacing={4}>
              <Text>
                Token: <Code>{invitation.token}</Code>
              </Text>
              <Divider />

              <Text fontSize="lg" fontWeight="bold">
                Invitation Details:
              </Text>
              <Text>
                Inviter: {invitation.inviterName}({invitation.inviterEmail})
              </Text>
              <Text>Invitee Email: {invitation.email}</Text>
              <Text>Roles: {getAdminType(invitation.permissions)}</Text>
              <Text>Organization ID: {invitation.organizationId}</Text>
              {invitation.organizationId === organization?.id ? (
                invitation.status === InvitationStatus.PENDING_OWNER_APPROVAL ? (
                  <Box display="flex" gap={4} mt={4}>
                    <Button
                      colorScheme="blue"
                      size="lg"
                      flex="1"
                      onClick={handleApprove}
                      isLoading={approveInvitationLoading}
                      disabled={approveInvitationLoading}
                    >
                      Approve
                    </Button>
                    <Button
                      colorScheme="red"
                      size="lg"
                      flex="1"
                      onClick={handleReject}
                      isLoading={approveInvitationLoading}
                      disabled={approveInvitationLoading}
                    >
                      Reject
                    </Button>
                  </Box>
                ) : invitation.status === InvitationStatus.OWNER_REJECTED ? (
                  <Badge
                    variant="solid"
                    colorScheme={'red'}
                    alignSelf={'center'}
                    width={'50%'}
                    py={2}
                    textAlign={'center'}
                    rounded={'md'}
                    fontSize={'lg'}
                  >
                    Rejected
                  </Badge>
                ) : (
                  <Badge
                    variant="solid"
                    colorScheme={'green'}
                    alignSelf={'center'}
                    width={'50%'}
                    py={2}
                    textAlign={'center'}
                    rounded={'md'}
                    fontSize={'lg'}
                  >
                    Approved
                  </Badge>
                )
              ) : null}
            </VStack>
          </Box>
        </VStack>
      </Container>
    </Box>
  )
}
