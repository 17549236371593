import { FunctionComponent } from 'react'
import LoginPage from './AuthenticationPage/LoginPage'
import AuthenticationPage from './AuthenticationPage/AuthenticationPage'
import NewScriptPage from './NewScriptPage/NewScriptPage'
import PatientsPage from './PatientsPage/PatientsPage'
import PrescriptionsPage from './PrescriptionsPage/PrescriptionsPage'
import PrescriberProfileCreationPage from './PrescriberProfileCreationPage/PrescriberProfileCreationPage'
import GenericErrorPage from './ErrorPage/GenericErrorPage'
import LoadingPage from './LoadingPage'
import OrganizationCreationDeveloperPage from './OrganizationCreationDeveloperPage/OrganizationCreationDeveloperPage'
import ClinicCreationPage from './ClinicCreationPage/ClinicCreationPage'
import OrganizationLinkPage from './OrganizationCreationPage/OrganizationLinkPage'
import PricePlanPage from './Billing/PricePlanPage'
import { ReferralPage } from '../features/refer/ReferralPage'
import { ExperimentsPage } from '../features/experiments/ExperimentsPage'
import { SSOLandingPage } from '../features/sso/SSOLandingPage'
import OrganizationAdminCreationPage from '../features/organization-admin/OrganizationAdminCreationPage'
import { SelectRolesPage } from '../features/select-roles/SelectRolesPage'
import AcceptInvitationPage from '../features/user-invitations/AcceptInvitationPage'
import { ApproveInvitationPage } from '../features/user-invitations/ApproveInvitationPage'
import SettingsPage from './SettingsPage/SettingsPage'

export interface AppRoute {
  path: string
  Component: FunctionComponent
  requiresAuth?: boolean
}

export const routes = {
  acceptUserInvitation: {
    path: '/invitations/accept',
    Component: AcceptInvitationPage,
    requiresAuth: false,
  },
  approveUserInvitation: {
    path: '/invitations/approve',
    Component: ApproveInvitationPage,
    requiresAuth: true,
  },
  authWithClientId: {
    path: '/auth/:clientId',
    Component: AuthenticationPage,
    requiresAuth: false,
  },
  createAdminProfile: {
    path: '/create-admin-profile',
    Component: OrganizationAdminCreationPage,
    requiresAuth: true,
  },
  createClinic: {
    path: '/create-organization/new-clinic',
    Component: ClinicCreationPage,
    requiresAuth: true,
  },
  createOrganizationDeveloper: {
    path: '/admin-tools/crt-org',
    Component: OrganizationCreationDeveloperPage,
    requiresAuth: false,
  },
  createPrescriberProfile: {
    path: '/create-prescriber-profile',
    Component: PrescriberProfileCreationPage,
    requiresAuth: true,
  },
  error: {
    path: '/error',
    Component: GenericErrorPage,
    requiresAuth: false,
  },
  experiments: {
    path: '/experiments',
    Component: ExperimentsPage,
    requiresAuth: true,
  },
  linkOrganization: {
    path: '/create-organization/link-to-clinic',
    Component: OrganizationLinkPage,
    requiresAuth: true,
  },
  login: {
    path: '/login',
    Component: LoginPage,
    requiresAuth: false,
  },
  newScript: {
    path: '/new-script',
    Component: NewScriptPage,
    requiresAuth: true,
  },
  patients: {
    path: '/patients',
    Component: PatientsPage,
    requiresAuth: true,
  },
  patientScripts: {
    path: '/prescribers/:prescriberId/patients/:patientId/scripts',
    Component: PrescriptionsPage,
    requiresAuth: true,
  },
  prescriptions: {
    path: '/prescriptions',
    Component: PrescriptionsPage,
    requiresAuth: true,
  },
  pricePlan: {
    path: '/price-plans',
    Component: PricePlanPage,
    requiresAuth: true,
  },
  refer: {
    path: '/refer',
    Component: ReferralPage,
    requiresAuth: true,
  },
  root: {
    path: '/',
    Component: LoadingPage,
    requiresAuth: true,
  },
  selectRoles: {
    path: '/select-roles',
    Component: SelectRolesPage,
    requiresAuth: true,
  },
  settings: {
    path: '/settings',
    Component: SettingsPage,
    requiresAuth: true,
  },
  sso: {
    path: '/sso/:ssoClientNameParam',
    Component: SSOLandingPage,
    requiresAuth: false,
  },
}
