import { OrganizationAdminForm } from './components/OrganizationAdminForm'
import { useAppDispatch, useAppSelector } from '../../app/typedReduxHooks'
import {
  OrganizationAdminClient,
  OrganizationAdminFormData,
  setOrganizationAdmin,
} from './organizationAdminSlice'
import { useForm } from 'react-hook-form'
import { Button, VStack } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import useApi from '../../common/utils/api/useApi'

export const OrganizationAdminProfilePage = () => {
  const adminProfile = useAppSelector((state) => state.organizationAdmin?.profile)
  const dispatch = useAppDispatch()
  const { trigger: getAdminProfile } = useApi(OrganizationAdminClient.getAdminProfile, {
    onSuccess: ({ data }) => {
      if (data) {
        dispatch(setOrganizationAdmin(data))
      }
    },
  })

  const { trigger: updateAdminProfile, loading: updateLoading } = useApi(
    OrganizationAdminClient.updateAdminProfile,
    {
      onSuccess: async ({ data: { id } }) => {
        if (id) {
          await getAdminProfile({ id })
        }
      },
    }
  )

  const onUpdate = async (data: OrganizationAdminFormData) => {
    if (adminProfile?.id) {
      await updateAdminProfile({
        id: adminProfile.id,
        data,
      })
    }
  }
  const adminProfileFormData: OrganizationAdminFormData | undefined = adminProfile
    ? {
        givenName: adminProfile.givenName ?? '',
        familyName: adminProfile.familyName ?? '',
        email: adminProfile.email ?? '',
      }
    : undefined
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<OrganizationAdminFormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: adminProfileFormData ?? {},
  })
  return (
    <VStack as="form" onSubmit={handleSubmit(onUpdate)} alignItems={'start'}>
      <OrganizationAdminForm
        {...{
          register,
          errors,
        }}
      />
      <Button
        width="200px"
        colorScheme="blue"
        type="submit"
        disabled={!isEmpty(errors) || updateLoading}
        isLoading={updateLoading}
      >
        Update
      </Button>
    </VStack>
  )
}
