import { FC, useEffect } from 'react'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'
import Page from '../../components/Page'
import PrescriberProfilePage from '../PrescriberProfilePage/PrescriberProfilePage'
import OrganizationProfilePage from '../OrganizationProfilePage'
import { SubscriptionInfo } from '../Billing/SubscriptionInfo'
import { RootState } from '../../app/store'
import { useAppDispatch, useAppSelector } from '../../app/typedReduxHooks'
import { BillingClient } from '../Billing/BillingSlice'
import { OrganizationAdminProfilePage } from '../../features/organization-admin/OrganizationAdminProfilePage'
import { UsersPage } from '../../features/users/UsersPage'
import UnauthorizedPage from '../../components/UnauthorizedPage'
import { Permission } from '../../types/permission'

const SettingsPage: FC<unknown> = () => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const authInfo = useAppSelector((state: RootState) => state.authentication)
  const loginFinished = authInfo.loginFinished
  const permissions = authInfo?.permissions ?? []
  const organizationId = useAppSelector((state: RootState) => state.organization?.data?.id)
  const organizationSettings = useAppSelector((state) => state.organization?.data?.settings)
  const isPrescriberActive = useAppSelector((state) => state.prescriber.prescriber?.active)
  const organizationAdmin = useAppSelector((state) => state.organizationAdmin.profile)
  const isAdminActive = organizationAdmin?.active
  const subscription = useAppSelector((state: RootState) => state.billing?.subscription.data)

  useEffect(() => {
    if (!subscription && organizationId) {
      dispatch(BillingClient.getSubscription({ organizationId }))
    }
  }, [organizationId, dispatch])

  useEffect(() => {
    if (!subscription && organizationId) {
      dispatch(BillingClient.getSubscription({ organizationId }))
    }
  }, [organizationId])

  const isAbleToManageBilling =
    subscription?.stripeSubscriptionId &&
    organizationAdmin?.permissions?.includes(Permission.BILLING_MANAGEMENT)

  // Define available tabs and their conditions
  const availableTabs = [
    { id: 'prescriber', isVisible: loginFinished && !!isPrescriberActive },
    { id: 'admin', isVisible: loginFinished && !!isAdminActive },
    { id: 'users', isVisible: loginFinished && !!organizationSettings?.enableUsersBoard },
    {
      id: 'organization',
      isVisible: loginFinished && !!organizationSettings?.enableUpdateOrganization,
    },
    { id: 'subscription', isVisible: loginFinished && isAbleToManageBilling },
  ]

  const currentTab = searchParams.get('tab')

  const currentTabIndex = availableTabs
    .filter((tab) => tab.isVisible)
    .findIndex((tab) => tab.id === currentTab)

  const handleTabChange = (index: number) => {
    const visibleTabs = availableTabs.filter((tab) => tab.isVisible)
    const selectedTab = visibleTabs[index]
    if (selectedTab) {
      setSearchParams({ tab: selectedTab.id })
    }
  }

  useEffect(() => {
    if (currentTabIndex === -1) {
      const firstVisibleTab = availableTabs.find(
        (tab) => ['prescriber', 'admin'].includes(tab.id) && tab.isVisible
      )
      if (firstVisibleTab) {
        setSearchParams({ tab: firstVisibleTab.id })
      }
    }
  }, [currentTabIndex, setSearchParams])

  if (permissions.length === 0) {
    return <UnauthorizedPage />
  }

  return (
    <Page>
      <Tabs
        size="lg"
        index={currentTabIndex !== -1 ? currentTabIndex : 0}
        onChange={handleTabChange}
      >
        <TabList>
          {loginFinished && isPrescriberActive ? (
            <Tab fontWeight="bold">Prescriber Profile</Tab>
          ) : null}
          {loginFinished && isAdminActive ? <Tab fontWeight="bold">Admin Profile</Tab> : null}
          {loginFinished && !!organizationSettings?.enableUsersBoard && (
            <Tab fontWeight="bold">Users</Tab>
          )}
          {loginFinished && organizationSettings?.enableUpdateOrganization ? (
            <Tab fontWeight="bold">Organisation</Tab>
          ) : null}
          {loginFinished && isAbleToManageBilling && <Tab fontWeight="bold">Subscription</Tab>}
        </TabList>
        <TabPanels>
          {loginFinished && isPrescriberActive ? (
            <TabPanel>
              <PrescriberProfilePage />
            </TabPanel>
          ) : null}
          {loginFinished && isAdminActive ? (
            <TabPanel>
              <OrganizationAdminProfilePage />
            </TabPanel>
          ) : null}
          {loginFinished && !!organizationSettings?.enableUsersBoard && (
            <TabPanel>
              <UsersPage />
            </TabPanel>
          )}
          {loginFinished && organizationSettings?.enableUpdateOrganization ? (
            <TabPanel>
              <OrganizationProfilePage />
            </TabPanel>
          ) : null}
          {loginFinished && isAbleToManageBilling && (
            <TabPanel>
              <SubscriptionInfo subscription={subscription} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Page>
  )
}

export default SettingsPage
