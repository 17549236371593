import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { FunctionComponent, useState } from 'react'
import { format, parseISO } from 'date-fns'
import { Patient } from '../../types'
import ConfirmationModal from '../../components/shared-components/ConfirmationModal'
import PatientForm from './PatientForm'
import { useDeletePatientMutation, useUpdatePatientMutation } from '../../services/patients'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { FetchBaseQueryErrorResult } from '../../services/baseQuery'
import { ServerError } from '../../types/result'
import { LimitedPrescriberProfile } from '../../features/users/UsersSlice'

interface UpdatePatientModalProps {
  patient: Patient
  isOpen: boolean
  onClose: () => any
  selectedPrescriber: LimitedPrescriberProfile | null
  isAbleToCreateOrDelete: boolean
}

const UpdatePatientModal: FunctionComponent<UpdatePatientModalProps> = ({
  patient,
  isOpen,
  onClose,
  selectedPrescriber,
  isAbleToCreateOrDelete,
}) => {
  const prescriber = useSelector(({ prescriber }: RootState) => prescriber?.prescriber)
  const effectivePrescriber = selectedPrescriber || (prescriber as LimitedPrescriberProfile)
  const effectivePrescriberId = effectivePrescriber?.id

  const tryOrElse = <T, R>(code: () => T, orElse: (err: unknown) => R) => {
    try {
      return code()
    } catch (err) {
      return orElse(err)
    }
  }

  const transformToPatientFormData = (patient: Patient): Patient => {
    let medicareExpire = ''
    if (patient.medicare_valid_to) {
      const medicareValidTo = tryOrElse(
        () => format(parseISO(patient.medicare_valid_to), 'yyyy-MM-dd'),
        () => 'Invalid Date'
      )
      const regex = /^\d{4}-\d{2}/
      const matched = medicareValidTo?.match(regex) ?? [medicareValidTo]
      medicareExpire = matched[0]
    }
    return {
      ...patient,
      medicare_valid_to: medicareExpire,
    }
  }
  const [shouldDisableSubmitButton, setShouldDisableSubmitButton] = useState(false)

  const {
    trigger,
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: transformToPatientFormData(patient),
  })
  const [updatePatient, updatePatientResult] = useUpdatePatientMutation()
  const [deletePatient] = useDeletePatientMutation()

  const updatePatientError = (updatePatientResult.error as FetchBaseQueryErrorResult['error'])
    ?.data as ServerError

  const onPatientUpdate = async (data: Patient) => {
    const result = await updatePatient({
      patient: data,
      prescriberId: effectivePrescriberId,
      patientId: patient.id,
    })
    if ('data' in result) {
      reset(data)
      onClose()
    }
  }
  const onPatientDelete = async () => {
    try {
      await deletePatient({ patientId: patient.id, prescriberId: effectivePrescriberId })
      reset()
      onClose()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset()
        onClose()
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={handleSubmit(onPatientUpdate)}
        width="100%"
        maxWidth="1000px"
      >
        <ModalHeader>Update Patient</ModalHeader>
        {updatePatientError?.error === 'duplicated-ihi-number' && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription
              fontSize={14}
            >{`Sorry, failed to update patient, and the error is ${updatePatientError.message}`}</AlertDescription>
          </Alert>
        )}
        <ModalCloseButton />
        <ModalBody>
          <PatientForm
            trigger={trigger}
            register={register}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            setShouldDisableSubmitButton={setShouldDisableSubmitButton}
            defaultPatient={patient}
            selectedPrescriber={effectivePrescriber}
            hidePrescriberSelection={true}
          />
        </ModalBody>

        <ModalFooter justifyContent="space-between">
          <ConfirmationModal
            title="Delete Patient"
            body="Are you sure you want to delete this patient?"
            onConfirm={() => {
              onPatientDelete()
            }}
          >
            {({ openModal }) => (
              <Button colorScheme="red" onClick={openModal} disabled={!isAbleToCreateOrDelete}>
                Delete
              </Button>
            )}
          </ConfirmationModal>
          <Button
            colorScheme="blue"
            type="submit"
            disabled={shouldDisableSubmitButton || !isAbleToCreateOrDelete}
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UpdatePatientModal
