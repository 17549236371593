import { ProductDetails } from '../../ProductSearchContextSlice'

export const getProductDisplayName = ({
  product,
  brandRequired = false,
}: {
  product: ProductDetails
  brandRequired: boolean
}): string => {
  const genericName = product.vis.map((vi) => vi.viName).join('+')
  if (!product.brand) {
    return genericName
  }
  if (product.aip?.lemi) {
    return product.productName
  }
  if (brandRequired) {
    return `${genericName} (${product.brand.brandName})`
  }
  return genericName
}

export const getProductDisplayNameNew = ({
  brandRequired,
  genericName,
  tradeName,
  product,
}: {
  brandRequired: boolean
  tradeName: string
  genericName: string
  product: ProductDetails | undefined
}): string => {
  if (product?.aip?.lemi) {
    return tradeName || product.productName
  }

  if (brandRequired && !!product?.brand?.brandName) {
    return `${genericName} (${product.brand.brandName})`
  }

  return genericName || (product ? product.vis.map((vi) => vi.viName).join('+') : '')
}
