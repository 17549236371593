import { createAsyncThunk } from '@reduxjs/toolkit'
import { AuthenticationClient } from '../features/authentication/slice/authenticationSlice'
import { fetchOrganizationData } from '../pages/OrganizationProfilePage/OrganizationSlice'
import { RootState } from './store'

export const appDidFinishLaunching = createAsyncThunk(
  'app/did_finish_launching',
  async (_, { dispatch }) => {
    await dispatch(AuthenticationClient.Login())
    await dispatch(dataPrefetching())
  }
)

const dataPrefetching = createAsyncThunk('app/prefetching', async (_, { dispatch, getState }) => {
  const prescriber = (getState() as RootState).prescriber?.prescriber
  const admin = (getState() as RootState).organizationAdmin?.profile
  const organization = prescriber ? prescriber.organization : admin?.organization
  const orgId = organization?.id
  if (orgId) {
    await dispatch(fetchOrganizationData(orgId))
  }
})

// TODO: Add slice to handle global error or notifications if needed.
