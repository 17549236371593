import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { Fragment, FunctionComponent, useState } from 'react'
import PatientForm from './PatientForm'
import { Patient } from '../../types'
import {
  CreatePatientRequest,
  emptyPatient,
  useCreatePatientMutation,
} from '../../services/patients'
import { FetchBaseQueryErrorResult } from '../../services/baseQuery'
import { ServerError } from '../../types/result'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { LimitedPrescriberProfile } from '../../features/users/UsersSlice'

interface NewPatientModalProps {
  onAdd?: (patient: Patient) => void
  children: FunctionComponent<{
    openModal: () => void
  }>
  hidePrescriberSelection?: boolean
  selectedPrescriber: LimitedPrescriberProfile | null
  onPrescriberChange?: (id: string) => void
}

const NewPatientModal: FunctionComponent<NewPatientModalProps> = ({
  onAdd,
  children: ChildrenComponent,
  hidePrescriberSelection = false,
  selectedPrescriber,
  onPrescriberChange,
}) => {
  const toast = useToast()
  const [modalIsOpen, setModelIsOpen] = useState<boolean>(false)
  const [shouldDisableSubmitButton, setShouldDisableSubmitButton] = useState(false)
  const prescriber = useSelector(({ prescriber }: RootState) => prescriber?.prescriber)
  const [createPatient, createPatientResult] = useCreatePatientMutation()
  const createPatientError = (createPatientResult.error as FetchBaseQueryErrorResult['error'])
    ?.data as ServerError

  const effectivePrescriber = hidePrescriberSelection ? prescriber : selectedPrescriber
  const hasValidHpii = !!effectivePrescriber?.hpii

  const {
    trigger,
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: emptyPatient,
  })

  const handleCreatePatient = async (data: CreatePatientRequest) => {
    if (!hasValidHpii) {
      toast({
        title: 'Cannot create patient',
        description: 'Selected prescriber does not have a valid HPII number',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      return
    }

    const result = await createPatient({
      patient: data,
      prescriberId: effectivePrescriber?.id,
    })

    if ('data' in result) {
      onAdd?.(result.data)
      reset()
      setModelIsOpen(false)
    }
  }

  const handleCloseCreation = () => {
    reset()
    setModelIsOpen(false)
  }

  return (
    <Fragment>
      <ChildrenComponent openModal={() => setModelIsOpen(true)} />
      <Modal isOpen={modalIsOpen} onClose={handleCloseCreation} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent
          as="form"
          width="100%"
          maxWidth="1000px"
          onSubmit={handleSubmit(handleCreatePatient)}
        >
          <ModalHeader>New Patient</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PatientForm
              trigger={trigger}
              register={register}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              setShouldDisableSubmitButton={setShouldDisableSubmitButton}
              defaultPatient={undefined}
              selectedPrescriber={selectedPrescriber}
              onPrescriberChange={onPrescriberChange}
              hidePrescriberSelection={hidePrescriberSelection}
            />
          </ModalBody>
          <ModalFooter>
            {createPatientError && (
              <Alert status="error" mr={3}>
                <AlertIcon />
                <AlertDescription>{createPatientError.message}</AlertDescription>
              </Alert>
            )}
            <Button
              colorScheme="blue"
              type="submit"
              isDisabled={shouldDisableSubmitButton}
              isLoading={createPatientResult.isLoading}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  )
}

export default NewPatientModal
