import {
  Button,
  Card,
  Heading,
  Link,
  List,
  ListIcon,
  ListItem,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import TitleWithLogo from '../../components/TitleWithLogo'
import { CheckIcon, EmailIcon } from '@chakra-ui/icons'
import useApi from '../../common/utils/api/useApi'
import { BillingClient } from './BillingSlice'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/typedReduxHooks'
import { RootState } from '../../app/store'
import { useGAContextData } from '../../utils/google-analytics/useGAContextData'
import GoogleAnalyticsTagManager from '../../utils/google-analytics/GoogleAnalyticsTagManager'

const PricePlanPage = () => {
  const toast = useToast()
  const dispatch = useAppDispatch()
  const [disabled, setDisabled] = useState(false)
  const [standardSeats, setStandardSeats] = useState(1)
  const [unlimitedSeats, setUnlimitedSeats] = useState(1)
  const organizationId = useAppSelector((state: RootState) => state.organization?.data?.id)
  const subscription = useAppSelector((state: RootState) => state.billing?.subscription.data)
  const admin = useAppSelector((state) => state.organizationAdmin.profile)
  const isMultipleSeatsSupport = location.search.includes('multiple_seats')
  const GAContextData = useGAContextData()
  useEffect(() => {
    if (!subscription && organizationId) {
      dispatch(BillingClient.getSubscription({ organizationId }))
    }
  }, [organizationId, dispatch])

  useEffect(() => {
    setDisabled(!admin?.isOwner || !!subscription)
  }, [subscription, admin])
  const { trigger: triggerCreateCheckoutSession, loading } = useApi(
    BillingClient.createCheckoutSession,
    {
      onSuccess: (checkoutSessionUrl: string) => {
        window.location.href = checkoutSessionUrl
      },
      onFailure: () => {
        toast({
          title: 'Create subscription failed',
          description:
            'You may already have a valid subscription or do not have permission to do it. Please contact your organisation owner to buy/update the plan.',
          status: 'error',
          isClosable: true,
          duration: 3000,
        })
        setDisabled(true)
      },
    }
  )

  const getStarted = (pricePlan: string, seats: number) => {
    return () => {
      if (seats === 0) {
        toast({
          title: 'You have to choose at least 1 seat',
          status: 'warning',
          isClosable: true,
          duration: 3000,
        })
        return
      }
      GoogleAnalyticsTagManager.sendGetStartedPaymentEvent({ ...GAContextData, pricePlan })
      if (organizationId) {
        triggerCreateCheckoutSession({ pricePlan, organizationId, seats: seats || 1 })
      }
    }
  }

  return (
    <VStack overflow="auto" p={['16px', '24px']} minHeight="100%" spacing={[8, 8, 28]}>
      <TitleWithLogo title={'Choose Your Plan'} />
      <SimpleGrid
        width={['90%', '80%', '100%', '80%', '70%']}
        columns={[1, 1, 3]}
        spacing={8}
        alignItems={'stretch'}
      >
        {/* STANDARD Card */}
        <Card>
          <VStack spacing={6} height="100%" p={8}>
            <VStack spacing={2}>
              <Heading size={'lg'} as={'h2'}>
                STANDARD
              </Heading>
              <VStack spacing={1}>
                <Text fontSize="6xl" as="b" fontFamily={'system-ui'} lineHeight="1.2">
                  $35
                </Text>
                <Text color="gray.600">per user per month</Text>
              </VStack>
            </VStack>

            <List spacing={4} flex="1">
              <ListItem display="flex" alignItems="center">
                <ListIcon as={CheckIcon} color="blue.500" boxSize={5} />
                <Text>30 scripts per month</Text>
              </ListItem>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={CheckIcon} color="blue.500" boxSize={5} />
                <Text>Send script via SMS</Text>
              </ListItem>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={CheckIcon} color="blue.500" boxSize={5} />
                <Text>Send script via email</Text>
              </ListItem>
            </List>

            <VStack spacing={4} width="100%">
              {isMultipleSeatsSupport && (
                <VStack spacing={2} width="100%">
                  <Text fontSize="sm" color="gray.600" fontWeight="medium">
                    Number of seats
                  </Text>
                  <NumberInput
                    defaultValue={1}
                    min={0}
                    max={999}
                    width="140px"
                    size="lg"
                    value={standardSeats}
                    onChange={(valueString) => {
                      const value = valueString ? valueString : '0'
                      setUnlimitedSeats(0)
                      setStandardSeats(parseInt(value))
                    }}
                  >
                    <NumberInputField textAlign="center" />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </VStack>
              )}
              <Button
                colorScheme={'blue'}
                disabled={loading || disabled}
                isLoading={loading}
                onClick={getStarted('STANDARD', standardSeats)}
                size="lg"
                width="100%"
              >
                GET STARTED
              </Button>
            </VStack>
          </VStack>
        </Card>

        {/* UNLIMITED Card */}
        <Card
          bgColor={'#032035'}
          color={'white'}
          position="relative"
          transform="scale(1.02)"
          boxShadow="xl"
        >
          <Text
            as={'b'}
            fontSize={'13px'}
            width={'100%'}
            bgColor={'darkcyan'}
            textAlign={'center'}
            py={2}
            letterSpacing="wider"
          >
            MOST POPULAR
          </Text>
          <VStack spacing={6} height="100%" p={8}>
            <VStack spacing={2}>
              <Heading size={'lg'} as={'h2'}>
                UNLIMITED
              </Heading>
              <VStack spacing={1}>
                <Text fontSize="6xl" as="b" fontFamily={'system-ui'} lineHeight="1.2">
                  $65
                </Text>
                <Text>per user per month</Text>
              </VStack>
            </VStack>

            <List spacing={4} flex="1">
              <ListItem display="flex" alignItems="center">
                <ListIcon as={CheckIcon} color="darkcyan" boxSize={5} />
                <Text>Unlimited scripts per month</Text>
              </ListItem>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={CheckIcon} color="darkcyan" boxSize={5} />
                <Text>Send script via SMS</Text>
              </ListItem>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={CheckIcon} color="darkcyan" boxSize={5} />
                <Text>Send script via email</Text>
              </ListItem>
            </List>

            <VStack spacing={4} width="100%">
              {isMultipleSeatsSupport && (
                <VStack spacing={2} width="100%">
                  <Text fontSize="sm" fontWeight="medium">
                    Number of seats
                  </Text>
                  <NumberInput
                    defaultValue={1}
                    min={0}
                    max={999}
                    width="140px"
                    size="lg"
                    value={unlimitedSeats}
                    bg="whiteAlpha.100"
                    borderColor="whiteAlpha.300"
                    onChange={(valueString) => {
                      const value = valueString ? valueString : '0'
                      setStandardSeats(0)
                      setUnlimitedSeats(parseInt(value))
                    }}
                  >
                    <NumberInputField textAlign="center" />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </VStack>
              )}
              <Button
                colorScheme={'teal'}
                disabled={loading || disabled}
                isLoading={loading}
                onClick={getStarted('UNLIMITED', unlimitedSeats)}
                size="lg"
                width="100%"
              >
                GET STARTED
              </Button>
            </VStack>
          </VStack>
        </Card>

        {/* GROUP Card */}
        <Card>
          <VStack spacing={6} height="100%" p={8}>
            <VStack spacing={2}>
              <Heading size={'lg'} as={'h2'}>
                GROUP
              </Heading>
              <Text fontSize="40px" as="b" fontFamily={'system-ui'} lineHeight="1.2">
                Contact Us
              </Text>
            </VStack>

            <VStack flex="1" justifyContent="center" spacing={6}>
              <Link
                href="mailto:support@rxpad.com.au"
                target="_blank"
                isExternal
                display="flex"
                alignItems="center"
                color="blue.500"
                fontSize="lg"
              >
                <EmailIcon mr={2} /> support@rxpad.com.au
              </Link>
              <Text textAlign="center" color="gray.600">
                Contact us for customised pricing if you run a healthcare organisation.
              </Text>
            </VStack>
          </VStack>
        </Card>
      </SimpleGrid>
    </VStack>
  )
}
export default PricePlanPage
