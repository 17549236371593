import { Alert, AlertIcon, Button, HStack, Input, Select, SimpleGrid, Text } from '@chakra-ui/react'
import {
  CreatePrescriberRequest,
  PrescriberBriefInfo,
  PrescriberClient,
} from '../../NewScriptPage/ScriptForm/PrescriberSlice'
import {
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form'
import InputField from '../../../components/InputField'
import luhn_validate from '../../../utils/validation-helpers/LuhnValidate'
import useApi from '../../../common/utils/api/useApi'
import { mapStatusToText } from '../../../utils/status/map-status-to-text'
import { PRESCRIBER_OPRIONS } from '../../PrescriberProfilePage/constants'
import { isValidPrescriberType } from '../../../utils/validation-helpers/PrescriberValidate'
import { TOOLTIP_TEXT } from '../../../constants'
import { useEffect, useState } from 'react'
import { FORM_STAGE } from '../constant'
import { isValidEmailFormat } from '../../../utils/validation-helpers/emailFormatValidate'
import { isValidPrecriberNumberFormat } from '../../../utils/validation-helpers/prescriberNumberValidate'
import { PrescriberCreation } from '../../../types/prescriber'
import { RootState } from '../../../app/store'
import { useAppDispatch, useAppSelector } from '../../../app/typedReduxHooks'
import { setPrescriberCreationPayload } from '../PrescriberCreationSlice'
import { trimValue } from '../../../utils/text-formatter'
import { useFeatureToggle } from '../../../common/utils/feature-toggle/useFeatureToggle'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../routes'
import { CheckCircleIcon } from '@chakra-ui/icons'
import { ssoClientConfigMappings } from '../../../features/sso/constant'
import { appDidFinishLaunching } from '../../../app/appSlice'
import { setOrganizationAdminCreationPayload } from '../../../features/organization-admin/organizationAdminSlice'

interface ProfileCreationFormProps {
  trigger: UseFormTrigger<PrescriberCreation>
  register: UseFormRegister<PrescriberCreation>
  setValue: UseFormSetValue<PrescriberCreation>
  errors: FieldErrorsImpl<PrescriberCreation>
  setError: UseFormSetError<PrescriberCreation>
  getValues: UseFormGetValues<PrescriberCreation>
  setFormStage?: (stage: FORM_STAGE) => void
}

const ProfileCreationForm = ({
  trigger: triggerFormErrors,
  register,
  errors,
  setValue,
  setError,
  getValues,
}: ProfileCreationFormProps) => {
  const { isFeatureEnabled: isReferralCodeFeatureOn } = useFeatureToggle('REFERRAL_CODE')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [HPIICheckingStatus, setHPIICheckingStatus] = useState(getValues().hpiiStatus === 'A')
  const [disabledNext, setDisabledNext] = useState(getValues().hpiiStatus !== 'A')
  const [referrerInfo, setReferrerInfo] = useState<PrescriberBriefInfo | null>(null)
  const [validReferrerCode, setValidReferrerCode] = useState<string | null>(null)
  const { authentication } = useAppSelector((state: RootState) => state)
  const { currentClinic } = useAppSelector((state) => state.clinic)
  const [HPIIGroup, setHPIIGroup] = useState({
    hpii: getValues().hpii,
    family_name: getValues().familyName,
  })
  const createPrescriberStatus = useAppSelector((state) => state.prescriber.status.CreatePrescriber)
  const clientId = localStorage.getItem('client_id') || ''
  const ssoClient = ssoClientConfigMappings.find((client) => client.clientId === clientId)
  const isEnableRewards = isReferralCodeFeatureOn && !ssoClient

  if (!clientId) {
    navigate(routes.login.path)
  }

  useEffect(() => {
    authentication.firstName && setValue('givenNames', authentication.firstName)
    authentication.familyName && setValue('familyName', authentication.familyName)
    authentication.email && setValue('email', authentication.email)
    if (ssoClient?.forcePhoneNumber) {
      setValue('phoneNumber', ssoClient.forcePhoneNumber)
      setValue('otherPhoneNumber', ssoClient.forcePhoneNumber)
    }
  }, [authentication, setValue, ssoClient])

  const { trigger: checkHpii, loading: checkHpiiLoading } = useApi(PrescriberClient.checkHpii, {
    onSuccess: (response) => {
      setValue('hpiiStatus', response.hpii_status)
      if (response.hpii_status === 'A') {
        setHPIICheckingStatus(true)
        setDisabledNext(false)
      }
      if (response.hpii_status === 'RS') {
        setValue('hpii', response.hpii)
        checkHpii({
          hpii: response.hpii,
          family_name: getValues().familyName,
          clinicId: currentClinic?.id || '',
        })
      }
    },
  })

  const { trigger: checkReferralCode, loading: checkReferralCodeLoading } = useApi(
    PrescriberClient.checkReferralCode,
    {
      onSuccess: (data) => {
        setReferrerInfo(data)
        setValidReferrerCode(getValues().referralCodeOfReferrer ?? null)
        delete errors.referralCodeOfReferrer
      },
      onFailure: () => {
        setError('referralCodeOfReferrer', {
          message: 'Invalid referral code, please fill in the correct one',
        })
        setValidReferrerCode(null)
        setReferrerInfo(null)
      },
    }
  )

  const onNext = async () => {
    triggerFormErrors().then((isValid) => {
      if (isValid) {
        dispatch(setOrganizationAdminCreationPayload(null))
        dispatch(setPrescriberCreationPayload(getValues()))
        navigate(routes.linkOrganization.path)
      }
    })
  }
  const onSubmit = async () => {
    if (!ssoClient) return
    triggerFormErrors().then(async (isValid) => {
      if (isValid) {
        dispatch(setPrescriberCreationPayload(getValues()))
      }
      const prescriberCreationPayload = getValues()
      await dispatch(
        PrescriberClient.CreatePrescriber({
          ...prescriberCreationPayload,
          clinicId: ssoClient?.clinicId,
          organizationId: ssoClient?.orgId,
        } as CreatePrescriberRequest)
      )
      await dispatch(appDidFinishLaunching())
      navigate(routes.newScript.path)
    })
  }
  const validateHpii = async () => {
    const values = getValues()

    checkHpii({
      hpii: values.hpii,
      family_name: values.familyName,
      clinicId: currentClinic?.id || '',
    })
  }
  const validateReferrer = async () => {
    const referralCodeOfReferrer = getValues().referralCodeOfReferrer
    if (!referralCodeOfReferrer) {
      return
    }
    await checkReferralCode(referralCodeOfReferrer)
  }
  const onBlur = (e: React.FormEvent<HTMLInputElement>, fieldName: keyof typeof HPIIGroup) => {
    const { value } = e.target as HTMLInputElement
    if (value !== HPIIGroup?.[fieldName]) {
      setHPIICheckingStatus(false)
      setDisabledNext(true)
      setHPIIGroup({ ...HPIIGroup, [fieldName]: value })
    }
  }
  const isPhoneNumberForced = !!ssoClient?.forcePhoneNumber
  const invalidHpii = !!errors?.hpii?.message

  return (
    <>
      <SimpleGrid columns={[1, 2]} rowGap={6} columnGap={20} style={{ marginBottom: '36px' }}>
        <InputField label="First Name" field="givenNames" isRequired={true} errors={errors}>
          <Input
            placeholder="First Name"
            {...register('givenNames', {
              required: 'Required',
              maxLength: {
                value: 40,
                message: 'Prescriber name must be less than 40 Characters',
              },
            })}
          ></Input>
        </InputField>
        <InputField label="Family Name" field="familyName" isRequired={true} errors={errors}>
          <Input
            placeholder="Family Name"
            {...register('familyName', {
              required: 'Required',
              maxLength: {
                value: 40,
                message: 'Name less than 40 characters',
              },
              onBlur: (e) => onBlur(e, 'family_name'),
            })}
          ></Input>
        </InputField>
        <InputField label="Email" field="email" isRequired={true} errors={errors}>
          <Input
            placeholder="example@email.com"
            {...register('email', {
              required: 'Please enter Email for confirmation emails at this prescriber',
              validate: (email: string) => {
                if (email.length > 50) {
                  return 'The maximum length of an email is 50'
                }
                return (
                  isValidEmailFormat(email) ||
                  'Please enter email with the correct format, such as example@email.com'
                )
              },
            })}
          ></Input>
        </InputField>
        {/* Phone Number */}
        <InputField label="Phone Number" field="phoneNumber" isRequired={true} errors={errors}>
          <Input
            placeholder="eg. 0411222333"
            isDisabled={isPhoneNumberForced}
            {...register('phoneNumber', {
              required: 'Please enter Phone for SMS confirmations at this prescriber',
              validate: (value?: string) => {
                if (!value) {
                  return true
                }
                return (
                  /^(0|\+61)[0-8]\d{8}$/g.test(value) ||
                  'Please provide a valid phone number (no space or bracket)'
                )
              },
            })}
          ></Input>
          {isPhoneNumberForced && (
            <Text fontSize="sm" color="blue.600" mt={1}>
              This phone number is set by your organization and cannot be changed.
            </Text>
          )}
        </InputField>
        <InputField
          label="Other Phone Number"
          field="otherPhoneNumber"
          errors={errors}
          useTooltipIcon
          tooltipContent={TOOLTIP_TEXT.OTHER_PHONE_NUMBER}
        >
          <Input
            placeholder="eg. 0411222333"
            isDisabled={isPhoneNumberForced}
            {...register('otherPhoneNumber', {
              validate: (value?: string) => {
                if (!value) {
                  return true
                }
                return (
                  /^(0|\+61)[0-8]\d{8}$/g.test(value) ||
                  'Please provide a valid phone number (no space or bracket)'
                )
              },
            })}
          />
          {isPhoneNumberForced && (
            <Text fontSize="sm" color="blue.600" mt={1}>
              This phone number is set by your organization and cannot be changed.
            </Text>
          )}
        </InputField>
        <InputField
          label="PBS Prescriber Number"
          field="prescriberNumber"
          isRequired={true}
          errors={errors}
          useTooltipIcon={true}
          tooltipContent={TOOLTIP_TEXT.PBS_PRESCRIBER_NUMBER}
        >
          <Input
            placeholder=""
            {...register('prescriberNumber', {
              required: 'Please enter a valid prescriber number',
              validate: (prescriberNumber: string) => {
                return (
                  isValidPrecriberNumberFormat(prescriberNumber) ||
                  'Please enter a valid prescriber number'
                )
              },
            })}
          />
        </InputField>
        <InputField
          label="Prescriber Type"
          field="prescriberType"
          isRequired={true}
          errors={errors}
        >
          <Select
            {...register('prescriberType', {
              validate: (prescriberType: string) => {
                if (!prescriberType) {
                  return 'Please select a prescriber type'
                }

                return (
                  isValidPrescriberType(String(prescriberType)) ||
                  'Please select a valid prescriber type'
                )
              },
            })}
          >
            {PRESCRIBER_OPRIONS.map(({ label, value }) => (
              <option value={value} key={label}>
                {label}
              </option>
            ))}
          </Select>
        </InputField>
        <InputField
          label="Healthcare Identifier - Individual (HPI-I)"
          field="hpii"
          isRequired={true}
          errors={errors}
          useTooltipIcon={true}
          tooltipContent={TOOLTIP_TEXT.HPI_I}
        >
          <Input
            placeholder="800361xxxxxxxxxx"
            {...register('hpii', {
              required: 'Please enter a HPI-I at this prescriber',
              setValueAs: trimValue,
              validate: (hpii: number | string) => {
                hpii = String(hpii)
                if (hpii?.length === 0) return true
                return (
                  (hpii.startsWith('800361') && luhn_validate(hpii, 16)) ||
                  'Please Enter a valid HPI-I Number'
                )
              },
              onBlur: (e) => onBlur(e, 'hpii'),
            })}
          />
        </InputField>
        <InputField label="Qualifications" field="qualifications" isRequired={true} errors={errors}>
          <Input
            placeholder="Please use commas to separate your qualification. e.g MBBS, DNB, FANZCA, FFPMANZCA, PG Dipl Clinical Research"
            {...register('qualifications', {
              maxLength: { value: 50, message: 'Qualifications must be less than 50 Characters' },
              required:
                'Please enter the qualifications at this prescriber and use commas to separate your qualification',
            })}
          />
        </InputField>
        <InputField
          label="eRx entity ID"
          field="entityId"
          errors={errors}
          useTooltipIcon={true}
          tooltipContent={TOOLTIP_TEXT.ENTITY_ID}
          isRequired={true}
        >
          <Input
            placeholder="Entity ID"
            {...register('entityId', {
              setValueAs: trimValue,
              validate: (entityId?: string) => {
                if (!entityId) {
                  return 'Entity ID is a required field'
                }
                const entityIDStr = String(entityId)
                return /^[0-9A-Z]{5}$/.test(entityIDStr.trim()) || 'Please select a valid entity ID'
              },
              required: 'Entity ID is a required field',
            })}
          />
        </InputField>

        <InputField label="HPI-I Status" field="hpiiStatus" isRequired={true} errors={errors}>
          <HStack gap={2}>
            <Alert status={HPIICheckingStatus ? 'success' : 'warning'}>
              <AlertIcon />
              <Text>{mapStatusToText[getValues().hpiiStatus] ?? 'Invalid'}</Text>
            </Alert>
            <Button onClick={validateHpii} disabled={checkHpiiLoading || invalidHpii} height="100%">
              Validate
            </Button>
          </HStack>
        </InputField>
        {isEnableRewards && (
          <InputField
            label="Referral Code"
            field="referralCodeOfReferrer"
            useTooltipIcon={true}
            tooltipContent={TOOLTIP_TEXT.REFERRAL_CODE}
            isRequired={false}
            errors={errors}
          >
            <HStack gap={2}>
              <Input
                {...register('referralCodeOfReferrer', {
                  setValueAs: trimValue,
                  validate: (referrerCode?: string) => {
                    if (validReferrerCode && validReferrerCode === referrerCode) {
                      return true
                    }
                    setReferrerInfo(null)
                    setValidReferrerCode(null)
                    if (!referrerCode) {
                      return true
                    }
                    return 'Please validate referral code before you submit'
                  },
                })}
              />
              <Button
                onClick={validateReferrer}
                isLoading={checkReferralCodeLoading}
                disabled={checkReferralCodeLoading}
                height="100%"
              >
                Validate
              </Button>
            </HStack>
            {referrerInfo && (
              <HStack>
                <Text>
                  Referrer: {referrerInfo?.givenNames} {referrerInfo?.familyName}
                </Text>
                <CheckCircleIcon color={'green.500'} />
              </HStack>
            )}
          </InputField>
        )}
      </SimpleGrid>
      {createPrescriberStatus === 'FAILURE' && (
        <Text color="red">
          Create prescriber failed, please check if this prescriber already exists.
        </Text>
      )}
      <Button
        style={{ marginTop: 'auto' }}
        width="200px"
        colorScheme="blue"
        onClick={ssoClient ? onSubmit : onNext}
        disabled={disabledNext || Object.keys(errors).length > 0}
      >
        {ssoClient ? 'Submit' : 'Next'}
      </Button>
    </>
  )
}

export default ProfileCreationForm
