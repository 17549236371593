enum SSO_CLIENT {
  TELECARE = 'TELECARE',
  HDA = 'HDA',
}

export const ssoClientConfigMappings: {
  clientName: SSO_CLIENT
  identityProvider?: string
  clientId?: string
  orgId?: string
  clinicId?: string
  forcePhoneNumber?: string
}[] = [
  {
    clientName: SSO_CLIENT.TELECARE,
    identityProvider: process.env.REACT_APP_TELECARE_COGNITO_IDENTITY_PROVIDER,
    clientId: process.env.REACT_APP_TELECARE_COGNITO_CLIENT_ID,
    orgId: process.env.REACT_APP_TELECARE_ORG_ID,
    clinicId: process.env.REACT_APP_TELECARE_CLINIC_ID,
    forcePhoneNumber: process.env.REACT_APP_HDA_PHONE_NUMBER,
  },
  {
    clientName: SSO_CLIENT.HDA,
    identityProvider: process.env.REACT_APP_HDA_COGNITO_IDENTITY_PROVIDER,
    clientId: process.env.REACT_APP_HDA_COGNITO_CLIENT_ID,
    orgId: process.env.REACT_APP_HDA_ORG_ID,
    clinicId: process.env.REACT_APP_HDA_CLINIC_ID,
    forcePhoneNumber: process.env.REACT_APP_HDA_PHONE_NUMBER,
  },
]
