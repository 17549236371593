import { Input, SimpleGrid, VStack } from '@chakra-ui/react'
import InputField from '../../../components/InputField'
import { isValidEmailFormat } from '../../../utils/validation-helpers/emailFormatValidate'
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form'
import { OrganizationAdminFormData } from '../organizationAdminSlice'

interface PrescriberFormProps {
  register: UseFormRegister<OrganizationAdminFormData>
  errors: FieldErrorsImpl<OrganizationAdminFormData>
}

export const OrganizationAdminForm = ({ register, errors }: PrescriberFormProps) => {
  return (
    <VStack
      overflow="auto"
      p="24px"
      pb="9"
      minHeight="100%"
      height={'100%'}
      minWidth={'60%'}
      spacing="12"
    >
      <SimpleGrid
        columns={[1, 2]}
        rowGap={6}
        columnGap={20}
        width={'100%'}
        style={{ marginBottom: '36px' }}
      >
        <InputField label="First Name" field="givenName" isRequired={true} errors={errors}>
          <Input
            placeholder="First Name"
            {...register('givenName', {
              required: 'Required',
              maxLength: {
                value: 40,
                message: 'First name must be less than 40 Characters',
              },
            })}
          />
        </InputField>
        <InputField label="Family Name" field="familyName" isRequired={true} errors={errors}>
          <Input
            placeholder="Family Name"
            {...register('familyName', {
              required: 'Required',
              maxLength: {
                value: 40,
                message: 'Family name must be less than 40 Characters',
              },
            })}
          ></Input>
        </InputField>
        <InputField label="Email" field="email" isRequired={true} errors={errors}>
          <Input
            placeholder="example@email.com"
            {...register('email', {
              required: 'Please enter Email for confirmation emails at this prescriber',
              validate: (email: string) => {
                if (email.length > 50) {
                  return 'The maximum length of an email is 50'
                }
                return (
                  isValidEmailFormat(email) ||
                  'Please enter email with the correct format, such as example@email.com'
                )
              },
            })}
          ></Input>
        </InputField>
      </SimpleGrid>
    </VStack>
  )
}
