import React, { useMemo, useState, useRef } from 'react'
import { Button, Menu, MenuButton, MenuList, Text, HStack, Box } from '@chakra-ui/react'
import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { OrganizationUser } from '../features/users/UsersSlice'
import { VariableSizeList } from 'react-window'

interface PrescriberSelectorProps {
  users: OrganizationUser[]
  selectedPrescriberId?: string
  onSelect: (prescriberId: string | undefined) => void
}

export const PrescriberSelector: React.FC<PrescriberSelectorProps> = ({
  users,
  selectedPrescriberId,
  onSelect,
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const listRef = useRef<VariableSizeList>(null)
  const rowHeights = useRef<{ [key: number]: number }>({})

  const sortedPrescribers = useMemo(() => {
    return users
      .filter((user) => user.prescriber)
      .sort((a, b) => {
        if (a.prescriber?.active !== b.prescriber?.active) {
          return a.prescriber?.active ? -1 : 1
        }

        const nameA = `${a.firstName} ${a.lastName}`.toLowerCase()
        const nameB = `${b.firstName} ${b.lastName}`.toLowerCase()

        return nameA.localeCompare(nameB)
      })
  }, [users])

  const selectedPrescriber = sortedPrescribers.find(
    (u) => u.prescriber?.id === selectedPrescriberId
  )

  // Function to get row height
  const getRowHeight = (index: number) => {
    return rowHeights.current[index] || 40
  }

  // Function to set row height
  const setRowHeight = (index: number, size: number) => {
    rowHeights.current[index] = size
    if (listRef.current) {
      listRef.current.resetAfterIndex(index)
    }
  }

  // Row renderer for virtualized list
  const PrescriberRow = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    const rowRef = useRef<HTMLDivElement>(null)
    const user = sortedPrescribers[index]
    const isActive = user.prescriber?.active
    const isSelected = user.prescriber?.id === selectedPrescriberId
    const entityId = user.prescriber?.entityId

    React.useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight)
      }
    }, [index])

    return (
      <Box
        ref={rowRef}
        style={{
          ...style,
          height: 'auto',
        }}
        onClick={() => {
          onSelect(user.prescriber?.id || '')
          setMenuOpen(false)
        }}
        cursor="pointer"
        px={3}
        py={2}
        opacity={isActive ? 1 : 0.6}
        bg={isSelected ? 'blue.50' : undefined}
        _hover={{ bg: isSelected ? 'blue.100' : 'gray.100' }}
      >
        <HStack justify="space-between" width="100%" flexWrap="wrap">
          <HStack flexWrap="wrap">
            <Text wordBreak="break-word">{`${user.firstName} ${user.lastName}`}</Text>
            {entityId && (
              <Text fontSize="sm" color="gray.500">
                ({entityId})
              </Text>
            )}
          </HStack>
          <HStack spacing={2}>
            {!isActive && (
              <Text fontSize="sm" color="gray.500">
                (Inactive)
              </Text>
            )}
            {isSelected && <CheckIcon color="blue.500" />}
          </HStack>
        </HStack>
      </Box>
    )
  }

  return (
    <Menu
      placement="bottom-end"
      strategy="fixed"
      isOpen={menuOpen}
      onOpen={() => setMenuOpen(true)}
      onClose={() => setMenuOpen(false)}
      offset={[0, 4]}
      gutter={0}
      modifiers={[
        {
          name: 'preventOverflow',
          options: {
            padding: 8,
          },
        },
        {
          name: 'flip',
          options: {
            padding: 8,
          },
        },
        {
          name: 'sameWidth',
          enabled: true,
          fn: ({ state }) => {
            state.styles.popper.width = `${state.rects.reference.width}px`
            return state
          },
          phase: 'beforeWrite',
          requires: ['computeStyles'],
        },
      ]}
    >
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="outline">
        <HStack>
          <Text>
            {selectedPrescriber
              ? `${selectedPrescriber.firstName} ${selectedPrescriber.lastName}${
                  selectedPrescriber.prescriber?.entityId
                    ? ` (${selectedPrescriber.prescriber.entityId})`
                    : ''
                }`
              : 'Filter by Prescriber'}
          </Text>
        </HStack>
      </MenuButton>
      <MenuList maxH="400px" p={0} overflow="hidden">
        {menuOpen && (
          <VariableSizeList
            ref={listRef}
            height={Math.min(400, sortedPrescribers.length * 40)}
            width="100%"
            itemCount={sortedPrescribers.length}
            itemSize={getRowHeight}
            overscanCount={5}
          >
            {PrescriberRow}
          </VariableSizeList>
        )}
      </MenuList>
    </Menu>
  )
}
