import React, { Fragment, useEffect } from 'react'
import { Divider, Text, VStack } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../app/typedReduxHooks'
import { RootState } from '../app/store'
import { BillingClient } from '../pages/Billing/BillingSlice'
import { titleMsPrescribersId } from '../pages/NewScriptPage/SendScriptPanel/PaperPrescription'

export const UserSummaryPanel: React.FC<unknown> = () => {
  const dispatch = useAppDispatch()

  const prescriber = useAppSelector((state) => state.prescriber.prescriber)
  const admin = useAppSelector((state) => state.organizationAdmin.profile)
  const subscription = useAppSelector((state: RootState) => state.billing?.subscription.data)
  const clinic = useAppSelector((state) => state.clinic.currentClinic)
  const isUnlimitedToken = subscription?.tokens && subscription?.tokens > 10_000
  const isCustomPlan = !subscription?.stripeSubscriptionId

  const organizationId = useAppSelector((state: RootState) => state.organization?.data?.id)
  useEffect(() => {
    if (!subscription && organizationId && prescriber) {
      dispatch(BillingClient.getSubscription({ prescriberId: prescriber?.id, organizationId }))
    }
  }, [organizationId, dispatch])

  const prescriberTitle = titleMsPrescribersId.includes(prescriber?.id ?? '') ? 'Ms' : 'Dr'

  return (prescriber || admin) && clinic ? (
    <VStack width="260px" spacing={2} alignItems="stretch">
      <Text color="white" paddingStart="16px">
        {prescriber?.active
          ? `${prescriberTitle} ${prescriber.givenNames} ${prescriber.familyName}`
          : `${admin?.givenName} ${admin?.familyName}`}
      </Text>

      <Text color="white" paddingStart="16px">
        Clinic: {clinic.name}
      </Text>
      {prescriber?.active && (
        <Fragment>
          <Text color="white" paddingStart="16px">
            Prescriber No: {prescriber.prescriberNumber}
          </Text>
          {subscription && (
            <Fragment>
              <Text color="white" paddingStart="16px">
                Subscription:{' '}
                {isCustomPlan
                  ? `Custom(${isUnlimitedToken ? 'Unlimited' : 'Standard'})`
                  : isUnlimitedToken
                  ? 'Unlimited Plan'
                  : 'Standard Plan'}
              </Text>
              <Text color="white" paddingStart="16px">
                Remaining Tokens: {isUnlimitedToken ? 'Unlimited' : subscription?.remainingTokens}
              </Text>
            </Fragment>
          )}

          {prescriber.paperPrescriptionOnly && (
            <>
              <Divider />
              <Text fontSize="sm" color="red" paddingStart="16px">
                PAPER PRESCRIPTION ONLY
              </Text>
            </>
          )}
        </Fragment>
      )}
    </VStack>
  ) : null
}
