import { AdminRole } from '../type/AdminRole'
import { Permission } from '../../../types/permission'

const adminRolePermissionMapping = {
  ['patient_admin']: [Permission.ORGANIZATION_MANAGEMENT, Permission.PATIENT_MANAGEMENT],
  ['rx_admin']: [
    Permission.ORGANIZATION_MANAGEMENT,
    Permission.PRESCRIPTION_MANAGEMENT,
    Permission.PATIENT_MANAGEMENT,
  ],
}

export const convertRoleToPermissions = (role: AdminRole): Permission[] => {
  if (!role) {
    return []
  }
  return adminRolePermissionMapping?.[role] ?? []
}
