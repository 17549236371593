import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react'
import { Fragment, FunctionComponent, useState } from 'react'
import NewPatientModal from './NewPatientModal'
import UpdatePatientModal from './UpdatePatientModal'

import { Patient } from '../../types'
import PatientTable from './PatientTable'
import { ImportPatientsModal } from '../../features/patient/components/ImportPatientModal'
import { RootState } from '../../app/store'
import { useAppSelector } from '../../app/typedReduxHooks'
import { PrescriberSelector } from '../../components/PrescriberSelector'
import { LimitedPrescriberProfile } from '../../features/users/UsersSlice'
import UnauthorizedPage from '../../components/UnauthorizedPage'
import { Permission } from '../../types/permission'

const PatientPage: FunctionComponent<unknown> = () => {
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null)
  const prescriber = useAppSelector(({ prescriber }: RootState) => prescriber?.prescriber)
  const prescribers = useAppSelector(
    (state: RootState) => state.users?.data?.filter((user) => user.prescriber) || []
  )
  const isPatientAdmin = useAppSelector((state: RootState) =>
    state.authentication.permissions?.includes(Permission.PATIENT_MANAGEMENT)
  )
  const [selectedPrescriber, setSelectedPrescriber] = useState<LimitedPrescriberProfile | null>(
    (prescriber?.active || !isPatientAdmin
      ? (prescriber as LimitedPrescriberProfile)
      : prescribers.find((u) => u.prescriber?.active)?.prescriber) ?? null
  )

  const organizationSettings = useAppSelector((state) => state.organization?.data?.settings)
  const isAbleToManagePatient = !!organizationSettings?.enableManagePatient
  const isSelectedPrescriberInactive = !selectedPrescriber?.active

  if (!isPatientAdmin && !prescriber) {
    return <UnauthorizedPage />
  }

  return (
    <Box minWidth={0} width={'100%'} height={['100%', '100vh']}>
      <VStack p="12px" alignItems="stretch" spacing={6} height="100%">
        <HStack spacing="4" justify="space-between">
          <HStack spacing="4">
            <Text as="h1" fontSize="3xl" fontWeight="bold">
              Patients
            </Text>
            {isAbleToManagePatient && (
              <Fragment>
                <NewPatientModal
                  selectedPrescriber={selectedPrescriber}
                  onPrescriberChange={(id) => {
                    const prescriber =
                      prescribers.find((u) => u.prescriber?.id === id)?.prescriber || null
                    setSelectedPrescriber(prescriber)
                  }}
                  hidePrescriberSelection={!isPatientAdmin}
                >
                  {({ openModal }) => (
                    <Button
                      colorScheme="blue"
                      onClick={() => openModal()}
                      disabled={!isPatientAdmin && isSelectedPrescriberInactive}
                    >
                      + New Patient
                    </Button>
                  )}
                </NewPatientModal>
                <ImportPatientsModal
                  selectedPrescriber={selectedPrescriber}
                  onPrescriberChange={(id) =>
                    setSelectedPrescriber(
                      prescribers.find((u) => u.prescriber?.id === id)?.prescriber ?? null
                    )
                  }
                  hidePrescriberSelection={!isPatientAdmin}
                >
                  {({ openModal }) => (
                    <Button
                      colorScheme="blue"
                      onClick={() => openModal()}
                      disabled={!isPatientAdmin && isSelectedPrescriberInactive}
                    >
                      Import Patients
                    </Button>
                  )}
                </ImportPatientsModal>
              </Fragment>
            )}
          </HStack>
          {isPatientAdmin && (
            <HStack pr={10} spacing={4}>
              <Text as={'b'} fontSize={'xl'}>
                Selected prescriber:
              </Text>
              <PrescriberSelector
                users={prescribers}
                selectedPrescriberId={selectedPrescriber?.id}
                onSelect={(id) =>
                  setSelectedPrescriber(
                    prescribers.find((u) => u.prescriber?.id === id)?.prescriber ?? null
                  )
                }
              />
            </HStack>
          )}
        </HStack>

        {prescribers.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
            width="100%"
            borderWidth="1px"
            borderRadius="lg"
            p={4}
          >
            <Text fontSize="lg" color="gray.500">
              No prescribers available in this organization.
            </Text>
          </Box>
        ) : (
          <PatientTable
            setSelectedPatient={setSelectedPatient}
            isAbleToManagePatient={
              isAbleToManagePatient && (isPatientAdmin || !isSelectedPrescriberInactive)
            }
            selectedPrescriber={selectedPrescriber}
          />
        )}
      </VStack>

      {selectedPatient && (
        <UpdatePatientModal
          patient={selectedPatient}
          isOpen={true}
          onClose={() => setSelectedPatient(null)}
          selectedPrescriber={selectedPrescriber}
          isAbleToCreateOrDelete={!isSelectedPrescriberInactive}
        />
      )}
    </Box>
  )
}

export default PatientPage
