import ApiClient from '../../utils/api/ApiClient'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AdminRole } from './type/AdminRole'
import { Permission } from '../../types/permission'

export interface LimitedPrescriberProfile {
  id: string
  entityId?: string
  hpii?: string
  active: boolean
}

interface LimitedAdminProfile {
  id: string
  permissions: Permission[]
  active: boolean
  isOwner: boolean
}

export interface OrganizationUser {
  userId: string
  firstName: string
  lastName: string
  email: string
  admin: LimitedAdminProfile | null
  prescriber: LimitedPrescriberProfile | null
}

interface OrganizationUsersState {
  data: OrganizationUser[] | null
}

const initialState: OrganizationUsersState = {
  data: null,
}

export const OrganizationUsersClient = {
  getAllUsersInOrg: async ({
    organizationId,
  }: {
    organizationId: string
  }): Promise<OrganizationUser[]> => {
    const response = await ApiClient.get<{
      organizationId: string
      users: OrganizationUser[]
    }>(`/bff/organizations/${organizationId}/users`)
    return response?.data?.users
  },
  updateUserRoles: async ({
    organizationId,
  }: {
    organizationId: string
    roles: AdminRole
    isPrescriber: boolean
  }): Promise<OrganizationUser[]> => {
    const response = await ApiClient.get<{
      organizationId: string
      users: OrganizationUser[]
    }>(`/bff/organization-admin/${organizationId}/users`)
    return response?.data?.users
  },
}

const organizationUsersSlice = createSlice({
  name: 'organizationUsers',
  initialState,
  reducers: {
    setOrganizationUsers: (
      state,
      { payload: organizationUsers }: PayloadAction<OrganizationUser[]>
    ) => {
      state.data = organizationUsers
    },
  },
})

export const { setOrganizationUsers } = organizationUsersSlice.actions

export default organizationUsersSlice.reducer
