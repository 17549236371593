import { RootState } from '../../../../../app/store'
import { PrescribedItem } from '../../../../../types'
import { ProductDetails } from '../../ProductSearchContextSlice'
import { transformPbsData } from '../pbsDataTransformer'
import { CustomPrescriptionItem } from '../../../CustomPrescriptionItem/CustomPrescriptionItem'
import { getProductDisplayName } from './getProductDisplayName'

export const customPrescriptionItemToPrescribedItem = (
  prescriptionItem: CustomPrescriptionItem,
  overrides: Partial<PrescribedItem> = {}
): PrescribedItem => {
  const hasPBSCode = !!prescriptionItem.pbsCode
  const pbsData = transformPbsData({
    pbsCode: prescriptionItem.pbsCode || '',
    pbsType: 'Private',
    prescriberTypes: [],
    restrictions: [],
    repeats: prescriptionItem.repeats || 0,
    maxQtyPacks: prescriptionItem.quantity || 0,
    maxQtyUnits: prescriptionItem.quantity || 0,
    pbsManufacturerCodes: [],
  })

  return {
    productId: prescriptionItem.id,
    tradeName: prescriptionItem.compoundName,
    genericName: prescriptionItem.compoundName,
    displayName: prescriptionItem.compoundName,
    form: prescriptionItem.dosageForm,
    strength: prescriptionItem.itemStrength,
    genericIntention: 'G',
    brandRequired: false,
    brandSubstitutionNotAllowed: false,
    ...pbsData,
    unusualQuantityFlag: false,
    routeAdministration: prescriptionItem.routeOfAdministration,
    patientInstructions: '',
    regulation24: false,
    emergencySupply: false,
    isExtemp: true,
    extemporaneousDescription: prescriptionItem.compoundName,
    unlistedItemRepatAuthority: false,
    privatePrescription: !hasPBSCode,
    prescriptionDate: new Date().toISOString(),
    unusualDoseFlag: false,
    prescriberScriptNumber: '',
    privacyStatement: true,
    noMySL: false,
    sendToPharmacy: '',
    // TODO: Check if we need these two fields
    ctgAnnotation: '',
    isPBS: hasPBSCode,
    isRPBS: false,
    state: 'Active',
    ...overrides,
    customPrescriptionPBSCode: prescriptionItem.pbsCode || '',
  }
}

export const detailsToPrescribedItem = (
  product: ProductDetails,
  overrides: Partial<PrescribedItem> = {}
): PrescribedItem => {
  // const pbsConcise = product?.pbs?.[0]
  // const pbsData = transformPbsData(pbsConcise)

  return {
    productId: product.productId,
    tradeName: product.productName,
    genericName: product.vis.map((vi) => vi.viName).join(' + '),
    displayName: getProductDisplayName({ product, brandRequired: false }),
    form:
      product.productPacks?.[0].productPackItems[0].dosageForm || product.vis[0].dosageForm?.[0],
    strength:
      (product.vis[0].molecules &&
        product.vis[0].molecules
          .map(
            (molecule) =>
              `${molecule.moleculeStrength}${molecule.moleculeStrengthUnit || ''}${
                molecule.moleculePerVol
                  ? '/' + molecule.moleculePerVol + molecule.moleculePerVolUnit
                  : ''
              }`
          )
          .join('/')) ||
      '',
    genericIntention: product?.aip?.lemi ? 'B' : 'G',
    brandRequired: false,
    brandSubstitutionNotAllowed: false,
    // ...pbsData,
    pbsCode: '',
    pbsManufacturerCode: '',
    quantity: 1,
    numberOfRepeatsAuthorised: 0,
    maximumQuantity: 'NA',
    maximumRepeats: 'NA',
    phoneApprovalAuthorityNumber: '',
    // End PBS Data
    unusualQuantityFlag: false,
    routeAdministration: product?.brand
      ? product.roas?.[0] || ''
      : product.vis[0].roa
      ? product.vis[0].roa[0]
      : '',
    patientInstructions: '',
    regulation24: false,
    emergencySupply: false,
    isExtemp: false,
    extemporaneousDescription: '',
    unlistedItemRepatAuthority: false,
    privatePrescription: true,
    prescriptionDate: new Date().toISOString(),
    unusualDoseFlag: false,
    prescriberScriptNumber: '',
    reasonForPrescribing: '',
    privacyStatement: true,
    noMySL: false,
    sendToPharmacy: '',
    // TODO: Check if we need these two fields
    ctgAnnotation: '',
    productDetails: product,
    isPBS: false,
    isRPBS: false,
    state: 'Active',
    ...overrides,
  }
}

export const selectedPrescribedItemSelector = (state: RootState): PrescribedItem | undefined => {
  const { prescribedItemList, selectedPrescribedItemIndex } = state.newScript

  return (
    (typeof selectedPrescribedItemIndex === 'number' &&
      prescribedItemList[selectedPrescribedItemIndex]) ||
    undefined
  )
}
