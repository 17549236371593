import { Permission } from '../../../types/permission'

export enum InvitationStatus {
  PENDING = 'pending',
  PENDING_OWNER_APPROVAL = 'pendingOwnerApproval',
  ACCEPTED = 'accepted',
  EXPIRED = 'expired',
  REVOKED = 'revoked',
  OWNER_REJECTED = 'ownerRejected',
}

export interface UserInvitation {
  token: string
  email: string
  inviterUserId: string
  inviterName: string
  inviterEmail: string
  status: InvitationStatus
  expirationTime: number
  organizationId: string
  userId?: string
  isPrescriber: boolean
  isOwnerInvitation: boolean
  needsOwnerApproval: boolean
  permissions: Permission[]
}
