import { Button, Center, Heading, VStack } from '@chakra-ui/react'
import { Logo } from '../../components/shared-components/Logo'
import { OrganizationAdminForm } from './components/OrganizationAdminForm'
import { useAppDispatch } from '../../app/typedReduxHooks'
import { useNavigate } from 'react-router-dom'
import {
  OrganizationAdminFormData,
  setOrganizationAdminCreationPayload,
} from './organizationAdminSlice'
import { routes } from '../../pages/routes'
import { setPrescriberCreationPayload } from '../../pages/PrescriberProfileCreationPage/PrescriberCreationSlice'
import { useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { ssoClientConfigMappings } from '../sso/constant'
import { useEffect } from 'react'

const OrganizationAdminCreationPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const clientId = localStorage.getItem('client_id') || ''
  const ssoClient = ssoClientConfigMappings.find((client) => client.clientId === clientId)
  useEffect(() => {
    if (ssoClient) {
      navigate(routes.createPrescriberProfile.path)
    }
  }, [ssoClient])
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<OrganizationAdminFormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {},
  })
  const onSubmit = async (data: OrganizationAdminFormData) => {
    dispatch(setPrescriberCreationPayload(null))
    dispatch(setOrganizationAdminCreationPayload(data))
    navigate(routes.linkOrganization.path)
  }
  return (
    <VStack overflow="auto" p="24px" pb="9" minHeight="100%" spacing="12">
      <Center>
        <Logo maxWidth={100} />
      </Center>
      <Heading as="h2" textAlign="center" size="lg">
        Create Your Admin Profile
      </Heading>
      <VStack as="form" onSubmit={handleSubmit(onSubmit)}>
        <OrganizationAdminForm
          {...{
            register,
            errors,
          }}
        />
        <Button width="200px" colorScheme="blue" type="submit" disabled={!isEmpty(errors)}>
          Next
        </Button>
      </VStack>
    </VStack>
  )
}

export default OrganizationAdminCreationPage
