import { Text, VStack } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { routes } from '../../routes'

export const SubscriptionExpiredNotificationContent = () => {
  return (
    <VStack alignItems={'stretch'}>
      <Text>Your subscription has expired.</Text>
      <Text paddingBottom={8}>
        To start sending prescriptions, please contact your organisation administrator. Click{' '}
        <Link to={`${routes.settings.path}?tab=users`} style={{ color: 'dodgerblue' }}>
          here
        </Link>{' '}
        to check organisation users.
      </Text>
    </VStack>
  )
}
