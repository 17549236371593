import { Box, HStack, Text, useDisclosure, VStack } from '@chakra-ui/react'
import { Fragment, useEffect, useState } from 'react'
import Loading from '../../components/shared-components/Loading'
import { Prescription } from '../../types'
import { CeasePrescriptionModal } from './components/CeasePrescriptionModal'
import { ReIssuePrescriptionModal } from './components/ReIssuePrescriptionModal'
import { useParams } from 'react-router-dom'
import CancelPrescriptionModal from './components/CancelPrescriptionModal'
import { useGetAllPrescriptionsQuery } from '../../services/prescriptions'
import RCPagination from '../../components/RCPagination'
import SearchInput from '../../components/form/SearchInput'
import PrescriptionTable from './components/PrescriptionTable'
import { useAppSelector } from '../../app/typedReduxHooks'
import { RootState } from '../../app/store'
import { LimitedPrescriberProfile } from '../../features/users/UsersSlice'
import { PrescriberSelector } from '../../components/PrescriberSelector'
import UnauthorizedPage from '../../components/UnauthorizedPage'
import { Permission } from '../../types/permission'

const PrescriptionsPage = () => {
  const { patientId, prescriberId } = useParams()
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const prescriber = useAppSelector(({ prescriber }: RootState) => prescriber?.prescriber)
  const prescribers = useAppSelector(
    (state: RootState) => state.users?.data?.filter((user) => user.prescriber) || []
  )

  const [selectedPrescriber, setSelectedPrescriber] = useState<LimitedPrescriberProfile | null>(
    null
  )

  useEffect(() => {
    if (prescribers.length > 0) {
      if (prescriberId) {
        const prescriberFromUrl = prescribers.find(
          (u) => u.prescriber?.id === prescriberId
        )?.prescriber
        if (prescriberFromUrl) {
          setSelectedPrescriber(prescriberFromUrl)
          return
        }
      }

      if (!selectedPrescriber) {
        setSelectedPrescriber(
          (prescriber
            ? (prescriber as LimitedPrescriberProfile)
            : prescribers.find((u) => u.prescriber?.active)?.prescriber) ?? null
        )
      }
    }
  }, [prescribers, prescriberId, prescriber, selectedPrescriber])
  const permissions = useAppSelector((state) => state.authentication.permissions)
  const isPrescriptionAdmin = permissions?.includes(Permission.PRESCRIPTION_MANAGEMENT)
  const isDeactivatedPrescriber = !selectedPrescriber?.active

  const [reIssueModalIsOpen, setReIssueModalIsOpen] = useState<boolean>(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false)
  const [selectedPrescription, setSelectedPrescription] = useState<Prescription | undefined>(
    undefined
  )
  const [selectedScid, setSelectedScid] = useState<string>('')
  const { isOpen: isCeaseOpen, onOpen: onCeaseOpen, onClose: onCeaseClose } = useDisclosure()
  const { isLoading, data: prescriptionsResponse } = useGetAllPrescriptionsQuery({
    patientId,
    prescriberId: selectedPrescriber?.id || prescriber?.id,
    page: String(page),
    limit: '10',
    search: searchTerm,
  })
  const { data: prescriptions, meta: prescriptionsPaginationData } = prescriptionsResponse || {}

  const openCeaseModal = (index: number) => {
    const scid = prescriptions?.[index]?.scid
    if (scid) {
      setSelectedScid(scid)
      onCeaseOpen()
    }
  }

  const openCancelModal = (index: number) => {
    const prescription = prescriptions?.[index]
    prescription && setSelectedPrescription(prescription)
    setIsCancelModalOpen(true)
  }

  const openReIssueModal = (index: number) => {
    const prescription = prescriptions?.[index]
    prescription && setSelectedPrescription(prescription)
    setReIssueModalIsOpen(true)
  }

  if (!isPrescriptionAdmin && !prescriber) {
    return <UnauthorizedPage />
  }

  return (
    <Box width="100%" height="100vh" display="flex" flexDirection="column" overflow="hidden">
      {isLoading && <Loading />}
      <VStack px={4} spacing={4} align="stretch" height="100%" width="100%" overflow="hidden">
        <HStack spacing="4" justify="space-between">
          <Text as="h1" fontSize="3xl" fontWeight="bold">
            Prescriptions
          </Text>
          {isPrescriptionAdmin && !prescriberId && (
            <HStack pr={10} spacing={4} zIndex={2}>
              <Text as={'b'} fontSize={'xl'}>
                Selected prescriber:
              </Text>
              <PrescriberSelector
                users={prescribers}
                selectedPrescriberId={selectedPrescriber?.id}
                onSelect={(id) =>
                  setSelectedPrescriber(
                    prescribers.find((u) => u.prescriber?.id === id)?.prescriber ?? null
                  )
                }
              />
            </HStack>
          )}
        </HStack>
        <SearchInput
          w={['80%', '20%']}
          alignSelf="flex-start"
          onSearch={(value: string) => {
            const formattedValue = value.replace(/\s/g, '')
            setSearchTerm(formattedValue)
            setPage(1)
          }}
          placeholder="by patient name or scid"
        />

        {prescribers.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
            width="100%"
            borderWidth="1px"
            borderRadius="lg"
            p={4}
          >
            <Text fontSize="lg" color="gray.500">
              No prescribers available in this organisation.
            </Text>
          </Box>
        ) : prescriptions ? (
          <Fragment>
            <Box flex="1" minHeight={0} width="100%" overflow="hidden">
              <PrescriptionTable
                prescriptions={prescriptions}
                openCeaseModal={openCeaseModal}
                openReIssueModal={openReIssueModal}
                openCancelModal={openCancelModal}
                isDeactivatedPrescriber={isDeactivatedPrescriber}
                isReadonlyPermission={
                  !(prescriber?.active && selectedPrescriber?.id === prescriber?.id)
                }
              />
            </Box>

            <Box pt={2}>
              <RCPagination
                showLessItems
                current={page}
                total={prescriptionsPaginationData?.totalItems}
                pageSize={10}
                onChange={(currentPage) => setPage(currentPage)}
              />
            </Box>
          </Fragment>
        ) : null}
      </VStack>

      <CeasePrescriptionModal isOpen={isCeaseOpen} onClose={onCeaseClose} scid={selectedScid} />

      {selectedPrescription && (
        <>
          <CancelPrescriptionModal
            isOpen={isCancelModalOpen}
            onClose={() => setIsCancelModalOpen(false)}
            prescription={selectedPrescription}
          />

          <ReIssuePrescriptionModal
            isOpen={reIssueModalIsOpen}
            onClose={() => {
              setReIssueModalIsOpen(false)
              setSelectedPrescription(undefined)
            }}
            prescription={selectedPrescription}
          />
        </>
      )}
    </Box>
  )
}

export default PrescriptionsPage
