import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import { getPrescriptionViewDataForNewScript } from './PaperPrescription'
import { GeneralError, PrescribedItem } from '../../../types'
import { FC, useEffect, useState } from 'react'
import GoogleAnalyticsTagManager from '../../../utils/google-analytics/GoogleAnalyticsTagManager'
import { useGAContextData } from '../../../utils/google-analytics/useGAContextData'
import { rtpmPrecheck, RTPMResult } from './services/rtpmPrecheckService'
import { useAppSelector } from '../../../app/typedReduxHooks'
import { useFeatureToggle } from '../../../common/utils/feature-toggle/useFeatureToggle'
import { RTPMNotification } from './RTPMNotification'

interface DeliveryMethodProps {
  emergencySupply: boolean
  sendToPharmacy: boolean
  sendViaSMS: boolean
  printPaperToken: boolean
  sendViaEmail: boolean
  isPaperPrescription: boolean
}

const DeliveryMethod: FC<DeliveryMethodProps> = (props) => {
  const Content: FC<DeliveryMethodProps> = ({
    sendToPharmacy,
    emergencySupply,
    sendViaEmail,
    sendViaSMS,
    printPaperToken,
    isPaperPrescription,
  }) => {
    if (isPaperPrescription) {
      return <Text>Paper Prescription</Text>
    }
    if (sendToPharmacy || emergencySupply || printPaperToken) {
      return <Text>Paper Token</Text>
    }
    if (sendViaEmail) {
      return <Text>Email</Text>
    }
    if (sendViaSMS) {
      return <Text>SMS</Text>
    }
    return <Text>N/A</Text>
  }

  return (
    <Box>
      <Text fontWeight="bold" textTransform="uppercase">
        Script Delivery Method:
      </Text>
      <Content {...props} />
    </Box>
  )
}

interface ReviewPrescriptionModalProps {
  isOpen: boolean
  isPaperPrescription: boolean
  isIncludeS8: boolean
  onClose: () => void
  sendPrescription: () => void
}

const PreviewPrescriptionModal: FC<ReviewPrescriptionModalProps> = ({
  isOpen,
  onClose,
  sendPrescription,
  isPaperPrescription,
  isIncludeS8,
}) => {
  const data = getPrescriptionViewDataForNewScript()
  const prescriber = useAppSelector((state) => state.prescriber.prescriber)
  const organization = useAppSelector((state) => state.organization?.data)
  const organizationSettings = organization?.settings
  const { isFeatureEnabled: isRTPMEnabled } = useFeatureToggle('RTPM')
  const enableRTPMFeature =
    isRTPMEnabled &&
    organizationSettings?.enableRTPM &&
    (organizationSettings?.forceEnableRTPM || prescriber?.enableRtpm)
  const enableRTPMUIRestriction = enableRTPMFeature && organizationSettings?.enableRTPMUIRestriction
  const clinic = useAppSelector((state) => state.clinic.currentClinic)
  const GAContextData = useGAContextData()
  const drugs = useAppSelector((state) => state.newScript.prescribedItemList)
  const [rtpmResult, setRtpmResult] = useState<{ data?: RTPMResult; error?: GeneralError } | null>(
    null
  )
  const [rtpmCheckStatus, setRtpmCheckStatus] = useState<{
    checkRequired: boolean
    rtpmCheckLink: string
    dismissRequired: boolean
  } | null>(null)
  const selectedPatient = useAppSelector((state) => state.patientDetail.selectedPatient)
  useEffect(() => {
    if (isOpen && clinic && prescriber && selectedPatient && drugs) {
      const runRtpmPrecheck = async () => {
        if (!enableRTPMFeature) {
          return
        }
        const { data, error } = await rtpmPrecheck({
          patient: selectedPatient,
          clinic,
          prescriber,
          prescribedItems: drugs,
        })
        if (data) {
          setRtpmResult({
            data,
            error: undefined,
          })
          enableRTPMUIRestriction &&
            setRtpmCheckStatus({
              checkRequired: [1, 2].includes(data.code),
              rtpmCheckLink: data.contextualLinkUrl,
              dismissRequired: false,
            })
        } else {
          setRtpmResult({
            data: undefined,
            error,
          })
          enableRTPMUIRestriction &&
            setRtpmCheckStatus({
              checkRequired: false,
              rtpmCheckLink: '',
              dismissRequired: true,
            })
        }
      }
      runRtpmPrecheck()
    } else {
      setRtpmResult(null)
    }
  }, [isOpen])

  const onSend = () => {
    onClose()
    GoogleAnalyticsTagManager.sendCreatePrescriptionEvent(GAContextData)
    sendPrescription()
  }

  return (
    <Modal
      isOpen={isOpen}
      size={'xl'}
      onClose={onClose}
      blockScrollOnMount={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb={2}>Preview Prescription</ModalHeader>
        <ModalBody py={0}>
          <Card>
            <CardBody px={4} py={2}>
              {enableRTPMFeature && (
                <RTPMNotification
                  data={rtpmResult?.data}
                  error={rtpmResult?.error}
                  onCheck={() => {
                    setRtpmCheckStatus({
                      ...rtpmCheckStatus,
                      checkRequired: false,
                      dismissRequired: false,
                      rtpmCheckLink: '',
                    })
                  }}
                  onClose={() => {
                    if (rtpmCheckStatus?.dismissRequired) {
                      setRtpmCheckStatus({
                        ...rtpmCheckStatus,
                        dismissRequired: false,
                      })
                    }
                  }}
                />
              )}

              <Stack divider={<StackDivider />} spacing="4">
                <Box>
                  <Text fontWeight="bold" textTransform="uppercase">
                    Prescriber
                  </Text>
                  <Text>{`Prescriber: ${data.prescriberTitle} ${data.prescriberFirstname} ${data.prescriberLastname}`}</Text>
                  <Text>{`Address: ${data.clinicAddress}, ${data.clinicSuburb} ${data.clinicState} ${data.clinicPostcode}`}</Text>
                  <Text>{`Phone: ${data.prescriberPhoneNumber ?? ''}`}</Text>
                  <Text>{`Other Phone: ${data.prescriberOtherPhoneNumber ?? ''}`}</Text>
                  <Text>{`Provider No. ${data.prescriberProviderNumber ?? ''}`}</Text>
                  <Text>{`Prescriber No.: ${data.prescriberNumber ?? ''}`}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" textTransform="uppercase">
                    Patient
                  </Text>
                  <Text>{`Patient: ${data.patientFirstname} ${data.patientLastname}`}</Text>
                  <Text>{`DOB: ${data.patientDob}`}</Text>
                  <Text>{`Address: ${data.patientAddress}, ${data.patientSuburb} ${data.patientState} ${data.patientPostcode}`}</Text>
                </Box>

                {data.prescribedItems.map((item: PrescribedItem, index: number) => (
                  <Box key={index}>
                    <Text fontWeight="bold" textTransform="uppercase">
                      {`Item ${index + 1}:`}
                    </Text>
                    <Text>{`Name: ${item.displayName || item.genericName}`}</Text>
                    {item.isPBS && <Text>Type: PBS</Text>}
                    {item.isRPBS && <Text>Type: RPBS</Text>}
                    {item.privatePrescription && <Text>Type: Private</Text>}
                    {item.controlledSubstanceReference === 'Section 100' && (
                      <Text>Type: Section 100</Text>
                    )}
                    {item.pbsCode && <Text>{`PBS Code: ${item.pbsCode}`}</Text>}
                    {item.phoneApprovalAuthorityNumber && (
                      <Text>{`Approval Number: ${item.phoneApprovalAuthorityNumber}`}</Text>
                    )}
                    <Text>{`Quantity: ${item.quantity}`}</Text>
                    <Text>{`Repeat: ${item.numberOfRepeatsAuthorised}`}</Text>
                    {item.repeatIntervals && <Text>{`Interval: ${item.repeatIntervals}`}</Text>}
                    {item.patientInstructions && (
                      <Text>{`Directions: ${item.patientInstructions}`}</Text>
                    )}
                    {item.reasonForPrescribing && (
                      <Text>{`Reason for Prescribing: ${item.reasonForPrescribing}`}</Text>
                    )}
                    {item.doctorsNotes && <Text>{`Annotation: ${item.doctorsNotes}`}</Text>}
                    {item.unusualDoseFlag && <Text>{`Unusual Dose`}</Text>}
                    {item.brandSubstitutionNotAllowed && (
                      <Text>{`Brand substitution not allowed`}</Text>
                    )}
                    {item.regulation24 && <Text>{`Regulation 49 (previously Regulation 24)`}</Text>}
                    {item.emergencySupply && <Text>{`Emergency supply`}</Text>}
                    {item.noMySL && (
                      <Text>{`Patient has exercised their choice to keep the information away from their ASL`}</Text>
                    )}
                    {item.sendToPharmacy && <Text>{`${item.sendToPharmacy}`}</Text>}
                  </Box>
                ))}
                <DeliveryMethod
                  isPaperPrescription={!!data.isPaperPrescription}
                  sendToPharmacy={!!data.prescribedItems?.[0]?.sendToPharmacy}
                  emergencySupply={!!data.prescribedItems?.[0]?.emergencySupply}
                  sendViaEmail={!!data.sendViaEmail}
                  sendViaSMS={!!data.sendViaSMS}
                  printPaperToken={!!data.printPaperToken}
                />
              </Stack>
            </CardBody>
          </Card>
          {isPaperPrescription && isIncludeS8 ? (
            <Text color={'red.500'} fontWeight={'bold'} paddingTop={4}>
              Printed Schedule 8 prescriptions must comply with state or territory requirements
            </Text>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <VStack>
            {enableRTPMUIRestriction &&
            (rtpmCheckStatus?.checkRequired || rtpmCheckStatus?.dismissRequired) ? (
              <Alert status={'error'}>
                <AlertIcon />
                <AlertDescription fontSize="sm">
                  {rtpmCheckStatus?.checkRequired ? (
                    <Text>
                      Active alert(s) exist. Click{' '}
                      <Link
                        href={rtpmCheckStatus?.rtpmCheckLink}
                        textDecoration="underline"
                        isExternal
                        onClick={() => {
                          setRtpmCheckStatus({
                            checkRequired: false,
                            dismissRequired: false,
                            rtpmCheckLink: '',
                          })
                        }}
                      >
                        HERE
                      </Link>{' '}
                      to view details in order to proceed with sending the prescription.
                    </Text>
                  ) : (
                    <Text>Please dismiss the above RTPM alert in order to proceed.</Text>
                  )}
                </AlertDescription>
              </Alert>
            ) : null}
            <HStack alignSelf={'end'}>
              <Button
                variant="ghost"
                mr={3}
                onClick={() => {
                  setRtpmCheckStatus(null)
                  onClose()
                }}
              >
                Make Changes
              </Button>
              <Button
                colorScheme="blue"
                onClick={onSend}
                disabled={
                  enableRTPMUIRestriction &&
                  !(
                    rtpmCheckStatus?.checkRequired === false &&
                    rtpmCheckStatus?.dismissRequired === false
                  )
                }
              >
                Send
              </Button>
            </HStack>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PreviewPrescriptionModal
