import ApiClient from '../../utils/api/ApiClient'
import { UserInvitation } from './type/UserInvitation'
import { Permission } from '../../types/permission'

export interface AcceptInvitationRequest {
  token: string
  password?: string
  firstName: string
  lastName: string
  phoneNumber?: string
}

export interface SubmitInvitationRequest {
  email: string
  isPrescriber: boolean
  organizationId: string
  permissions: Permission[]
}

export interface ApproveInvitationRequest {
  invitationToken: string
  isApproved: boolean
}

interface AcceptInvitationResponse {
  userId: string
}

interface ApproveInvitationResponse {
  token: string
  isApproved: boolean
}

interface UserInvitationCreationResponse {
  token: string
  email: string
  inviterName: string
  inviterEmail: string
  expiresAt: Date
  status: string
  organizationId: string
  userId?: string
  isPrescriber: boolean
  permissions: Permission[]
}

const submitInvitation = async (data: SubmitInvitationRequest) => {
  const response = await ApiClient.post<UserInvitationCreationResponse>(
    `/bff/user-invitations`,
    data
  )
  return response?.data
}

const acceptInvitation = async (
  data: AcceptInvitationRequest
): Promise<AcceptInvitationResponse> => {
  const response = await ApiClient.post(`/bff/user-invitations/acceptance`, data)
  return response?.data
}

const approveInvitation = async (
  data: ApproveInvitationRequest
): Promise<ApproveInvitationResponse> => {
  const response = await ApiClient.post(`/bff/user-invitations/approval`, data)
  return response?.data
}

const getInvitationByToken = async ({
  invitationToken,
}: {
  invitationToken: string
}): Promise<UserInvitation> => {
  const response = await ApiClient.get<UserInvitation>(`/bff/user-invitations/${invitationToken}`)
  return response?.data
}

export const InvitationApiClient = {
  acceptInvitation,
  approveInvitation,
  getInvitationByToken,
  submitInvitation,
}
