import { PbsConcise, PbsRestriction } from '../ProductSearchContextSlice'

interface PBSData {
  pbsCode: string
  pbsManufacturerCode: string
  quantity: number
  numberOfRepeatsAuthorised: number
  maximumQuantity: string
  maximumRepeats: string
  phoneApprovalAuthorityNumber: string
  streamlinedCode: string

  // Prescription Type breakdown
  isPBS: boolean
  isRPBS: boolean
  isSection100: boolean
  unlistedItemRepatAuthority: false
  privatePrescription: boolean
}

export const EMPTY_PBS_DATA: Omit<PBSData, 'quantity' | 'numberOfRepeatsAuthorised'> = {
  pbsCode: '',
  pbsManufacturerCode: '',
  maximumQuantity: 'NA',
  maximumRepeats: 'NA',
  phoneApprovalAuthorityNumber: '',
  streamlinedCode: '',

  // Prescription Type breakdown
  isPBS: false,
  isRPBS: false,
  isSection100: false,
  unlistedItemRepatAuthority: false,
  privatePrescription: true,
}

export const transformPbsData = (
  pbsConcise?: PbsConcise,
  restriction?: PbsRestriction
): PBSData => {
  return {
    pbsCode: pbsConcise?.pbsCode ?? '',
    pbsManufacturerCode: pbsConcise?.pbsManufacturerCodes ? pbsConcise.pbsManufacturerCodes[0] : '',
    quantity: pbsConcise?.maxQtyUnits ?? 1,
    numberOfRepeatsAuthorised: pbsConcise?.repeats ?? 0,
    maximumQuantity: pbsConcise?.maxQtyUnits?.toString() ?? 'NA',
    maximumRepeats: pbsConcise?.repeats?.toString() ?? 'NA',
    phoneApprovalAuthorityNumber:
      (restriction ? restriction : pbsConcise?.restrictions[0])?.streamlinedCode || '',
    streamlinedCode:
      (restriction ? restriction : pbsConcise?.restrictions[0])?.streamlinedCode || '',

    // Prescription Type breakdown
    isPBS: ['PBS', 'PBS/RPBS'].includes(pbsConcise?.pbsType || ''),
    isRPBS: pbsConcise?.pbsType === 'RPBS',
    isSection100: pbsConcise?.pbsType === 'Section 100',
    unlistedItemRepatAuthority: false,
    privatePrescription: pbsConcise?.pbsType === 'Private',
  }
}
