import React from 'react'
import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import useApi from '../../../common/utils/api/useApi'
import { InvitationApiClient } from '../InvitationSlice'
import { useAppSelector } from '../../../app/typedReduxHooks'
import { RootState } from '../../../app/store'
import { AxiosError, AxiosResponse } from 'axios'
import { AdminRole } from '../../users/type/AdminRole'
import { convertRoleToPermissions } from '../../users/utils/AdminPermissions'

interface InviteFormInputs {
  email: string
  isPrescriber: boolean
  adminRole: AdminRole
}

interface InviteModalProps {
  isOpen: boolean
  onClose: () => void
  noEnoughSeats: boolean
}

export const InviteUserModal = ({ isOpen, onClose, noEnoughSeats }: InviteModalProps) => {
  const organization = useAppSelector((state: RootState) => state.organization?.data)
  const organizationId = organization?.id
  const organizationSettings = organization?.settings
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<InviteFormInputs>({
    defaultValues: {
      email: '',
      isPrescriber: false,
      adminRole: null,
    },
  })

  const toast = useToast()

  const currentAdminRole = watch('adminRole')
  const isPrescriber = watch('isPrescriber')

  const { trigger: submitUserInvitation, loading } = useApi(InvitationApiClient.submitInvitation, {
    onSuccess: (res) => {
      handleClose()
      toast({
        title: 'User Invitation Sent Successfully',
        description: organizationSettings?.userInvitationNeedApproval
          ? 'The invitation has been sent to the organization owner for approval.'
          : `An invitation email has been sent to ${res?.email}.`,
        status: 'success',
        isClosable: true,
        duration: 3000,
      })
    },
    onFailure: (error: AxiosError) => {
      const response = error.response as AxiosResponse<{ message: string }> | undefined
      toast({
        title: 'User Invitation Sent Failed',
        description: response?.data?.message ? response.data.message : '',
        status: 'error',
        isClosable: true,
        duration: 3000,
      })
    },
  })

  const handleAdminRoleChange = (newRole: AdminRole) => {
    if (newRole === currentAdminRole) {
      setValue('adminRole', null)
    } else {
      setValue('adminRole', newRole)
    }
  }

  const onSubmit = async (data: InviteFormInputs) => {
    if ((!data.isPrescriber && !data.adminRole) || !organizationId) {
      return
    }

    const permissions = data?.adminRole ? convertRoleToPermissions(data.adminRole) : []

    await submitUserInvitation({
      organizationId,
      email: data.email,
      isPrescriber: data.isPrescriber,
      permissions,
    })
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  const isAtLeastOneRoleSelected = isPrescriber || currentAdminRole !== null

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>Invite New User</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Stack spacing={4}>
              <FormControl isInvalid={!!errors.email}>
                <FormLabel>Invitee Email</FormLabel>
                <Input
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  })}
                  type="email"
                  placeholder="Enter email address"
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!isAtLeastOneRoleSelected}>
                <FormLabel>Role</FormLabel>
                <VStack align="stretch" spacing={2} bgColor={'gray.50'} p={4} rounded={'md'}>
                  <Box>
                    <Controller
                      name="isPrescriber"
                      control={control}
                      render={({ field }) => (
                        <>
                          <HStack spacing={2}>
                            <Checkbox
                              isChecked={field.value}
                              disabled={noEnoughSeats}
                              onChange={(e) => setValue('isPrescriber', e.target.checked)}
                            >
                              Prescriber
                            </Checkbox>
                            <Badge fontSize={'xs'} colorScheme="purple" variant="subtle">
                              billable
                            </Badge>
                          </HStack>
                          {noEnoughSeats && (
                            <Text fontSize="sm" color="purple" ml={6}>
                              More seats required for new prescribers.
                            </Text>
                          )}
                          <Text fontSize="sm" color="gray.600" ml={6}>
                            This role allows the user to create prescriptions and manage patient
                            health data.
                          </Text>
                        </>
                      )}
                    />
                  </Box>

                  <Box>
                    <Controller
                      name="adminRole"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Checkbox
                            isChecked={field.value === 'patient_admin'}
                            onChange={() => handleAdminRoleChange('patient_admin')}
                          >
                            Patient Admin
                          </Checkbox>
                          <Text fontSize="sm" color="gray.600" ml={6}>
                            This role allows the user to manage patients.
                          </Text>
                        </>
                      )}
                    />
                  </Box>

                  <Box>
                    <Controller
                      name="adminRole"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Checkbox
                            isChecked={field.value === 'rx_admin'}
                            onChange={() => handleAdminRoleChange('rx_admin')}
                          >
                            Rx Admin
                          </Checkbox>
                          <Text fontSize="sm" color="gray.600" ml={6}>
                            This role allows the user to view prescriptions and manage patients.
                          </Text>
                        </>
                      )}
                    />
                  </Box>
                </VStack>
                {!isAtLeastOneRoleSelected && (
                  <FormErrorMessage>Please select at least one role</FormErrorMessage>
                )}
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              isLoading={isSubmitting}
              loadingText="Sending"
              isDisabled={!isAtLeastOneRoleSelected || loading}
            >
              Send Invitation
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
