import React, { Fragment, useEffect, useState } from 'react'
import {
  Alert,
  AlertIcon,
  Button,
  chakra,
  Checkbox,
  Container,
  Heading,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  HIServiceMessage,
  IhiGender,
  PatientClient,
  PatientIhiSearchErrorResponse,
  PatientIhiSearchSuccessfulResponse,
} from '../../features/patient/PatientContextSlice'
import InputField from '../../components/InputField'
import luhn_validate from '../../utils/validation-helpers/LuhnValidate'
import useApi from '../../common/utils/api/useApi'
import { isValidDvaCardColour } from '../../utils/validation-helpers/DvaValidate'
import { RootState } from '../../app/store'
import {
  AustralianState,
  HIStatus,
  IHIRecord,
  Patient,
  PatientWithOptionalId,
  RecordStatus,
} from '../../types'
import { ATSI_STATUS_OPTIONS, DVA_CARD_COLOUR } from './constants'
import { useAppSelector } from '../../app/typedReduxHooks'
import AddressSuggestionInput from '../../components/form/AddressSuggestionInput'
import { trimValue } from '../../utils/text-formatter'
import { validateHumanName } from '../../utils/validation-helpers/Common'
import DateOfBirthSelectGroup from '../../components/DateOfBirthSelectGroup'
import {
  addressValidator,
  dvaFileNumberValidator,
  emailValidator,
  medicareIrnValidator,
  medicareNumberValidator,
  phoneNumberValidator,
  postcodeValidator,
} from '../../features/patient/utils/patientFieldsValidator'
import { PrescriberSelector } from '../../components/PrescriberSelector'
import { LimitedPrescriberProfile } from '../../features/users/UsersSlice'
import { Permission } from '../../types/permission'

interface NewIhiRecord extends Omit<IHIRecord, 'created_at'> {
  service_message: HIServiceMessage | null
}

interface PatientFormProps {
  trigger: UseFormTrigger<Patient> | UseFormTrigger<PatientWithOptionalId>
  register: UseFormRegister<Patient> | UseFormRegister<PatientWithOptionalId>
  setValue: UseFormSetValue<Patient> | UseFormSetValue<PatientWithOptionalId>
  errors: FieldErrorsImpl<Patient> | FieldErrorsImpl<PatientWithOptionalId>
  getValues: UseFormGetValues<Patient> | UseFormGetValues<PatientWithOptionalId>
  setShouldDisableSubmitButton: React.Dispatch<React.SetStateAction<boolean>>
  defaultPatient?: Patient | PatientWithOptionalId
  selectedPrescriber: LimitedPrescriberProfile | null
  onPrescriberChange?: (id: string) => void
  hidePrescriberSelection?: boolean
}

const AddressSuggestion = chakra(AddressSuggestionInput)

function PatientForm({
  trigger,
  register,
  errors,
  setValue,
  getValues,
  setShouldDisableSubmitButton,
  defaultPatient,
  selectedPrescriber,
  onPrescriberChange,
  hidePrescriberSelection = false,
}: PatientFormProps) {
  const [warning, setWarning] = useState('')
  const [isExpanded, setIsExpanded] = useState(false)
  const [showAddressLine2, setShowAddressLine2] = useState(!!getValues().address_2)
  const [isShowHospitalProviderNumber, setIsShowHospitalProviderNumber] = useState(false)
  const prescriberState = useSelector((state: RootState) => state.prescriber)
  const { currentClinic } = useAppSelector((state) => state.clinic)
  const originalIhiRecord: Omit<IHIRecord, 'created_at'> | undefined =
    defaultPatient?.ihi_number && defaultPatient?.ihi_status && defaultPatient?.record_status
      ? {
          ihi_number: defaultPatient.ihi_number,
          ihi_status: defaultPatient.ihi_status as HIStatus,
          record_status: defaultPatient.record_status as RecordStatus,
        }
      : undefined

  const hasPatientManagement = useAppSelector((state) =>
    state.authentication.permissions?.includes(Permission.PATIENT_MANAGEMENT)
  )
  const currentUser = useAppSelector((state) => state.authentication)
  const users = useAppSelector((state) => state.users?.data)
  const activePrescriberUsers = users?.filter((user) => user?.prescriber?.active) ?? []

  const effectivePrescriber = hidePrescriberSelection
    ? selectedPrescriber || prescriberState.prescriber
    : selectedPrescriber
  const hasValidHpii = !!effectivePrescriber?.hpii

  const resetIhiRecord = () => {
    setValue('ihi_number', originalIhiRecord?.ihi_number || '')
    setValue('ihi_status', originalIhiRecord?.ihi_status || '')
    setValue('record_status', 'Unverified')
  }

  const resetIhiRecordStatus = () => {
    if (originalIhiRecord?.ihi_number) {
      setValue('record_status', 'Unverified')
      setShouldDisableSubmitButton(true)
    }
  }

  useEffect(() => {
    const values = getValues()
    setShouldDisableSubmitButton(!!values.ihi_number && values.record_status !== 'Verified')
  }, [getValues, setShouldDisableSubmitButton])

  useEffect(() => {
    setIsShowHospitalProviderNumber(!!defaultPatient?.hospital_category)
  }, [defaultPatient?.hospital_category])

  useEffect(() => {
    if (hasPatientManagement && currentUser?.username && !selectedPrescriber?.id) {
      const currentUserData = users?.find((u) => u.userId === currentUser.username)
      if (currentUserData?.prescriber?.active) {
        onPrescriberChange?.(currentUserData.prescriber.id)
      }
    }
  }, [hasPatientManagement, currentUser?.username, users, selectedPrescriber, onPrescriberChange])

  const { trigger: searchIhi, loading } = useApi(PatientClient.checkIhi, {
    onSuccess: async (response) => {
      let newRecord: NewIhiRecord
      if ((response as PatientIhiSearchErrorResponse).Message) {
        const message = (response as PatientIhiSearchErrorResponse).Message
        if (message.startsWith('01613')) {
          newRecord = {
            ihi_number: originalIhiRecord?.ihi_number || '',
            ihi_status: 'Expired',
            record_status: originalIhiRecord?.record_status || 'Unverified',
            service_message: null,
          }
        } else if (message.startsWith('01614')) {
          newRecord = {
            ihi_number: originalIhiRecord?.ihi_number || '',
            ihi_status: 'Retired',
            record_status: originalIhiRecord?.record_status || 'Unverified',
            service_message: null,
          }
        } else {
          /// Fixme: Should handler error
          newRecord = {
            ihi_number: '',
            ihi_status: 'Active',
            record_status: 'Unverified',
            service_message: null,
          }
        }
      } else {
        response = response as PatientIhiSearchSuccessfulResponse
        newRecord = {
          ihi_number: response.IHI,
          ihi_status: response.HIStatus,
          record_status: response.RecordStatus,
          service_message: response.ServiceMessages?.serviceMessageField[0] || null,
        }
      }

      const completed = await handleNewPatientIhiRecord(newRecord)
      await updateDisableSubmitButtonState()
      showWarning(newRecord)

      if (completed) await trigger('ihi_number')
    },
  })

  const validateIhi = async () => {
    const validate = async () =>
      await trigger([
        'given_names',
        'family_name',
        'date_of_birth',
        'medicare_irn',
        'medicare_no',
        'dva_file_no',
      ])
    const isValid = await validate()
    if (!isValid) {
      return
    }

    await searchIhiNumber()
  }

  /// Fixme: Move this logic to bff
  const handleNewPatientIhiRecord = async (newIhiRecord: NewIhiRecord): Promise<boolean> => {
    /// Resolved number
    if (newIhiRecord.ihi_status === 'Retired' || newIhiRecord.ihi_status === 'Expired') {
      if (newIhiRecord.ihi_number) {
        setValue('ihi_number', newIhiRecord.ihi_number)
        setValue('ihi_status', newIhiRecord.ihi_status)
        setValue('record_status', newIhiRecord.record_status)
      } else {
        resetIhiRecord()
      }
      return true
    }

    if (newIhiRecord.service_message?.codeField === '01611') {
      setValue('ihi_number', newIhiRecord.ihi_number)
      setValue('ihi_status', newIhiRecord.ihi_status)
      setValue('record_status', newIhiRecord.record_status)
      await searchIhiNumber()
      return false
    }

    /// Not found
    if (newIhiRecord.service_message?.codeField === '01439') {
      // Failed to fetch
      resetIhiRecord()
      return true
    }

    setValue('ihi_number', newIhiRecord.ihi_number)
    setValue('ihi_status', newIhiRecord.ihi_status)
    setValue('record_status', newIhiRecord.record_status)
    return true
  }

  const updateDisableSubmitButtonState = () => {
    const disabled = !!getValues().ihi_number && getValues().record_status !== 'Verified'
    setShouldDisableSubmitButton(disabled)
  }

  const showWarning = (newIhiRecord: Omit<IHIRecord, 'created_at'>) => {
    setWarning('')

    if (
      (newIhiRecord.ihi_status === 'Retired' || newIhiRecord.ihi_status === 'Expired') &&
      !originalIhiRecord
    ) {
      setWarning(
        `This IHI record has an '${newIhiRecord.ihi_status}' IHI status and cannot be retrieved.`
      )
      return
    }

    if (!newIhiRecord.ihi_number) {
      setWarning(`No Healthcare Individual record have been found.`)
      return
    }

    if (newIhiRecord.record_status !== 'Verified') {
      setWarning(
        `Sorry, we can not store the IHI number with a ${newIhiRecord.record_status} record status.`
      )
      return
    }

    if (
      originalIhiRecord?.ihi_status !== newIhiRecord.ihi_status &&
      (originalIhiRecord?.ihi_status === 'Active' || originalIhiRecord?.ihi_status === 'Deceased')
    ) {
      setWarning(
        `The number status changed from ${originalIhiRecord.ihi_status} to ${newIhiRecord.ihi_status}.`
      )
      return
    }
  }

  const searchIhiNumber = async () => {
    const values = getValues()
    let search: Record<any, any> = {}
    const medicareNoChanged =
      values.medicare_irn !== defaultPatient?.medicare_irn ||
      values.medicare_no !== defaultPatient?.medicare_no
    const dvaFileNoChanged = values.dva_file_no !== defaultPatient?.dva_file_no
    const ihiNumberChanged = values.ihi_number !== defaultPatient?.ihi_number && !!values.ihi_number
    if (!medicareNoChanged && !dvaFileNoChanged && !ihiNumberChanged) {
      search = {
        MedicareIRN: values.medicare_irn || '',
        MedicareCardNumber: values.medicare_no || '',
        DvaFileNumber: values.dva_file_no || '',
        IhiNumber: values.ihi_number || '',
      }
    } else {
      if (medicareNoChanged) {
        search.MedicareIRN = values.medicare_irn || ''
        search.MedicareCardNumber = values.medicare_no || ''
      }
      if (dvaFileNoChanged) {
        search.DvaFileNumber = values.dva_file_no || ''
      }
      if (ihiNumberChanged) {
        search.IhiNumber = values.ihi_number || ''
      }
    }
    await searchIhi({
      GivenName: values.given_names,
      FamilyName: values.family_name,
      DOB: values.date_of_birth,
      Gender: values.gender as IhiGender,
      ...search,
      HpiiNumber: effectivePrescriber?.hpii ?? '',
      ClinicId: currentClinic?.id,
    })
  }

  return (
    <VStack alignItems="stretch" paddingY={[1, 4]} paddingX={[1, 10]} spacing={12}>
      {!hidePrescriberSelection && hasPatientManagement && !defaultPatient && (
        <VStack alignItems="stretch" flex={1} spacing={4}>
          <Heading as="h4" size="md">
            Prescriber Selection
          </Heading>
          <SimpleGrid columns={[1, 2]} spacingX={8} spacingY={4}>
            <InputField label="Select Prescriber" field="prescriber" isRequired={true}>
              <PrescriberSelector
                users={activePrescriberUsers || []}
                selectedPrescriberId={selectedPrescriber?.id}
                onSelect={(id) => id && onPrescriberChange?.(id)}
              />
            </InputField>
          </SimpleGrid>
        </VStack>
      )}
      {!hasPatientManagement || hidePrescriberSelection || selectedPrescriber ? (
        !hasValidHpii && !defaultPatient ? (
          <Alert status="warning">
            <AlertIcon />
            {hidePrescriberSelection ? (
              <Text>You don't have a valid HPII number.</Text>
            ) : (
              <Text>
                Selected prescriber does not have a valid HPII number. Please select a different
                prescriber.
              </Text>
            )}
          </Alert>
        ) : (
          <Fragment>
            <VStack alignItems="stretch" flex={1} spacing={4}>
              <Heading as="h4" size="md">
                Contact Information
              </Heading>
              <SimpleGrid columns={[1, 2]} spacingX={8} spacingY={4}>
                <InputField
                  label="First Names"
                  field="given_names"
                  errors={errors}
                  isRequired={true}
                >
                  <Input
                    {...register('given_names', {
                      setValueAs: trimValue,
                      required: 'Required',
                      maxLength: {
                        value: 40,
                        message: 'Patient name must be less than 40 Characters',
                      },
                      validate: (value: string) => {
                        const result = validateHumanName(value)
                        return result.isValid || result.displayValue
                      },
                    })}
                  ></Input>
                </InputField>
                <InputField
                  label="Family Name"
                  field="family_name"
                  errors={errors}
                  isRequired={true}
                >
                  <Input
                    {...register('family_name', {
                      setValueAs: trimValue,
                      required: 'Required',
                      maxLength: {
                        value: 40,
                        message: 'Patient name must be less than 40 Characters',
                      },
                      validate: (value: string) => {
                        const result = validateHumanName(value)
                        return result.isValid || result.displayValue
                      },
                    })}
                  />
                </InputField>
                <InputField label="Gender" field="gender" isRequired={true} errors={errors}>
                  <Select
                    {...register('gender', {
                      required: 'Required',
                    })}
                  >
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="I">Intersex or Indeterminate</option>
                    <option value="N">Not Stated</option>
                  </Select>
                </InputField>
                <DateOfBirthSelectGroup
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  defaultDateOfBirth={defaultPatient?.date_of_birth}
                />
                <InputField
                  label="Mobile Number"
                  field="phone_number"
                  isRequired={true}
                  errors={errors}
                >
                  <Input
                    placeholder="eg. 0411222333"
                    {...register('phone_number', {
                      setValueAs: trimValue,
                      required: false,
                      validate: (value: string) => {
                        return phoneNumberValidator(value, getValues().email)
                      },
                      deps: ['email'],
                    })}
                  ></Input>
                </InputField>
                <InputField label="Email" field="email" isRequired={true} errors={errors}>
                  <Input
                    {...register('email', {
                      setValueAs: trimValue,
                      required: false,
                      validate: (email: string) => {
                        return emailValidator(email, getValues().phone_number)
                      },
                      deps: ['phone_number'],
                    })}
                  ></Input>
                </InputField>
                <AddressSuggestion setValue={setValue} gridColumn={['1 / 2', '1 / span 2']} />
                <InputField
                  label="Residential Address"
                  field="address_1"
                  isRequired={true}
                  errors={errors}
                >
                  <Input
                    {...register('address_1', {
                      setValueAs: trimValue,
                      required: 'Required',
                      validate: addressValidator,
                    })}
                  ></Input>
                  {!showAddressLine2 && (
                    <Button onClick={() => setShowAddressLine2(true)} color="grey">
                      + Add address line 2
                    </Button>
                  )}
                </InputField>
                {showAddressLine2 && (
                  <InputField label="Residential Address Line 2" field="address_2" errors={errors}>
                    <Input
                      {...register('address_2', {
                        setValueAs: trimValue,
                        validate: addressValidator,
                      })}
                    ></Input>
                  </InputField>
                )}
                <InputField label="Suburb" field="suburb" isRequired={true} errors={errors}>
                  <Input
                    {...register('suburb', {
                      setValueAs: trimValue,
                      required: 'Required',
                      maxLength: 36,
                      validate: (value: string) => {
                        // suburb and human name have same validation rule
                        const result = validateHumanName(value)
                        return result.isValid || result.displayValue
                      },
                    })}
                  ></Input>
                </InputField>
                <InputField label="Postcode" field="postcode" isRequired={true} errors={errors}>
                  <Input
                    type="text"
                    {...register('postcode', {
                      setValueAs: trimValue,
                      required: 'Required',
                      validate: (value: number) => {
                        return postcodeValidator(`${value}`)
                      },
                    })}
                  ></Input>
                </InputField>
                <InputField label="State" field="state" isRequired={true} errors={errors}>
                  <Select
                    {...register('state', {
                      required: 'Required',
                      validate: (input: string) =>
                        Object.values(AustralianState).includes(input as AustralianState) ||
                        'Please select a valid State',
                    })}
                  >
                    <>
                      {Object.values(AustralianState).map((state) => {
                        return (
                          <option value={state} key={state}>
                            {state}
                          </option>
                        )
                      })}
                    </>
                  </Select>
                </InputField>
              </SimpleGrid>
            </VStack>
            <VStack alignItems="stretch" flex={1} spacing={4}>
              <Heading as="h4" size="md">
                Medicare / DVA Information
              </Heading>
              <Text>Either Medicare and/or DVA required</Text>
              <SimpleGrid columns={[1, 2]} spacingX={8} spacingY={4}>
                <InputField label="Medicare Card Number" field="medicare_no" errors={errors}>
                  <Input
                    {...register('medicare_no', {
                      setValueAs: trimValue,
                      validate: (medicareNo: undefined | string) => {
                        return medicareNumberValidator(medicareNo, getValues().dva_file_no)
                      },
                      onChange: resetIhiRecordStatus,
                      deps: ['dva_file_no'],
                    })}
                  />
                </InputField>
                <InputField label="Medicare IRN" field="medicare_irn" errors={errors}>
                  <Input
                    {...register('medicare_irn', {
                      setValueAs: trimValue,
                      minLength: {
                        value: 1,
                        message: 'Please enter a valid IRN',
                      },
                      maxLength: {
                        value: 1,
                        message: 'Please enter a valid IRN',
                      },
                      validate: (irnNo: undefined | string) => {
                        return medicareIrnValidator(irnNo, getValues().dva_file_no)
                      },
                      onChange: resetIhiRecordStatus,
                      deps: ['dva_file_no'],
                    })}
                  />
                </InputField>
                <InputField label="DVA Card Number" field="dva_file_no" errors={errors}>
                  <Input
                    {...register('dva_file_no', {
                      setValueAs: trimValue,
                      validate: (dvaFileNo: undefined | string) => {
                        return dvaFileNumberValidator(
                          dvaFileNo,
                          getValues().medicare_no,
                          getValues().medicare_irn
                        )
                      },
                      onChange: resetIhiRecordStatus,
                      deps: ['medicare_no', 'medicare_irn'],
                    })}
                  />
                </InputField>
                <InputField label="DVA Card Colour" field="dva_card_colour" errors={errors}>
                  <Select
                    {...register('dva_card_colour', {
                      setValueAs: trimValue,
                      validate: (dvaCardColour: undefined | string) => {
                        const dvaCardNumber = getValues().dva_file_no
                        if (dvaCardNumber) {
                          return (
                            isValidDvaCardColour(String(dvaCardColour)) ||
                            'Please select a valid Dva Card Colour'
                          )
                        }
                        return true
                      },
                      deps: ['dva_file_no'],
                    })}
                  >
                    <option value=""></option>
                    <option value="G">{DVA_CARD_COLOUR.G}</option>
                    <option value="W">{DVA_CARD_COLOUR.W}</option>
                    <option value="O">{DVA_CARD_COLOUR.O}</option>
                  </Select>
                </InputField>
              </SimpleGrid>
            </VStack>
            <VStack alignItems="stretch" flex={1} spacing={4}>
              <VStack alignItems="start">
                <Heading as="h4" size="md">
                  Individual Healthcare Identifier (IHI)
                </Heading>
                <Text fontSize="sm">You must validate your patient's IHI to e-prescribe</Text>
              </VStack>
              <Stack alignItems="strength" spacing={8} direction={['column', 'row']}>
                <VStack alignItems="stretch" flexGrow="1">
                  <InputField
                    label="IHI Status"
                    field="ihi_status"
                    errors={errors}
                    {...register('ihi_status', {
                      validate: (value: string) => {
                        const isPaperPrescriptionsOnly = getValues().paper_only
                        if (!isPaperPrescriptionsOnly && !value) {
                          return "Please validate your patient's IHI"
                        }
                      },
                      deps: ['paper_only'],
                    })}
                    //@ts-expect-error reason placeholder @bob
                    ref={null}
                  >
                    <Alert
                      status={
                        getValues().ihi_status === 'Active' &&
                        getValues().record_status === 'Verified'
                          ? 'success'
                          : 'warning'
                      }
                      colorScheme={getValues().ihi_status ? undefined : 'gray'}
                    >
                      <AlertIcon />
                      <Text>{`${getValues().ihi_status || 'Not Applicable'}${
                        getValues().ihi_status && getValues().record_status
                          ? '(' + getValues().record_status + ')'
                          : ''
                      }`}</Text>
                    </Alert>
                  </InputField>
                  <p style={{ fontSize: '10px', color: 'red' }}>{warning}</p>
                  <Button onClick={validateIhi} disabled={loading} marginEnd={8} colorScheme="blue">
                    Validate IHI
                  </Button>
                </VStack>
                <VStack flexGrow="1" alignItems="strength">
                  <InputField label="IHI Number" field="ihi_number" errors={errors}>
                    <Input
                      disabled
                      backgroundColor="gray.200"
                      {...register('ihi_number', {
                        validate: (ihi: number | string) => {
                          ihi = String(ihi || '')
                          if (ihi.length === 0) return true
                          return (
                            (ihi.startsWith('800360') && luhn_validate(ihi, 16)) ||
                            'Please Enter a valid IHI Number'
                          )
                        },
                      })}
                    />
                  </InputField>
                  <Checkbox flexGrow="1" {...register('paper_only')}>
                    Paper Prescriptions Only
                  </Checkbox>
                </VStack>
              </Stack>
            </VStack>
            <HStack spacing={8} paddingY={10}>
              <Heading as="h4" size="md">
                Additional Information
              </Heading>
              <Button
                colorScheme="teal"
                variant="link"
                textDecoration={'underline'}
                color={'blue.400'}
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? 'Collapse' : 'Expand'}
              </Button>
            </HStack>
            {isExpanded && (
              <>
                <VStack alignItems="stretch" flex={1} spacing={4}>
                  <Heading as="h4" size="md">
                    Additional Entitlement Details (Optional)
                  </Heading>
                  <Stack alignItems="start" spacing={[4, 8]} direction={['column', 'row']}>
                    <VStack alignItems="stretch" flex={1} spacing={4}>
                      <InputField label="RACFID (Aged Care Patient)" field="racfid" errors={errors}>
                        <Input type="text" {...register('racfid')}></Input>
                      </InputField>
                      <InputField
                        label="Pension or Health Care Card Number"
                        field="concession_pension_no"
                        errors={errors}
                      >
                        <Input type="text" {...register('concession_pension_no')}></Input>
                      </InputField>
                      <InputField label="Entitlement Number" field="entitlement_no" errors={errors}>
                        <Input type="text" {...register('entitlement_no')}></Input>
                      </InputField>
                    </VStack>
                    <VStack alignItems="stretch" flex={1} spacing={3}>
                      <InputField label="" field="ctg_flag" errors={errors}>
                        <Checkbox {...register('ctg_flag')}>Eligible for Closing the Gap</Checkbox>
                      </InputField>
                      <InputField label="" field="hospital_category" errors={errors}>
                        <Checkbox
                          {...register('hospital_category')}
                          onChange={(e) => setIsShowHospitalProviderNumber(e.target.checked)}
                        >
                          Hospital Inpatient
                        </Checkbox>
                      </InputField>
                      {isShowHospitalProviderNumber && (
                        <InputField
                          label="Hospital Provider Number"
                          field="hospital_provider_number"
                          errors={errors}
                        >
                          <Input
                            type="text"
                            {...register('hospital_provider_number', {
                              validate: (hospitalProviderNumber: any) => {
                                const isHospitalCategoryChecked = getValues().hospital_category
                                if (isHospitalCategoryChecked && !hospitalProviderNumber) {
                                  return 'Please fill the Hospital Provider Number when you check the Hospital Inpatient'
                                }
                                return true
                              },
                              deps: ['hospital_category'],
                            })}
                          ></Input>
                        </InputField>
                      )}
                      <InputField label="ATSI Status" field="atsi_status" errors={errors}>
                        <Select {...register('atsi_status')}>
                          <>
                            <option value={undefined} key={'undefined_atsi_status'}>
                              {}
                            </option>
                            {Object.entries(ATSI_STATUS_OPTIONS).map(([value, lable]) => {
                              return (
                                <option value={value} key={value}>
                                  {lable}
                                </option>
                              )
                            })}
                          </>
                        </Select>
                      </InputField>
                    </VStack>
                  </Stack>
                </VStack>
                <VStack alignItems="stretch" flex={1} spacing={4}>
                  <Heading as="h4" size="md">
                    Additional Medicare Details (Optional)
                  </Heading>
                  <Container width={['100%', '50%']} paddingLeft={0} paddingRight={4}>
                    <InputField label="Medicare Expiry" field="medicare_valid_to" errors={errors}>
                      <Input
                        type="month"
                        {...register('medicare_valid_to', {
                          validate: (medicareValidTo: string | undefined) => {
                            if (
                              !isExpanded ||
                              (!medicareValidTo &&
                                !getValues().medicare_no &&
                                !getValues().medicare_irn)
                            ) {
                              return true
                            }
                            const message =
                              'Please enter a valid date that medicare valid to, eg: 2027-07'
                            const valid = medicareValidTo?.search(/^\d{4}-\d{2}/) !== -1
                            return valid || message
                          },
                        })}
                      />
                    </InputField>
                  </Container>
                </VStack>
              </>
            )}
          </Fragment>
        )
      ) : (
        <Alert status="info">
          <AlertIcon />
          <Text>Please select a prescriber before creating a patient</Text>
        </Alert>
      )}
    </VStack>
  )
}

export default PatientForm
