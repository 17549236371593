import { FunctionComponent, useCallback, useEffect, useRef } from 'react'
import { Center, CircularProgress, Flex, Spacer } from '@chakra-ui/react'
import { useAppDispatch } from '../../app/typedReduxHooks'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  AuthenticationClient,
  AuthenticationResponse,
} from '../../features/authentication/slice/authenticationSlice'
import { routes } from '../routes'
import { appDidFinishLaunching } from '../../app/appSlice'

const AuthenticationPage: FunctionComponent<unknown> = () => {
  const effectRan = useRef(false)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const timeoutRef = useRef<NodeJS.Timeout>()

  const navigate = useNavigate()

  const authenticateUser = useCallback(async () => {
    try {
      const code = new URLSearchParams(location.search).get('code')
      const clientId =
        location?.pathname?.split('/auth/')?.[1] || process.env.REACT_APP_COGNITO_CLIENT_ID
      if (!code || !clientId) {
        return navigate(routes.login.path)
      }
      localStorage.removeItem('_isExpired')
      const { payload } = await dispatch(
        AuthenticationClient.Authenticate({
          code,
          redirectUri: `${process.env.REACT_APP_COGNITO_REDIRECT_URI}/${clientId}`,
          clientId: clientId || '',
        })
      )
      const { assessToken, refreshToken } =
        (payload as { data?: AuthenticationResponse }).data || {}
      if (assessToken && refreshToken && clientId) {
        localStorage.setItem('access_token', assessToken)
        localStorage.setItem('refresh_token', refreshToken)
        localStorage.setItem('client_id', clientId)

        timeoutRef.current = setTimeout(() => {
          navigate(routes.login.path)
        }, 30000)

        setTimeout(async () => {
          try {
            await dispatch(appDidFinishLaunching())
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current)
            }
            const redirectUrl = localStorage.getItem('redirectAfterLogin')
            if (redirectUrl) {
              localStorage.removeItem('redirectAfterLogin')
              navigate(redirectUrl)
            } else {
              navigate(routes.root.path)
            }
          } catch (error) {
            navigate(routes.login.path)
          }
        }, 1000)
      } else {
        navigate(routes.login.path)
      }
    } catch (error) {
      navigate(routes.login.path)
    }
  }, [dispatch, navigate, location.search])

  useEffect(() => {
    if (!effectRan.current) {
      authenticateUser()
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      effectRan.current = true
    }
  }, [authenticateUser])

  return (
    <Flex flexDirection="column" minWidth="max-content" alignItems="center" gap="10">
      <Spacer />
      <Center w="100px">
        <CircularProgress isIndeterminate color="blue.300" />
      </Center>
      <Center w="intrinsic">We are logging you in...</Center>
    </Flex>
  )
}

export default AuthenticationPage
