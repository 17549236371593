import { VStack, StackDivider } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch, useAppSelector } from '../../../app/typedReduxHooks'
import { RootState } from '../../../app/store'
import { fetchOrganizationData } from '../OrganizationSlice'
import OrganizationDetails from './OrganizationDetails'
import OrganizationSettings from './OrganizationSettings'
import { Permission } from '../../../types/permission'

export const OrganizationProfile = () => {
  const dispatch = useAppDispatch()
  const prescriber = useSelector((state: RootState) => state.prescriber?.prescriber)
  const orgAdmin = useSelector((state: RootState) => state.organizationAdmin.profile)
  const organizationId = prescriber?.organization?.id ?? orgAdmin?.organization?.id
  const permissions = useAppSelector((state) => state.authentication.permissions)
  const isAbleToManageOrg = permissions?.includes(Permission.ORGANIZATION_MANAGEMENT)

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchOrganizationData(organizationId))
    }
  }, [organizationId, dispatch])

  return (
    <VStack
      divider={<StackDivider borderColor="gray.200" />}
      spacing={8}
      align="stretch"
      width={['100%', '80%', '55%']}
    >
      <OrganizationDetails isAbleToManageOrg={isAbleToManageOrg} />
      {isAbleToManageOrg && <OrganizationSettings />}
    </VStack>
  )
}
