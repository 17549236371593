import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ApiClient from '../../../utils/api/ApiClient'
import {
  PrescriberInService,
  transformPrescriberForUI,
} from '../../../pages/PrescriberProfilePage/utils'
import { setPrescriber } from '../../../pages/NewScriptPage/ScriptForm/PrescriberSlice'
import { enableIntercomService } from '../../../services/intercom/intercomService'
import { OrganizationAdmin } from '../../../types'
import { setOrganizationAdmin } from '../../organization-admin/organizationAdminSlice'
import { Permission } from '../../../types/permission'

export interface AuthenticationRequest {
  code: string
  clientId: string
  redirectUri: string
}

export interface ReAuthenticationRequest {
  clientId: string
  refreshToken: string
}

export interface AuthenticationResponse {
  assessToken: string
  refreshToken: string
  idToken: string
  expiresIn: number
  email: string
  username: string
  firstName: string
  familyName: string
}

type AuthenticationClientMethods = keyof typeof AuthenticationClient
export interface AuthenticationState {
  email: string
  firstName: string
  familyName: string
  username: string
  permissions: Permission[] | null
  hasUserProfile: boolean
  hasError: boolean
  loginFinished: boolean
  loading: {
    [K in AuthenticationClientMethods]?: string
  }
}

export interface ReAuthenticationResponse {
  assessToken: string
  idToken: string
  expiresIn: number
}

export const AuthenticationClient = {
  Authenticate: createAsyncThunk('auth', async (request: AuthenticationRequest) => {
    try {
      const { data: responseData } = await ApiClient.post(`/auth`, {
        code: request.code,
        clientId: request.clientId,
        redirectUri: request.redirectUri,
      })
      return { data: responseData as AuthenticationResponse }
    } catch (error) {
      return { error: new Error(`${error}`) }
    }
  }),
  Login: createAsyncThunk(
    'auth/login',
    async (_, thunkAPI): Promise<{ permissions: Permission[]; hasUserProfile: boolean }> => {
      const response = await ApiClient.post(`/auth/login`)
      const prescriber: PrescriberInService | undefined = response.data?.profiles?.prescribers?.[0]
      const admin: OrganizationAdmin | undefined = response.data?.profiles?.admins?.[0]

      const user: PrescriberInService | OrganizationAdmin | undefined = prescriber
        ? prescriber
        : admin

      const isEnableChatbot = user?.organization?.settings?.enableChatbot

      const adminPermissions = admin?.active ? admin?.permissions : []
      const prescriberPermissions = prescriber?.active ? [Permission.PRESCRIBING] : []
      const permissions: Permission[] = [...adminPermissions, ...prescriberPermissions]
        .filter((value): value is Permission => value !== null)
        .filter((value, index, array) => array.indexOf(value) === index)

      if (!prescriber && !admin) {
        localStorage.removeItem('prescriber')
        localStorage.removeItem('admin')
        enableIntercomService()
        return { permissions, hasUserProfile: false }
      }

      if (!isEnableChatbot) {
        enableIntercomService({ disable: true })
      } else {
        enableIntercomService({
          user: {
            name: `${prescriber ? prescriber?.givenNames : admin?.givenName} ${user.familyName}`,
            email: user?.email,
          },
        })
      }

      prescriber && thunkAPI.dispatch(setPrescriber(transformPrescriberForUI(prescriber)))
      admin && thunkAPI.dispatch(setOrganizationAdmin(admin))
      return { permissions, hasUserProfile: true }
    }
  ),
  Logout: createAsyncThunk('auth/logout', async () => {
    const refreshToken = localStorage.getItem('refresh_token')
    if (refreshToken) {
      await ApiClient.post('/auth/token/revoke', { token: refreshToken })
      localStorage.clear()
    }
  }),
  refreshAccessToken: async (request: ReAuthenticationRequest) => {
    try {
      const { data: responseData } = await ApiClient.post(`/auth/refresh`, {
        clientId: request.clientId,
        refreshToken: request.refreshToken,
      })

      return { data: responseData as ReAuthenticationResponse }
    } catch (error) {
      return { error: new Error(`${error}`) }
    }
  },
}

const permissionsJSON = localStorage.getItem('permissions')
const currentUserEmail = localStorage.getItem('email') ?? ''
const userCognitoUsername = localStorage.getItem('username') ?? ''
const permissions: Permission[] = (permissionsJSON?.length && JSON.parse(permissionsJSON)) || null

const authenticationSlice = createSlice<AuthenticationState, Record<string, any>>({
  name: 'authentication',
  initialState: {
    email: currentUserEmail,
    firstName: '',
    familyName: '',
    username: userCognitoUsername,
    permissions,
    loading: {},
    hasError: false,
    hasUserProfile: false,
    loginFinished: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AuthenticationClient.Authenticate.fulfilled, (state, action) => {
        if (action.payload?.data) {
          state.username = action.payload.data?.username || ''
          state.email = action.payload.data?.email || ''
          state.firstName = action.payload.data?.firstName || ''
          state.familyName = action.payload.data?.familyName || ''
          localStorage.setItem('email', state.email)
          localStorage.setItem('username', state.username)
        }
      })
      .addCase(AuthenticationClient.Login.pending, (state, { meta: { requestId } }) => {
        if (!state.loading.Login) {
          state.loading.Login = requestId
        }
      })
      .addCase(AuthenticationClient.Login.fulfilled, (state, { payload }) => {
        state.permissions = payload?.permissions ?? []
        state.hasUserProfile = payload?.hasUserProfile ?? false
        state.loginFinished = true
        state.loading.Login = undefined
        state.hasError = false
        localStorage.setItem('permissions', JSON.stringify(state.permissions))
      })
      .addCase(AuthenticationClient.Login.rejected, (state) => {
        state.loading.Login = undefined
        state.hasError = true
        state.permissions = null
        localStorage.setItem('permissions', JSON.stringify(state.permissions))
      })
      .addCase(AuthenticationClient.Logout.fulfilled, (state) => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('prescriber')
        localStorage.removeItem('admin')
        localStorage.removeItem('permissions')
        localStorage.removeItem('email')
        localStorage.removeItem('username')
        localStorage.removeItem('client_id')
        localStorage.removeItem('landingPageParams')
        state.username = ''
        state.email = ''
        state.firstName = ''
        state.familyName = ''
        state.loading = {}
        state.permissions = []
        state.hasUserProfile = false
        state.loginFinished = false
      })
  },
})

export default authenticationSlice.reducer
