import { Button, Card, Flex, Heading, Text, VStack } from '@chakra-ui/react'
import { Subscription } from '../../types'
import { BillingClient } from './BillingSlice'
import useApi from '../../common/utils/api/useApi'
import { useAppSelector } from '../../app/typedReduxHooks'
import { RootState } from '../../app/store'

export const SubscriptionInfo = ({ subscription }: { subscription: Subscription }) => {
  const organizationId = useAppSelector((state: RootState) => state.organization?.data?.id)
  const { trigger: triggerCreateCustomerPortalSession, loading } = useApi(
    BillingClient.createCustomerPortalSession,
    {
      onSuccess: (checkoutSessionUrl: string) => {
        window.location.href = checkoutSessionUrl
      },
      onFailure: () => {
        if (process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL) {
          window.location.href = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL
        }
      },
    }
  )
  if (!subscription) {
    return null
  }

  return (
    <Card p={8}>
      <VStack spacing={2} align="stretch">
        <Heading>Manage Subscription</Heading>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          gap={4}
          justify="space-between"
          align={{ base: 'stretch', md: 'center' }}
        >
          <Text>
            You can manage your subscription and billing information directly from Stripe.
          </Text>
          <Button
            colorScheme="blue"
            disabled={loading}
            isLoading={loading}
            onClick={() => {
              subscription?.stripeSubscriptionId &&
                organizationId &&
                triggerCreateCustomerPortalSession({
                  stripeSubscriptionId: subscription.stripeSubscriptionId,
                  organizationId,
                })
            }}
          >
            Manage subscription on Stripe.com
          </Button>
        </Flex>
      </VStack>
    </Card>
  )
}
